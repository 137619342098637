import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragIndicator } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Plan } from "../.";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import AcceptanceRateField from "./AcceptanceRateField";

import "./SortableRow.css";

export default function SortableRow({
  planId,
  planName,
  bookings,
  rejections,
  rejectionRate,
  venueName,
  priceTypes,
  firstPublishedAt,
}: SortablePlanRowProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: planId });

  return (
    <TableRow
      key={planId}
      style={{
        opacity: isDragging ? 0.2 : undefined,
        transform: CSS.Translate.toString(transform),
        transition,
      }}
      ref={setNodeRef}
      {...attributes}
      className={"SortableRow"}
    >
      <TableCell className="EditColumn">
        <IconButton
          size="small"
          target="_blank"
          rel="noreferrer"
          href={`/plans/edit/${planId}`}
        >
          <EditIcon fontSize="inherit" />
        </IconButton>
      </TableCell>
      <TableCell className="DetailsColumn">
        <IconButton
          size="small"
          target="_blank"
          rel="noreferrer"
          href={`https://oiwai.anny.gift/celebration-plans/${planId}`}
        >
          <VisibilityIcon style={{ color: "#c8a063" }} fontSize="inherit" />
        </IconButton>
      </TableCell>
      <TableCell className="AcceptanceRateColumn">
        <AcceptanceRateField
          planId={planId}
          bookings={bookings.length}
          rejections={rejections.length}
          acceptanceRate={
            rejectionRate !== null ? 100 - rejectionRate : undefined
          }
        />
      </TableCell>
      <TableCell className="VenueNameColumn">{venueName}</TableCell>
      <TableCell className="PlanNameColumn">{planName}</TableCell>
      <TableCell className="PriceTypesColumn">
        <ul style={{ paddingLeft: "1rem" }}>
          {priceTypes.map((priceType, i) => (
            <li key={i}>
              {priceType.name}: {priceType.amount} {priceType.currencyCode}
            </li>
          ))}
        </ul>
      </TableCell>
      <TableCell className="PublishedDateColumn">{firstPublishedAt}</TableCell>
      <TableCell className="DragHandleColumn">
        <IconButton
          style={{
            cursor: "grab",
            marginLeft: "auto",
          }}
          {...listeners}
        >
          <DragIndicator />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export interface SortablePlanRowProps {
  planId: string;
  planName: string;
  bookings: number[];
  rejections: number[];
  rejectionRate: number;
  venueName: string;
  priceTypes: Plan["activities"][0]["priceTypes"];
  firstPublishedAt: string;
}
