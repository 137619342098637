import { TableCell, TableCellProps, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { format, getDay } from "date-fns";
import { ja } from "date-fns/locale";
import s from "../../../../../styles";
import { SystemCssProperties } from "@mui/system";

export function DateTableCell({
  date,
  holidays,
  beforeHolidays,
}: DateTableCellProps) {
  const day = useMemo(() => getDay(date), [date]);

  const dateString = useMemo(() => {
    return format(date, "yyyy-MM-dd");
  }, [date]);

  const isWeekend = useMemo(() => {
    return [0, 6].includes(day);
  }, [day]);

  const tooltipTitle = useMemo(
    () =>
      holidays.get(dateString) ||
      beforeHolidays.get(dateString) ||
      (isWeekend ? "週末" : ""),
    [holidays, beforeHolidays, dateString, isWeekend]
  );

  const classes: SystemCssProperties<{}>[] = [
    s.cell,
    isWeekend ? s.weekendDay : {},
    ...([
      [s.beforeHoliday, beforeHolidays],
      [s.holiday, holidays],
    ] as [SystemCssProperties<{}>[], Map<string, string>][]).flatMap(
      ([style, map]) => {
        if (map.has(dateString)) return style;
        return [];
      }
    ),
  ];

  return (
    <Tooltip placement="top" title={tooltipTitle}>
      <TableCell sx={classes}>
        {format(date, "d")}
        <br />
        {format(date, "eee", { locale: ja })}
      </TableCell>
    </Tooltip>
  );
}

type DateTableCellProps = {
  date: Date;
  holidays: Map<string, string>;
  beforeHolidays: Map<string, string>;
} & TableCellProps;
