type ImageInputProps = {
  inputRef: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function ImageInput({ inputRef, onChange }: ImageInputProps) {
  return (
    <input
      type="file"
      style={{ display: "none" }}
      accept=".png,.jpg"
      ref={inputRef}
      onChange={onChange}
    />
  );
}
