import { IconButton, SvgIconTypeMap } from "@mui/material";
import { Box } from "@mui/system";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import { useCallback, useEffect, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import React from "react";
import "./index.css";
import { OverridableComponent } from "@mui/material/OverridableComponent";

// modulo op that handles negative numbers
// https://stackoverflow.com/questions/4467539/javascript-modulo-gives-a-negative-result-for-negative-numbers
const modulo = (n: number, m: number) => ((n % m) + m) % m;

const getImageId = (index: number) => `image-slide-${index}`;

type indexOrRelativeIndex =
  | {
      index: number;
      relativeIndex?: never;
    }
  | {
      index?: never;
      relativeIndex: number;
    };

export default function Lightbox({ images }: { images: string[] }) {
  const [currentPicture, setCurrentPicture] = useState(0);

  const changePicture = useCallback(
    ({ index, relativeIndex }: indexOrRelativeIndex) => {
      const nextPicture =
        index ?? modulo(currentPicture + relativeIndex!, images.length);
      setCurrentPicture(nextPicture);
    },
    [currentPicture, images.length]
  );

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      switch (e.key) {
        case "ArrowRight":
          changePicture({ relativeIndex: 1 });
          break;
        case "ArrowLeft":
          changePicture({ relativeIndex: -1 });
          break;
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [changePicture]);

  return (
    <Box className="lightbox">
      <Box className="slider-wrapper">
        <Box className="image-reel">
          {images.map((image, index) => {
            return (
              <LightboxImage
                key={index}
                active={index === currentPicture}
                image={image}
                index={index}
              />
            );
          })}
        </Box>
      </Box>
      <Box className="interface-container">
        <InterfaceButton
          handleClick={() => window.open(images[currentPicture])}
          Icon={FitScreenIcon}
          flexGrow={4}
          order={2}
        />

        {images.length > 1 && (
          <>
            <InterfaceButton
              handleClick={() => changePicture({ relativeIndex: -1 })}
              Icon={ArrowBackIosNewIcon}
              flexGrow={1}
              order={1}
            />
            <InterfaceButton
              handleClick={() => changePicture({ relativeIndex: 1 })}
              Icon={ArrowForwardIosIcon}
              flexGrow={1}
              order={3}
            />
            <Box className="image-selector-list">
              {images.map((_, index) => {
                return (
                  <IconButton
                    key={index}
                    onClick={() => changePicture({ index })}
                  >
                    {React.createElement(
                      index === currentPicture
                        ? CircleIcon
                        : CircleOutlinedIcon,
                      {
                        className: "image-selector",
                      }
                    )}
                  </IconButton>
                );
              })}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

interface LightboxImageProps {
  index: number;
  image: string;
  active: boolean;
}

const LightboxImage = ({ active, index, image }: LightboxImageProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (active) ref.current?.scrollIntoView();
  }, [active]);
  return (
    <div className="image-backdrop" id={getImageId(index)} ref={ref}>
      <img src={image} alt="Review photograph" />
    </div>
  );
};

const InterfaceButton = ({
  handleClick,
  Icon,
  flexGrow,
  order,
}: {
  handleClick: any;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  flexGrow: number;
  order: number;
}) => {
  return (
    <IconButton
      className="interface-button"
      sx={{
        flexGrow: flexGrow,
        order: order,
      }}
      onClick={handleClick}
    >
      <Icon className="interface-button-icon" />
    </IconButton>
  );
};
