import Tooltip from "@mui/material/Tooltip";
import { IconButton, Modal, Paper, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Lightbox from "../../../components/Lightbox";
import { useState } from "react";
import { IdAndName } from "./types";
import "./index.css";

export const LinkCell = (
  params: GridRenderCellParams<IdAndName>,
  getUrl: (id: string) => string
) => {
  return (
    <>
      {params.value.id && (
        <Tooltip title={params.value.name}>
          <div className="link-cell">
            <a
              href={getUrl(params.value.id.toString())}
              target="_blank"
              rel="noreferrer"
            >
              {params.value.name}
            </a>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export const ReviewCell = ({ content }: { content: string }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className="review-cell" onClick={handleOpen}>
        {content}
      </div>
      <Modal open={open} onClose={handleClose}>
        <Paper className="review-list-paper">
          <Typography>{content}</Typography>
        </Paper>
      </Modal>
    </>
  );
};

export const ImagesCell = ({ images }: { images: string[] }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <IconButton color="primary" onClick={handleOpen}>
        <PhotoCameraIcon />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <div>
          <Lightbox images={images} />
        </div>
      </Modal>
    </>
  );
};
