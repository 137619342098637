import { SxProps } from "@mui/system";

const styles: { [key: string]: SxProps } = {
  menuAppBar: {
    backgroundColor: "#c8a063",
    boxShadow: "none",
  },
  menuToolBar: {
    "@media (min-width:1200px)": {
      // eslint-disable-line no-useless-computed-key
      width: "1200px",
      marginLeft: "auto",
      marginRight: "auto",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  menuButtons: {
    marginLeft: "50px",
  },
  menuButton: {
    color: "#ffffff",
    "@media (max-width:768px)": {
      fontSize: "0px",
    },
  },
  externalButton: {
    backgroundColor: "#AA7942",
    color: "#ffffff",
    marginRight: "0.5rem",
  },
  rightMenu: {
    position: "absolute",
    right: "0px",
    "@media (max-width:1200px)": {
      // eslint-disable-line no-useless-computed-key
      right: "24px",
    },
  },
};

export default styles;
