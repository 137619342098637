import React, { useCallback, useMemo } from "react";
import { TableCell, Tooltip } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import styles from "../../../../../styles";
import { CrossCheckbox } from "./CheckboxIcon";
import { UseSchedules } from "../../../../useSchedules";
import { DateSchedule } from "../../../../useSchedules/useFetchSchedule";

type OpenCloseDayProps = {
  planId: string;
  date: string;
  hasTimeSlots: boolean;
  isCutoff: boolean;
  isLocked: boolean;
  status: DateSchedule["state"];
  isClosedDueToHoliday: boolean;
  toggleDate: UseSchedules["toggleDate"];
  isChecked: boolean;
};
const OpenCloseDay = ({
  planId,
  date,
  hasTimeSlots,
  isCutoff,
  isLocked,
  isChecked,
  isClosedDueToHoliday,
  toggleDate,
  ...props
}: OpenCloseDayProps) => {
  const disabled = useMemo(() => isLocked || isCutoff || isClosedDueToHoliday, [
    isLocked,
    isCutoff,
    isClosedDueToHoliday,
  ]);

  const toggleDateForDate = useCallback(() => toggleDate(planId, date), [
    planId,
    date,
    toggleDate,
  ]);

  const style = useMemo(
    () => ({
      ...styles.cell,
      ...(disabled ? styles.disabledCell : {}),
    }),
    [disabled]
  );

  return (
    <TableCell sx={style}>
      {!(isChecked || isCutoff || hasTimeSlots) && (
        <Tooltip
          title={"予約可能な時間帯が存在しないため、予約受入不可の状態です"}
        >
          <div
            style={{
              position: "absolute",
              right: 2,
              top: 2,
              color: "#a1a1a1",
            }}
          >
            <ErrorOutlineIcon />
          </div>
        </Tooltip>
      )}
      <CrossCheckbox
        disabled={disabled}
        checked={disabled || isChecked}
        onClick={toggleDateForDate}
      />
    </TableCell>
  );
};

export default React.memo(OpenCloseDay);
