import { SxProps } from "@mui/material";
import React from "react";

const venueSelector: SxProps = {
  marginTop: "5px",
  marginBottom: "0px",
};

const toolbar: SxProps = {
  marginTop: "15px",
  marginBottom: "10px",
};

const saveButton: SxProps = {
  "&:hover": { backgroundColor: "green" },
  backgroundColor: "green",
};

const monthText: SxProps = {
  paddingTop: "3px",
  fontSize: "25px",
};

const monthChangeButtons: SxProps = { backgroundColor: "#fff" };

const monthChangeButton: SxProps = {
  "&:hover": { border: "1px solid #000" },
  color: "#000",
  border: "1px solid #000",
};

const tableContainer: SxProps = {
  width: "auto",
  backgroundColor: "#fff",
  borderRadius: "5px",
  border: "1px solid rgba(200, 200, 200, 1)",
  maxHeight: "calc(100vh)"
};

const table: SxProps = {
  borderStyle: "hidden",
};

const cell: SxProps = {
  position: "relative",
  textAlign: "center",
  border: "1px solid rgba(200, 200, 200, 1)",
  padding: "10px",
};

const disabledCell: SxProps = {
  backgroundColor: "#ddd",
  opacity: 0.8,
};

const planDivider: SxProps = {
  borderTop: "2px solid black",
};

const planNameCell: SxProps = {
  minWidth: "300px",
  maxWidth: "300px",
  position: "sticky",
  left: 0,
  zIndex: 1,
  background: "white",
};

const linkToPlan: SxProps = {
  color: "black",
};

const planSpecifics: SxProps = {
  color: "gray",
  fontSize: "10px",
};

const weekendDay: SxProps = {
  backgroundColor: "#87b3f6",
  borderColor: "#4b79c0",
};

const beforeHoliday: SxProps = {
  backgroundColor: "#edbb6f",
  borderColor: "#AA7942",
};

const holiday: SxProps = {
  backgroundColor: "#c8a063",
  borderColor: "#AA7942",
};

const dayWithBookings: SxProps = {
  fontWeight: "bold",
  fontSize: "20px",
  cursor: "pointer",
};

const statusText: SxProps = {
  minWidth: "85px",
};

const unstyledList: React.CSSProperties = {
  listStyleType: "none",
  paddingLeft: "0px",
};

const viewPlanText: React.CSSProperties = {
  fontSize: "9px",
};

const stickyDayRow: SxProps = {
  position: "sticky",
  top: 64,
  zIndex: 14,
  background: "white",
};

const leftTopCell: SxProps = {
  position: "sticky",
  left: 0,
  zIndex: 100,
  background: "white",
};

const uneditableAvailabilityNotifications: SxProps = {
  position: "relative",
  left: "12.5em",
  fontSize: "1.4rem",
  minWidth: "250px",
};

const exports = {
  venueSelector,
  toolbar,
  saveButton,
  monthText,
  monthChangeButtons,
  monthChangeButton,
  tableContainer,
  table,
  cell,
  disabledCell,
  planDivider,
  planNameCell,
  linkToPlan,
  planSpecifics,
  weekendDay,
  beforeHoliday,
  holiday,
  dayWithBookings,
  statusText,
  unstyledList,
  viewPlanText,
  stickyDayRow,
  leftTopCell,
  uneditableAvailabilityNotifications,
};
export default exports;
