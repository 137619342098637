import { useContext } from "react";
import PaidItemCard from "./PaidItemCard";
import Stack from "@mui/material/Stack";
import { FieldValues, UseFieldArrayReturn } from "react-hook-form";
import { PaidOptionField } from "../types";
import { FormContext } from "../../components/FormContext";
import { getArrayFieldValue, setArrayFieldValue } from "../../formValues";

export default function PaidItemList({
  fieldArray,
  planId,
}: PaidItemListProps) {
  const { form } = useContext(FormContext);

  const { fields, remove } = fieldArray;

  const removeItem = (itemIndex: number) => {
    form.addRemovedItem({
      fieldName: PaidOptionField.plan_paid_items,
      fieldId: getArrayFieldValue({
        form,
        arrayName: PaidOptionField.plan_paid_items,
        itemIndex,
        fieldName: PaidOptionField.id,
      }),
    });
    remove(itemIndex);
  };

  return (
    <Stack>
      {fields.map((paidItem, i) => {
        return (
          <PaidItemCard
            planId={planId}
            remove={() => removeItem(i)}
            key={paidItem.id}
            paidItemId={paidItem.id}
            itemIndex={i}
            setImageUrl={(imageUrl: string | null) => {
              setArrayFieldValue({
                form,
                arrayName: PaidOptionField.plan_paid_items,
                itemIndex: i,
                fieldName: PaidOptionField.imageUrl,
                value: imageUrl,
              });
            }}
            formIdentifier={`${PaidOptionField.plan_paid_items}.${i}`}
            setDeleted={(isDeleted: boolean) =>
              setArrayFieldValue({
                form,
                arrayName: PaidOptionField.plan_paid_items,
                itemIndex: i,
                fieldName: PaidOptionField.isDeleted,
                value: isDeleted,
              })
            }
          />
        );
      })}
    </Stack>
  );
}

interface PaidItemListProps {
  planId: string;
  fieldArray: UseFieldArrayReturn<
    FieldValues,
    PaidOptionField.plan_paid_items,
    "id"
  >;
}
