import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { IconButton, Divider } from "@mui/material";
import { DragIndicator } from "@mui/icons-material";
import { UniqueIdentifier } from "@dnd-kit/core";

export default function SortableItem(props: SortableItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props.index });

  const style = {
    opacity: isDragging ? 0.2 : undefined,
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <>
      <ListItem 
        ref={setNodeRef} 
        style={style} 
        {...attributes}
        secondaryAction={
          <IconButton
            style={{
              cursor: "grab",
              marginLeft: "auto",
            }}
            {...listeners}
          >
            <DragIndicator />
          </IconButton>
        }
      >
        <ListItemText primary={props.label} />
      </ListItem>
      <Divider component="li" />
    </>
  );
}

interface SortableItemProps {
  index: UniqueIdentifier;
  label: string;
}
