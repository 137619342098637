import { useMemo } from "react";
import Chart from "react-apexcharts";
import {
  getFormattedStartOfWeek,
  getPastWeeksDates,
  groupDatesByWeek,
} from "../../../../../helpers/date-functions";

interface BookingsChartProps {
  bookings: Date[];
  rejections: Date[];
}

export default function BookingsChart({
  bookings,
  rejections,
}: BookingsChartProps) {
  const bookingSeries = useMemo(() => {
    return groupData(bookings);
  }, [bookings]);

  const rejectionSeries = useMemo(() => {
    return groupData(rejections);
  }, [rejections]);

  const state = useMemo(() => {
    return {
      options: {
        yaxis: {
          min: 0,
          max: Math.max(
            4,
            bookingSeries.reduce((acc, { y }) => Math.max(acc, y), 0)
          ),
          tickAmount: 4,
          labels: {
            formatter: (value: number) => value.toFixed(0),
          },
        },
        xaxis: {
          categories: pastWeekDates,
        },
        colors: ["#008ffb", "#eb4034"],

        chart: {
          id: "bookings-chart",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        stroke: { curve: "smooth" as "smooth" },
        dataLabels: {
          enabled: false,
        },
      },
      series: [
        {
          name: "予約リクエスト数",
          data: bookingSeries,
        },
        {
          name: "予約拒否数",
          data: rejectionSeries,
        },
      ],
    };
  }, [bookingSeries, rejectionSeries]);

  return <Chart options={state.options} series={state.series} type="area" />;
}

const groupData = (data: Date[]) => {
  const groupedData = groupDatesByWeek(data);
  return pastWeekDates.map((x) => ({
    x,
    y: groupedData.get(x) ?? 0,
  }));
};

const pastWeekDates = getPastWeeksDates(12).map(getFormattedStartOfWeek);
