import { useCallback, useEffect, useState } from "react";
import { useMutation, gql } from "@apollo/client";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Tooltip,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Reservation } from "..";
import ReservationDetails from "./ReservationDetails";
import { useNotifications } from "../../../../components/Notification";

interface RejectReservationProps {
  reservation: Reservation;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const rejectStyle: React.CSSProperties = {
  color: "red",
  fontWeight: "bold",
};

export default function RejectReservation({
  reservation,
  setLoading,
}: RejectReservationProps) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [rejectReservation, { loading, data }] = useMutation<
    RejectReservationResponse,
    RejectReservationInput
  >(REJECT_RESERVATION);

  useEffect(() => setLoading(loading), [loading, setLoading, data]);

  const closeDialog = useCallback(() => setDialogOpen(false), [setDialogOpen]);

  return (
    <>
      <Tooltip title="受入拒否する">
        <IconButton size="small" onClick={() => setDialogOpen(true)}>
          <CancelIcon style={{ color: "red" }} fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <ConfirmDialog
        rejectReservation={rejectReservation}
        dialogOpen={dialogOpen}
        closeDialog={closeDialog}
        reservation={reservation}
        setLoading={setLoading}
      />
    </>
  );
}

function ConfirmDialog({
  dialogOpen,
  closeDialog,
  reservation,
  rejectReservation,
  setLoading,
}: any) {
  const [closeTimeSlot, setCloseTimeSlot] = useState(true);
  const { showNotification } = useNotifications();

  useEffect(() => {
    // reset shouldCloseTimeSlot on closing modal
    if (!dialogOpen) setCloseTimeSlot(true);
  }, [dialogOpen]);

  function handleRejectReservation() {
    rejectReservation({
      variables: { reservationId: reservation.id, closeTimeSlot },
      refetchQueries: ["GetBookingsListData"],
    })
      .then((data: any) => {
        if (data?.data?.rejectReservation?.success === false) {
          setLoading(false);
          return showNotification({
            message:
              data?.data?.rejectReservation?.message ??
              "Reservation reject failed!",
            severity: "error",
          });
        }
        showNotification({
          message: "受入拒否が完了しました！",
          severity: "success",
        });
      })
      .catch(() =>
        showNotification({
          message: "受入拒否に失敗しました！",
          severity: "error",
        })
      );

    closeDialog();
  }

  return (
    <Dialog open={dialogOpen} onClose={closeDialog}>
      <DialogContent>
        この予約を<span style={rejectStyle}>受入不可</span>
        としてもよろしいですか？
        <ReservationDetails
          style={{ margin: "20px 0px" }}
          reservation={reservation}
        />
        <span style={rejectStyle}>「はい」</span>
        をご選択頂くと、ご予約者の登録メールアドレスへ予約受入不可のメールが送信されます。
        <FormGroup style={{ marginTop: 16 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={closeTimeSlot}
                onChange={({ target: { checked } }) =>
                  setCloseTimeSlot(checked)
                }
              />
            }
            label="タイムスロットを閉じる"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: "#000" }} onClick={closeDialog}>
          いいえ
        </Button>
        <Button style={{ color: "red" }} onClick={handleRejectReservation}>
          はい
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type RejectReservationInput = {
  reservationId: Reservation["id"];
  closeTimeSlot?: boolean;
};
type RejectReservationResponse = {
  rejectReservation: { success: boolean };
};
const REJECT_RESERVATION = gql`
  mutation RejectReservation($reservationId: ID!, $closeTimeSlot: Boolean) {
    rejectReservation(
      input: { reservationId: $reservationId, closeTimeSlot: $closeTimeSlot }
    ) {
      success
    }
  }
`;
