import path from "path";

import { useContext, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useNotifications } from "../../../../components/Notification";
import { FormContext } from "../components/FormContext";

export default function useUploadImage() {
  const [uploading, setUploading] = useState(false);
  const [uploadFile] = useMutation(UPLOAD_FILE_MUTATION);
  const { showNotification } = useNotifications();
  const { setNumUploading } = useContext(FormContext);

  const uploadImage = async (file: File, path: string) => {
    setUploading(true);
    setNumUploading((numUploading) => numUploading + 1);

    try {
      const { error, mediaType } = checkFile(file);
      if (error) throw new Error(error);

      const { data, errors } = await uploadFile({
        variables: {
          file,
          path,
        },
      });

      if (errors) throw errors[0];
      showNotification({ message: "Media uploaded", severity: "success" });

      return { mediaUrl: data.uploadFile.fileLocation, mediaType };
    } catch (e: any) {
      const error = e as Error;
      showNotification({
        message: `Uploading media failed, ${error.message}`,
        severity: "error",
      });

      return null;
    } finally {
      setUploading(false);
      setNumUploading((numUploading) => numUploading - 1);
    }
  };

  return { uploadImage, uploading };
}

function checkFile(file: File) {
  const { size } = file;
  const fileExtension = path.extname(file.name).toLowerCase();
  const maxImageSize = 10 * 1024 * 1024;
  if (size > maxImageSize) return { error: "file too big" };
  const isVideo = videoFileExtensions.includes(fileExtension);
  const isImage = imageFileExtensions.includes(fileExtension);
  if (!(isVideo || isImage)) return { error: "file is not a media file" };
  return { mediaType: isVideo ? "video" : "photo" };
}

export const imageFileExtensions = [
  ".jpg",
  ".jpeg",
  ".gif",
  ".png",
  ".apng",
  ".svg",
  ".avif",
  ".jfif",
  ".pjpeg",
  ".pjp",
  ".webp",
];

export const videoFileExtensions = [".mp4"];

const UPLOAD_FILE_MUTATION = gql`
  mutation UploadFileMutation($file: Upload!, $path: String!) {
    uploadFile(input: { file: $file, path: $path }) {
      succeeded
      fileLocation
    }
  }
`;
