import { Typography } from "@mui/material";
import { useMemo } from "react";
import { NotificationStack } from "../NotificationStack";
import { format } from "date-fns";
import s from "./EditNotificationStack.module.css";

export function EditNotificationStack({
  rowData,
  onClick,
  disabled,
}: EditNotificationStackProps) {
  const rows = useMemo(
    () =>
      rowData.map(({ planId, planName, createdAt }) => ({
        link: `/plans/edit/${planId}`,
        content: (
          <>
            <Typography className={s.RegularText}>{planId}</Typography>
            <Typography className={s.BoldText}>{planName}</Typography>
            <Typography className={s.RegularText}>
              {format(createdAt, "yyyy-MM-dd HH:mm:ss")}
            </Typography>
          </>
        ),
      })),
    [rowData]
  );

  const numNotifications = useMemo(
    () => rowData.reduce((acc, { count }) => acc + Math.sign(count), 0),
    [rowData]
  );

  return (
    <NotificationStack
      header={"プラン情報変更完了通知"}
      rows={rows}
      numNotifications={numNotifications}
      onClick={onClick}
      disabled={disabled}
    />
  );
}

type EditNotificationStackProps = {
  rowData: {
    planId: string;
    planName: string;
    count: number;
    createdAt: Date;
  }[];
  onClick?: () => {};
  disabled?: boolean;
};
