import { gql, useQuery } from "@apollo/client";
import SortablePlanTable from "./Components/SortablePlanTable";

export default function PlansList() {
  const { error: venuesError, data: venuesData } = useQuery<any>(GET_VENUES);

  const venues = venuesData?.venues ?? [];

  if (venuesError) {
    return <div>不具合が発生しました</div>;
  }

  return venues.length > 0 ? <SortablePlanTable venues={venues} /> : <></>;
}

export interface PlanJson {
  courseMenu: string;
  subtitle: string;
  description: string;
  remarks: string;
  highlights: {
    index: number;
    title: string;
    details: string;
    exclusive: boolean;
    media: {
      src: string;
      type: string;
    };
  }[];
  inclusions: {
    category: string;
    items: {
      id: string;
      title: string;
      description: string;
      iconName: string;
    }[];
  }[];
}

interface Activity {
  id: string;
  venue: Venue;
  priceTypes: {
    name: string;
    amount: string;
    currencyCode: string;
    active: boolean;
  }[];
  coursePhotos: {
    id: string;
    photoIndex: number;
    src: string;
  }[];
  json: {
    json: {
      name: string;
      courseMenu: string;
    };
  };
}

export interface PlanFAQ {
  id: string;
  priority: number;
  question: string;
  answer: string;
  imageUrl: string;
}

export interface PlanPaidItem {
  id: string;
  status: number;
  price: number;
  name: string;
  description: string;
  imageUrl: string;
}

export interface Plan {
  id: string;
  name: string;
  firstPublishedAt: string;
  planOrder: number;
  json: PlanJson;
  activities: Activity[];
  faqs: PlanFAQ[];
  planPaidItems: PlanPaidItem[];
  inclusions: {
    category: string;
    items: {
      id: string;
      title: string;
      description: string;
      iconName: string;
    }[];
  }[];
  bookings: {
    bookings: number[];
    rejections: number[];
    rejectionRate: number;
  };
  publishedPlan: {
    id: string;
  };
}

export interface Venue {
  id: string;
  name: string;
}

const GET_VENUES = gql`
  query GetVenues {
    venues {
      id
      name
    }
  }
`;
