import { useContext, useState } from "react";
import { Plan } from "../../List";
import ControlledTextField from "../BasicInfo/components/ControlledTextField";
import { FormContext } from "../components/FormContext";
import { getFieldState } from "../formValues";
import { Revertable } from "../components/Revertable";
import { ActivitiesField } from "./types";
import CoursePhotoList from "./components/CoursePhotoList";
import { useFieldArray } from "react-hook-form";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value != index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value == index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function ActivitiesEditorForm() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { form, planData } = useContext(FormContext);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
        >
          {planData?.activities?.map((activity: any, index: number) => (
            <Tab
              wrapped
              label={planData.activities?.[index]?.json?.json?.name}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {planData?.json?.courseMenu?.map((course: any, index: number) => (
        <CustomTabPanel value={value} index={index}>
          <div className="Activities">
            <Revertable
              fieldName={`${ActivitiesField.Course}.${index}.${ActivitiesField.CourseMenu}`}
              isCourse
            >
              <ControlledTextField
                name={`${ActivitiesField.Course}.${index}.${ActivitiesField.CourseMenu}`}
                label="コース内容"
                control={form.control}
                multiline
                fullWidth
                className={getFieldState({
                  form,
                  fieldName: `${ActivitiesField.Course}.${index}.${ActivitiesField.CourseMenu}`,
                })}
              />
            </Revertable>
            <div style={{ marginTop: "2rem" }}>
              <CoursePhotoList planId={form.planId} index={index} />
            </div>
          </div>
        </CustomTabPanel>
      ))}
    </Box>
  );
}

function getDefaultValuesActivities(plan: Plan) {
  return {
    [ActivitiesField.Course]: plan.activities.map((a: any, index) => {
      return {
        fieldId: plan.activities?.[index].id,
        [ActivitiesField.CourseMenu]:
          plan.activities?.[index]?.json?.json?.courseMenu,
        [ActivitiesField.CoursePhotos]: plan.activities?.[index].coursePhotos
          .slice()
          .sort((a, b) => a.photoIndex - b.photoIndex)
          .map((coursePhoto) => ({
            ...coursePhoto,
            fieldId: coursePhoto.id,
            [ActivitiesField.CoursePhotoIsDeleted]: false,
          })),
      };
    }),
  };
}

const activitiesExport = {
  form: ActivitiesEditorForm,
  getDefaultValues: getDefaultValuesActivities,
};
export default activitiesExport;
