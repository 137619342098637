import { Badge, IconButton } from "@mui/material";
import s from "./NotificationButton.module.css";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

export function NotificationButton({
  numNotifications,
  active,
  handleClick,
  disabled,
}: NotificationButtonProps) {
  return (
    <IconButton disabled={disabled ?? false} onClick={handleClick}>
      <Badge
        className={s.NotificationBadge}
        badgeContent={numNotifications}
        color="primary"
      >
        {numNotifications === 0 ? (
          <NotificationsNoneIcon />
        ) : active ? (
          <NotificationsActiveIcon className={s.ActiveNotificationIcon} />
        ) : (
          <NotificationsIcon />
        )}
      </Badge>
    </IconButton>
  );
}

type NotificationButtonProps = {
  numNotifications: number;
  active: boolean;
  handleClick: any;
  disabled?: boolean;
};
