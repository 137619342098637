import { useState } from "react";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import MoneyIcon from "@mui/icons-material/Money";
import InsightsIcon from "@mui/icons-material/Insights";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styles from "./styles";

export default function ReportsMenu() {
  const [menuAchor, setMenuAnchor] = useState<Element | null>(null);

  return (
    <>
      <Button
        sx={styles.menuButton}
        startIcon={<BarChartIcon />}
        onClick={(e: { currentTarget: Element }) =>
          setMenuAnchor(e.currentTarget)
        }
      >
        レポート
        <ArrowDropDownIcon />
      </Button>
      <Menu
        style={{ color: "red" }}
        open={Boolean(menuAchor)}
        anchorEl={menuAchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setMenuAnchor(null)}
      >
        <MenuItem component="a" href="/reports/costs">
          <ListItemIcon>
            <MoneyIcon style={{ color: "#c8a063" }} fontSize="small" />
          </ListItemIcon>
          <ListItemText>実績の確認</ListItemText>
        </MenuItem>
        <MenuItem component="a" href="/reports/dashboard">
          <ListItemIcon>
            <InsightsIcon style={{ color: "#c8a063" }} fontSize="small" />
          </ListItemIcon>
          <ListItemText>パフォーマンス</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
