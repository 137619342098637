import { Reservation } from "..";

interface ReservationDetailsProps {
  style?: React.CSSProperties;
  reservation: Reservation;
}

export default function ReservationDetails(props: ReservationDetailsProps) {
  return (
    <div style={props.style ? props.style : undefined}>
      <b>ご予約者氏名:</b> {props.reservation.booking.familyName}{" "}
      {props.reservation.booking.givenName} 様 （
      {props.reservation.booking.familyNameFurigana}{" "}
      {props.reservation.booking.givenNameFurigana}）
      <br />
      <b>店舗名:</b> {props.reservation.activity.venue.name}
      <br />
      <b>プラン名:</b> {props.reservation.activity.plan.name}
      <br />
      <b>予約日時:</b> {props.reservation.datetime}
      <br />
      <b>ご予約人数:</b>{" "}
      {props.reservation.items.map((i, index) => (
        <span key={index}>
          {i.priceType.name}: {i.quantity}{" "}
        </span>
      ))}
    </div>
  );
}
