import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageUploaderCard from "../../components/ImageUploaderCard";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { FaqsField } from "../types";
import ControlledTextField from "../../BasicInfo/components/ControlledTextField";
import {
  getArrayFieldValue,
  getArrayFieldState,
  getArrayItemState,
} from "../../formValues";
import { FormContext } from "../../components/FormContext";
import { useContext, useMemo } from "react";
import { RevertableArrayItem } from "../../components/Revertable";
import { FieldState } from "../../types";
import "./FaqCard.css";

export default function FaqCard({
  remove,
  planId,
  faqId,
  itemIndex,
  setImageUrl,
  formIdentifier,
  setDeleted,
}: FaqCardProps) {
  const { form } = useContext(FormContext);
  const arrayName = FaqsField.faqs;
  const { control, watch } = form;

  const isDeleted =
    getArrayFieldValue({
      form,
      arrayName,
      itemIndex,
      fieldName: FaqsField.isDeleted,
    }) ?? false;

  const isAdded =
    getArrayItemState({ form, arrayName, itemIndex }) === FieldState.added;

  const imageProps = useMemo(() => {
    return isDeleted
      ? {
          style: {
            opacity: "30%",
          },
        }
      : {};
  }, [isDeleted]);

  return (
    <div className="EditorFaqCardContainer">
      <RevertableArrayItem
        arrayName={arrayName}
        itemIndex={itemIndex}
        remove={remove}
      >
        <div
          className={`EditorFaqCard ${getArrayItemState({
            form,
            arrayName,
            itemIndex,
          })}`}
        >
          <ImageUploaderCard
            mediaUrl={watch(`${formIdentifier}.${FaqsField.imageUrl}`)}
            setMediaUrl={setImageUrl}
            mutable={!isDeleted}
            path={`plans/${planId}/faqs/${faqId}`}
            imageProps={imageProps}
          />

          <ControlledTextField
            control={control}
            name={`${formIdentifier}.${FaqsField.question}`}
            label={"質問"}
            multiline
            rows={6}
            disabled={isDeleted}
            className={getArrayFieldState({
              form,
              arrayName,
              itemIndex,
              fieldName: FaqsField.question,
            })}
          />
          <ControlledTextField
            control={control}
            name={`${formIdentifier}.${FaqsField.answer}`}
            label={"回答"}
            multiline
            rows={6}
            disabled={isDeleted}
            className={getArrayFieldState({
              form,
              arrayName,
              itemIndex,
              fieldName: FaqsField.answer,
            })}
          />

          {isDeleted ? (
            <Tooltip title="undelete">
              <span>
                <IconButton onClick={() => setDeleted(false)}>
                  <RestoreFromTrashIcon />
                </IconButton>
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="delete">
              <span>
                <IconButton onClick={() => setDeleted(true)} disabled={isAdded}>
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </div>
      </RevertableArrayItem>
    </div>
  );
}

interface FaqCardProps {
  planId: string;
  faqId: string;
  itemIndex: number;
  setImageUrl: (imageUrl: string | null) => void;
  formIdentifier: string;
  setDeleted: (isDeleted: boolean) => void;
  remove: () => void;
}
