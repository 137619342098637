import { FieldValues, UseFormReturn } from "react-hook-form";

export type FieldItem = {
  fieldId: string;
  fieldName: string;
  [key: string]: any;
};

export type Form = UseFormReturn<FieldValues, any> & {
  planId: string;
  originalValues: any;
  setOriginalValues: any;
  removedItems: { fieldId: string; fieldName: string }[];
  addRemovedItem: ({
    fieldId,
    fieldName,
  }: {
    fieldId: string;
    fieldName: string;
  }) => void;
  resetRemovedItems: () => void;
};

export enum FieldState {
  untouched = "UNTOUCHED",
  touched = "TOUCHED",
  added = "ADDED",
  removed = "REMOVED",
}

export type ObjectWithFieldId = {
  fieldId?: string;
  [key: string]: any;
};
