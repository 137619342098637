import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  SxProps,
  TextField as MuiTextField,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import React, { useState } from "react";
import cloneDeep from "lodash/cloneDeep";

import { PlanScheduleType } from ".";
import Card from "../../../../components/Card";
import TextField from "../../../../components/TextField";
import { format } from "date-fns";
import { isProductionEnvironment } from "../../../../helpers/environment";

interface PlanScheduleProps {
  plan: PlanScheduleType;
  updatePlan: (plan: PlanScheduleType) => void;
}

function PlanSchedule(props: PlanScheduleProps) {
  const [addingTimeSlot, setAddingTimeSlot] = useState("");

  const { plan } = props;
  const storefrontUrl = isProductionEnvironment
    ? "https://oiwai.anny.gift"
    : "https://staging-oiwai.anny.gift";

  const timeSlots = plan.schedule.days.reduce<{ [dotwCode: string]: string[] }>(
    (pv, cv) => {
      if (cv.dayOfTheWeek) pv[cv.dayOfTheWeek] = cv.timeSlots;
      if (cv.holiday) pv[cv.holiday] = cv.timeSlots;

      return pv;
    },
    {}
  );

  return (
    <Stack style={{ marginBottom: "50px" }} spacing={2}>
      <div style={{ fontSize: "20px", fontWeight: "bold" }}>
        {plan.planName} <br />
        <a
          style={{ fontSize: "10px", color: "#000" }}
          href={`${storefrontUrl}/celebration-plans/${plan.planId}`}
          target="_blank"
          rel="noreferrer"
        >
          このプランの公開ページを見る
        </a>
      </div>

      <Card>
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            textalign="center"
            label="受付締切期日（単位:時間）"
            startadornment={<AccessTimeIcon />}
            value={plan.schedule.cutoffTimeHours}
            onChange={(e) => updateField("cutoffTimeHours", e.target.value)}
          />
          <TextField
            size="small"
            textalign="center"
            label="先々の販売月数 （単位：ヶ月）"
            startadornment={<DateRangeIcon />}
            value={plan.schedule.bookablePeriodMonths}
            onChange={(e) =>
              updateField("bookablePeriodMonths", e.target.value)
            }
          />
        </Stack>
      </Card>
      <Card>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <DayOfTheWeek dotw="日曜日" dotwCode="SU" />
              <DayOfTheWeek dotw="月曜日" dotwCode="MO" />
              <DayOfTheWeek dotw="火曜日" dotwCode="TU" />
              <DayOfTheWeek dotw="水曜日" dotwCode="WE" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <DayOfTheWeek dotw="木曜日" dotwCode="TH" />
              <DayOfTheWeek dotw="金曜日" dotwCode="FR" />
              <DayOfTheWeek dotw="土曜日" dotwCode="SA" />
              <DayOfTheWeek dotw="祝日" dotwCode="祝日" />
              <DayOfTheWeek dotw="祝前日" dotwCode="祝前日" />
            </Stack>
          </Grid>
        </Grid>
      </Card>
      <AddTimeSlotModal />
    </Stack>
  );

  function DayOfTheWeek(props: { dotw: string; dotwCode: string }) {
    const dotwTimeSlots = timeSlots[props.dotwCode];

    return (
      <Grid container direction="row" alignItems="center">
        <Grid item xs={3} sm={2}>
          <Box sx={sx.scheduleDotw}>{props.dotw}</Box>
        </Grid>
        <Grid item xs={9} sm={10}>
          <Box sx={{ flexGrow: 1 }}>
            {dotwTimeSlots.map((t, k) => (
              <Chip
                sx={sx.chip}
                key={k}
                label={t}
                onDelete={() => removeTimeSlot(props.dotwCode, t)}
              />
            ))}
            <IconButton
              size="small"
              onClick={() => setAddingTimeSlot(props.dotwCode)}
            >
              <AddCircleIcon fontSize="small" />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    );
  }

  function AddTimeSlotModal() {
    const [newTimeSlot, setNewTimeSlot] = useState<Date | null>(null);

    return (
      <Dialog
        open={addingTimeSlot !== ""}
        onClose={() => setAddingTimeSlot("")}
      >
        <DialogContent>
          <Stack spacing={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                value={newTimeSlot}
                onChange={(newValue) => {
                  setNewTimeSlot(newValue);
                }}
                renderInput={(params) => (
                  <MuiTextField
                    size="small"
                    sx={sx.timeTextField}
                    {...params}
                  />
                )}
                ampm={false}
              />
            </LocalizationProvider>
            <Button
              disabled={newTimeSlot === null}
              variant="outlined"
              sx={sx.addTimeSlotButton}
              onClick={() => {
                if (newTimeSlot)
                  addTimeSlot(addingTimeSlot, format(newTimeSlot, "HH:mm"));
              }}
            >
              予約スロットを追加する
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  }

  function updateField(
    field: "cutoffTimeHours" | "bookablePeriodMonths",
    value: string
  ) {
    const newPlan = cloneDeep(plan);
    newPlan.schedule[field] = parseInt(value) || 0;
    props.updatePlan(newPlan);
  }

  function removeTimeSlot(dotw: string, timeSlot: string) {
    const newPlan = cloneDeep(plan);
    newPlan.schedule.days = newPlan.schedule.days.map((d) =>
      d.dayOfTheWeek !== dotw && d.holiday !== dotw
        ? d
        : { ...d, timeSlots: d.timeSlots.filter((t) => t !== timeSlot) }
    );
    props.updatePlan(newPlan);
  }

  function addTimeSlot(dotw: string, timeSlot: string) {
    const newPlan = cloneDeep(plan);
    newPlan.schedule.days = newPlan.schedule.days.map((d) => {
      if (d.dayOfTheWeek !== dotw && d.holiday !== dotw) return d;

      d.timeSlots.push(timeSlot);
      d.timeSlots.sort();
      // remove duplicates
      d.timeSlots = d.timeSlots.filter((t, i) => d.timeSlots.indexOf(t) === i);
      return d;
    });
    props.updatePlan(newPlan);

    setAddingTimeSlot("");
  }
}

export default React.memo(PlanSchedule);

const sx: { [id: string]: SxProps } = {
  scheduleDotw: {
    fontSize: "20px",
    fontWeight: "bold",
    width: "100px",
  },
  addTimeSlotButton: {
    borderColor: "black",
    color: "black",
    "&:hover": {
      borderColor: "black",
      backgroundColor: "#eee",
    },
  },
  chip: {
    margin: "2px",
  },
  timeTextField: {
    width: "140px",
    marginLeft: "auto",
    marginRight: "auto",
  },
};
