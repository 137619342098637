import React, { useState, useMemo, useCallback } from "react";
import { TableCell } from "@mui/material";
import styles from "../../../../../styles";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import LockIcon from "@mui/icons-material/Lock";
import { Box, SxProps } from "@mui/system";
import Day, { DayProps } from "./Day";
import { AvailabilityStatus } from "../../../../../../../../types";
import {
  UseSchedules,
  getAvailableTimeSlots,
  getClosedTimeSlots,
  isExistingTimeSlot,
  isCutoff,
  isDefaultTimeSlot,
} from "../../../../useSchedules";
import { PlanSchedule } from "../../../../useSchedules/useFetchSchedule";

type StatusProps = {
  schedule: PlanSchedule;
  isClosed: boolean;
  isClosedDueToHoliday: boolean;
  isLocked?: boolean;
  status?: AvailabilityStatus;
  hasTimeSlots: boolean;
  allTimeSlotsAreClosed: boolean;
  isCutoff: typeof isCutoff;
  closedTimeSlots: ReturnType<typeof getClosedTimeSlots>;
  toggleAllTimeSlots: UseSchedules["toggleAllTimeSlots"];
  toggleTimeSlot: UseSchedules["toggleTimeSlot"];
  addTimeSlot: UseSchedules["addTimeSlot"];
} & Pick<DayProps, "planId" | "date">;

const Status = ({
  planId,
  schedule,
  date,
  isLocked,
  status,
  closedTimeSlots,
  allTimeSlotsAreClosed,
  hasTimeSlots,
  isClosed,
  isCutoff,
  isClosedDueToHoliday,
  toggleTimeSlot,
  toggleAllTimeSlots,
  addTimeSlot,
}: StatusProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleAllTimeSlotsForDate = useCallback(
    () => toggleAllTimeSlots(schedule, date),
    [schedule, toggleAllTimeSlots, date]
  );

  const toggleTimeSlotForDate = useCallback(
    (timeSlot: string) => toggleTimeSlot(schedule, date, timeSlot),
    [schedule, toggleTimeSlot, date]
  );

  const addTimeSlotForDate = useCallback(
    (timeSlot: string) => addTimeSlot(schedule, date, timeSlot),
    [schedule, addTimeSlot, date]
  );

  const isExistingTimeSlotForDate = useCallback(
    (timeSlot: string) => isExistingTimeSlot(schedule, date, timeSlot),
    [schedule, date]
  );

  const isDefaultTimeSlotForDate = useCallback(
    (timeSlot: string) => isDefaultTimeSlot(schedule, date, timeSlot),
    [schedule, date]
  );

  const isCutoffForDate = useCallback(
    (timeSlot: string = "23:59") => isCutoff(schedule, date, timeSlot),
    [schedule, date, isCutoff]
  );

  const isDisabled = useMemo(
    () => isClosed || isCutoffForDate() || isClosedDueToHoliday,
    [isClosed, isClosedDueToHoliday, isCutoffForDate]
  );

  const sxColor = useCallback(
    (color: string) => ({ color: isDisabled ? "gray" : color }),
    [isDisabled]
  );

  const availableTimeSlots = useMemo(
    () => getAvailableTimeSlots(schedule, date),
    [schedule, date]
  );

  const statusIcon = React.useMemo(() => {
    return {
      AVAILABLE: <EventAvailableIcon sx={sxColor("green")} />,
      NOT_AVAILABLE: <EventBusyIcon sx={sxColor("red")} />,
      PARTIALLY_AVAILABLE: <ChangeHistoryIcon sx={sxColor("orange")} />,
      LOCKED: <LockIcon sx={{ color: "#323232" }} />,
    };
  }, [sxColor]);

  const style = useMemo(
    () => ({ ...styles.cell, ...(isDisabled ? styles.disabledCell : {}) }),
    [isDisabled]
  );

  return (
    <TableCell sx={style}>
      <Box
        sx={isDisabled ? {} : sx.clickable}
        onClick={() => {
          if (!isDisabled) setModalOpen(!modalOpen);
        }}
      >
        {isLocked ? (
          statusIcon.LOCKED
        ) : isDisabled ? (
          statusIcon.NOT_AVAILABLE
        ) : status !== undefined ? (
          statusIcon[status]
        ) : (
          <></>
        )}
      </Box>
      {modalOpen && (
        <Day
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          date={date}
          planId={planId}
          availableTimeSlots={availableTimeSlots}
          closedTimeSlots={closedTimeSlots}
          addTimeSlot={addTimeSlotForDate}
          toggleTimeSlot={toggleTimeSlotForDate}
          isDefaultTimeSlot={isDefaultTimeSlotForDate}
          allTimeSlotsAreClosed={allTimeSlotsAreClosed}
          hasTimeSlots={hasTimeSlots}
          toggleAllTimeSlots={toggleAllTimeSlotsForDate}
          isExistingTimeSlot={isExistingTimeSlotForDate}
          isCutoff={isCutoffForDate}
        />
      )}
    </TableCell>
  );
};

export default React.memo(
  Status,
  (
    { schedule: prevSchedule, ...prevProps },
    { schedule: nextSchedule, ...nextProps }
  ) =>
    Object.is(prevProps, nextProps) &&
    Object.is(
      prevSchedule.dates[prevProps.date],
      nextSchedule.dates[nextProps.date]
    )
);

const sx: { [id: string]: SxProps } = {
  clickable: {
    cursor: "pointer",
  },
};
