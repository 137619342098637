import { IconButton, Tooltip, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageUploaderCard from "../../components/ImageUploaderCard";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { TextField, Autocomplete } from "@mui/material";
import { Controller } from "react-hook-form";
import { PaidOptionField } from "../types";
import ControlledTextField from "../../BasicInfo/components/ControlledTextField";
import {
  getArrayFieldValue,
  getArrayFieldState,
  getArrayItemState,
} from "../../formValues";
import { FormContext } from "../../components/FormContext";
import { useContext, useMemo } from "react";
import { RevertableArrayItem } from "../../components/Revertable";
import { FieldState } from "../../types";
import "./PaidItemCard.css";

export default function PaidItemCard({
  remove,
  planId,
  paidItemId,
  itemIndex,
  setImageUrl,
  formIdentifier,
  setDeleted,
}: PaidItemCardProps) {
  const { form } = useContext(FormContext);
  const arrayName = PaidOptionField.plan_paid_items;
  const { control, watch } = form;

  const isDeleted =
    getArrayFieldValue({
      form,
      arrayName,
      itemIndex,
      fieldName: PaidOptionField.isDeleted,
    }) ?? false;

  const isAdded =
    getArrayItemState({ form, arrayName, itemIndex }) === FieldState.added;

  const imageProps = useMemo(() => {
    return isDeleted
      ? {
          style: {
            opacity: "30%",
          },
        }
      : {};
  }, [isDeleted]);

  return (
    <div className="EditorPaidItemCardContainer">
      <RevertableArrayItem
        arrayName={arrayName}
        itemIndex={itemIndex}
        remove={remove}
      >
        <div
          className={`EditorPaidItemCard ${getArrayItemState({
            form,
            arrayName,
            itemIndex,
          })}`}
        >
          <ImageUploaderCard
            mediaUrl={watch(`${formIdentifier}.${PaidOptionField.imageUrl}`)}
            setMediaUrl={setImageUrl}
            mutable={!isDeleted}
            path={`plans/${planId}/paid_items/${paidItemId}`}
            imageProps={imageProps}
          />
          <Box className="EditorPaidItemCardInputs">
            <ControlledTextField
              control={control}
              name={`${formIdentifier}.${PaidOptionField.name}`}
              label={"有料オプション名"}
              disabled={isDeleted}
              className={getArrayFieldState({
                form,
                arrayName,
                itemIndex,
                fieldName: PaidOptionField.name,
              })}
            />
            <ControlledTextField
              control={control}
              name={`${formIdentifier}.${PaidOptionField.price}`}
              label={"有料オプションの価格"}
              disabled={isDeleted}
              className={getArrayFieldState({
                form,
                arrayName,
                itemIndex,
                fieldName: PaidOptionField.price,
              })}
            />
            <ControlledTextField
              control={control}
              name={`${formIdentifier}.${PaidOptionField.description}`}
              label={"有料オプションの説明"}
              multiline
              rows={3}
              disabled={isDeleted}
              className={getArrayFieldState({
                form,
                arrayName,
                itemIndex,
                fieldName: PaidOptionField.description,
              })}
            />
            <Controller
              control={control}
              name={`${formIdentifier}.${PaidOptionField.status}`}
              render={({ field: { onChange, value, ref } }) => (
                <Autocomplete
                  defaultValue={value}
                  onChange={(_, value) => {
                    onChange(value);
                  }}
                  getOptionLabel={(option) => (option == 1 ? "Show" : "Hide")}
                  options={[1, 0]}
                  renderInput={(params) => (
                    <TextField {...params} label="状態" />
                  )}
                  ref={ref}
                />
              )}
            />
          </Box>
          {isDeleted ? (
            <Tooltip title="undelete">
              <span>
                <IconButton onClick={() => setDeleted(false)}>
                  <RestoreFromTrashIcon />
                </IconButton>
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="delete">
              <span>
                <IconButton onClick={() => setDeleted(true)} disabled={isAdded}>
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </div>
      </RevertableArrayItem>
    </div>
  );
}

interface PaidItemCardProps {
  planId: string;
  paidItemId: string;
  itemIndex: number;
  setImageUrl: (imageUrl: string | null) => void;
  formIdentifier: string;
  setDeleted: (isDeleted: boolean) => void;
  remove: () => void;
}
