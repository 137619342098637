import { useState } from "react";
import { Button, Stack } from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import { ja } from "date-fns/locale";
import { default as MuiTextField } from "@mui/material/TextField";
import { format, isValid, parse } from "date-fns";

interface DateRangePickerProps {
  from: { value: string | null; set: (value: string | null) => void };
  until: { value: string | null; set: (value: string | null) => void };
  onClose: (event: any) => void;
  maxDate?: Date;
}

export default function DateRangePicker(props: DateRangePickerProps) {
  const [fromOpen, setFromOpen] = useState(false);
  const [untilOpen, setUntilOpen] = useState(false);

  const [from, setFrom] = useState(props.from.value);
  const [until, setUntil] = useState(props.until.value);

  const [fromHelper, setFromHelper] = useState<string | null>(null);
  const [untilHelper, setUntilHelper] = useState<string | null>(null);

  return (
    <div style={{ padding: "5px" }}>
      <Stack direction="row" spacing={1}>
        <LocalizationProvider locale={ja} dateAdapter={AdapterDateFns}>
          <DatePicker
            maxDate={props.maxDate}
            leftArrowButtonText="前月"
            rightArrowButtonText="来月"
            open={fromOpen}
            onOpen={() => setFromOpen(true)}
            onClose={() => setFromOpen(false)}
            label="From"
            value={from ? parse(from, "yyyy-MM-dd", new Date()) : null}
            onChange={(d) => {
              d && isValid(d)
                ? setFrom(format(d, "yyyy-MM-dd"))
                : setFrom(null);
            }}
            renderInput={(params) => (
              <MuiTextField
                onClick={() => setFromOpen(true)}
                helperText={fromHelper ? errorText(fromHelper) : undefined}
                size="small"
                style={{ width: "150px" }}
                InputProps={{
                  readOnly: true,
                }}
                {...params}
              />
            )}
            inputFormat="yyyy-MM-dd"
          />
          <DatePicker
            maxDate={props.maxDate}
            leftArrowButtonText="前月"
            rightArrowButtonText="来月"
            open={untilOpen}
            onOpen={() => setUntilOpen(true)}
            onClose={() => setUntilOpen(false)}
            label="Until"
            value={until ? parse(until, "yyyy-MM-dd", new Date()) : null}
            onChange={(d) => {
              d && isValid(d)
                ? setUntil(format(d, "yyyy-MM-dd"))
                : setUntil(null);
            }}
            renderInput={(params) => (
              <MuiTextField
                onClick={() => setUntilOpen(true)}
                helperText={untilHelper ? errorText(untilHelper) : undefined}
                size="small"
                style={{ width: "150px" }}
                {...params}
              />
            )}
            inputFormat="yyyy-MM-dd"
          />
        </LocalizationProvider>
      </Stack>
      <Stack direction="row" justifyContent="right">
        {(from || until) && <Button onClick={handleReset}>Reset</Button>}
        <Button onClick={handleConfirm}>Confirm</Button>
      </Stack>
    </div>
  );

  function errorText(text: string) {
    return <span style={{ color: "red" }}>{text}</span>;
  }

  function handleReset() {
    setFrom(null);
    setUntil(null);
    return;
  }

  function handleConfirm(event: any) {
    let valid = true;

    if (from === null && until !== null) {
      setFromHelper("値が正しくない");
      valid = false;
    }
    if (until === null && from !== null) {
      setUntilHelper("値が正しくない");
      valid = false;
    }

    if (
      from !== null &&
      until !== null &&
      parse(from, "yyyy-MM-dd", new Date()) >
        parse(until, "yyyy-MM-dd", new Date())
    ) {
      setFromHelper(null);
      setUntilHelper("からより遅くなるまで");
      valid = false;
    }

    if (valid) {
      props.from.set(from);
      props.until.set(until);
      props.onClose(event);
    }
  }
}
