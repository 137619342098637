import {
  TableContainer,
  Table as MuiTable,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  TableHead,
} from "@mui/material";
import {
  getDaysInMonth,
  add,
  startOfMonth,
  isAfter,
  isSameDay,
} from "date-fns";
import { useMemo } from "react";
import styles from "../../styles";
import OpenCloseAll from "./OpenCloseAll";
import { DateTableCell } from "./Plan/components/atoms/DateTableCell";
import { UseSchedules } from "../useSchedules";
import Plan from "./Plan";
import { parseDate, formatDate, getHolidays } from "../helpers";

type TableProps = Pick<
  UseSchedules,
  | "plans"
  | "saving"
  | "toggleDate"
  | "toggleDateForAll"
  | "toggleTimeSlot"
  | "addTimeSlot"
  | "schedules"
  | "numBookings"
  | "numApproval"
  | "numApprovalPrevMonth"
  | "numTotalBookings"
  | "numTotalBookingsPrevMonth"
  | "setSchedules"
  | "toggleAllTimeSlots"
  | "toggleHoliday"
> & {
  month: Date;
};

export default function Table({
  plans,
  saving,
  toggleDate,
  toggleTimeSlot,
  addTimeSlot,
  toggleDateForAll,
  schedules,
  numBookings,
  numApproval,
  numApprovalPrevMonth,
  numTotalBookings,
  numTotalBookingsPrevMonth,
  setSchedules,
  toggleAllTimeSlots,
  toggleHoliday,
  month,
}: TableProps) {
  const holidays = useMemo(() => {
    // holiday date -> holiday name
    const currentYear = new Date().getFullYear();
    return new Map(
      Array.from({ length: 3 }, (_, i) => i).flatMap((deltaYear) =>
        getHolidays(currentYear + deltaYear).map((holiday) => [
          holiday.date.slice(0, 10),
          holiday.name,
        ])
      )
    );
  }, []);

  const beforeHolidays = useMemo(
    () =>
      new Map(
        Array.from(holidays).map(([key, holidayName]) => {
          const date = new Date(key);
          date.setDate(date.getDate() - 1);
          return [date.toISOString().slice(0, 10), `${holidayName}の前`];
        })
      ),
    [holidays]
  );

  const dates = useMemo(() => {
    const daysInMonth = getDaysInMonth(month);
    let days = [...Array(daysInMonth)].map((_, i) =>
      add(startOfMonth(month), { days: 1 * i })
    );
    return days
      .filter((d) => isAfter(d, new Date()) || isSameDay(d, new Date()))
      .map((date) => formatDate(date)!);
  }, [month]);

  if (saving)
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress />
        <br />
        予約可能日時を公開中です。少々時間がかかる場合がございますので、完了までそのままお待ちください。
      </div>
    );

  return (
    <TableContainer sx={styles.tableContainer}>
      <MuiTable stickyHeader sx={styles.table}>
        <TableHead>
          <TableRow
            sx={{ position: "sticky", top: 0, zIndex: 15, background: "white" }}
          >
            <TableCell
              sx={[styles.cell, styles.leftTopCell]}
              rowSpan={2}
            ></TableCell>
            <TableCell sx={styles.cell}>一括売止</TableCell>
            {dates.map((date, index) => (
              <OpenCloseAll
                key={index}
                index={index}
                date={date}
                toggleDateForAll={toggleDateForAll}
                schedules={schedules}
              />
            ))}
          </TableRow>
          <TableRow sx={styles.stickyDayRow}>
            <TableCell sx={styles.cell}>日付</TableCell>
            {dates.map((date, index) => (
              <DateTableCell
                key={index}
                date={parseDate(date)}
                holidays={holidays}
                beforeHolidays={beforeHolidays}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {plans.map((plan, index) => {
            return (
              <Plan
                key={index}
                plan={plan}
                dates={dates}
                addTimeSlot={addTimeSlot}
                toggleDate={toggleDate}
                numBookings={numBookings[plan.planId]}
                numApproval={numApproval[plan.planId]}
                numApprovalPrevMonth={numApprovalPrevMonth[plan.planId]}
                numTotalBookings={numTotalBookings[plan.planId]}
                numTotalBookingsPrevMonth={
                  numTotalBookingsPrevMonth[plan.planId]
                }
                schedule={schedules[plan.planId]}
                setSchedules={setSchedules}
                toggleTimeSlot={toggleTimeSlot}
                toggleAllTimeSlots={toggleAllTimeSlots}
                toggleHoliday={toggleHoliday}
              />
            );
          })}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}
