import { Card, CardHeader, Grid, SxProps } from "@mui/material";
import { Reservation } from "../types";
import TotalsCard from "./TotalsCard";

export default function Totals(props: { reservations: Reservation[] }) {
  const style: SxProps = {
    subtitleCard: {
      border: 1,
      borderRadius: 0,
      textAlign: "center",
    },
    subtitleContent: {
      padding: "10px",
    },
    subHeading: {
      textAlign: "center",
      borderTop: 1,
      borderLeft: 1,
      borderRight: 1,
      backgroundColor: "#FFFFFF",
      whiteSpace: "pre-line",
    },
    valueCard: {
      border: 1,
      textAlign: "center",
      borderRadius: 0,
    },
    valueCardCornerLeft: {
      border: 1,
      textAlign: "center",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    valueCardCornerRight: {
      border: 1,
      textAlign: "center",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
  };

  const shouldCountReservation = (reservation: Reservation) =>
    reservation.status === "RESERVED" ||
    (reservation.status === "CANCELLED" && reservation.items.length > 0);

  let numBookingsWithItems = 0;

  const productCommissionRate: number = Math.floor(
    props.reservations.reduce((acc, reservation): number => {
      if (
        shouldCountReservation(reservation) &&
        reservation.costs.productOrderCommissionRate > 0
      ) {
        numBookingsWithItems++;
        return acc + reservation.costs.productOrderCommissionRate;
      } else {
        return acc;
      }
    }, 0)
  );

  const productOrderCommissionFees: number = Math.floor(
    props.reservations.reduce((acc, reservation): number => {
      if (
        shouldCountReservation(reservation) &&
        reservation.costs.productOrderCost > 0
      ) {
        return acc + reservation.costs.productOrderCommissionFee;
      } else {
        return acc;
      }
    }, 0)
  );

  const productOrdersTotal: number = props.reservations.reduce(
    (acc, reservation): number => {
      if (shouldCountReservation(reservation)) {
        return acc + reservation.costs.productOrderCost;
      } else {
        return acc;
      }
    },
    0
  );

  const onSiteTotal: number = props.reservations.reduce(
    (acc, reservation): number => {
      if (
        reservation.status === "RESERVED" &&
        reservation.booking.payment.method === "ON_SITE_PAYMENT" &&
        reservation.booking.noShow === false
      ) {
        return acc + reservation.costs.contractedAmount;
      } else {
        return acc;
      }
    },
    0
  );

  const onSiteCommissionFees: number = props.reservations.reduce(
    (acc, reservation): number => {
      // prefer using onSiteCommission,
      // but not all plans entered onSiteCommissionRate
      // and instead use commissionRate for both, on-site and online
      const { costs } = reservation;
      const commission = costs.onSiteCommission || costs.commission || 0;
      const commissionRate =
        costs.onSiteCommissionRate || costs.commissionRate || 0;

      if (reservation.booking.payment.method !== "ON_SITE_PAYMENT") return acc;
      else if (
        reservation.status === "RESERVED" &&
        reservation.booking.noShow === false
      ) {
        return acc + commission;
      } else if (reservation.status === "CANCELLED") {
        return (
          acc +
          Math.floor(reservation.costs.cancellationFee * (commissionRate / 100))
        );
      } else {
        return acc;
      }
    },
    0
  );

  const onSiteCancellationFees = props.reservations.reduce(
    (acc, reservation): number => {
      if (
        reservation.status === "CANCELLED" &&
        reservation.booking.payment.method === "ON_SITE_PAYMENT"
      )
        return acc + reservation.costs.cancellationFee;
      return acc;
    },
    0
  );

  const inAdvanceCommissionFees: number = props.reservations.reduce(
    (acc, reservation): number => {
      if (reservation.booking.payment.method === "ON_SITE_PAYMENT") return acc;
      if (reservation.status === "RESERVED") {
        // deduct item set commission from total
        return acc + reservation.costs.commission;
      } else if (reservation.status === "CANCELLED") {
        return (
          acc +
          Math.floor(
            reservation.costs.cancellationFee *
              (reservation.costs.commissionRate / 100)
          )
        );
      } else {
        return acc;
      }
    },
    0
  );

  const inAdvanceTotal: number = props.reservations.reduce(
    (acc, reservation): number => {
      if (
        reservation.status === "RESERVED" &&
        reservation.booking.payment.method !== "ON_SITE_PAYMENT"
      ) {
        // deduct item set costs from total
        return acc + reservation.costs.contractedAmount;
      } else {
        return acc;
      }
    },
    0
  );

  const inAdvanceCancellationFees: number = props.reservations.reduce(
    (acc, reservation): number => {
      if (reservation.booking.payment.method !== "ON_SITE_PAYMENT")
        return acc + reservation.costs.cancellationFee;
      return acc;
    },
    0
  );

  /*
    ご請求金額 if either of:
    onSite - inAdvance > 0
    no bookings at all
    inAdvance = 0
    onSite = inAdvance

    お支払通知金額 if either of:
    onSite - inAdvance < 0
    onSite = 0
  */
  //  will be used for the totals 1-2 table when it is ok to show to partners
  // let totalOutputName = "ご請求金額";
  // if (onSiteCommissionFees - inAdvanceCommissionFees > 0)
  //   totalOutputName = "お支払通知金額";

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={1}
      sx={{ my: "1.5rem" }}
    >
      <TotalsCard
        title={"①現地決済取扱⾦額"}
        total={onSiteTotal}
        commissionFee={onSiteCommissionFees}
        cancellationFee={onSiteCancellationFees}
      />

      <TotalsCard
        title={"②事前決済取扱⾦額"}
        total={inAdvanceTotal}
        commissionFee={inAdvanceCommissionFees}
        cancellationFee={inAdvanceCancellationFees}
      />

      {productOrdersTotal > 0 && (
        <Grid container item direction="column" xs={11} lg={4}>
          <Card
            sx={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <CardHeader title="お祝いアイテム決済取扱⾦額" />
          </Card>
          <Grid container item direction="row">
            <Grid item xs={4} xl={4}>
              <Card sx={style.subtitleCard}>
                <CardHeader
                  sx={style.subtitleContent}
                  title="⾦額"
                  titleTypographyProps={{ variant: "subtitle1" }}
                />
              </Card>
            </Grid>
            <Grid item xs={4} xl={4}>
              <Card sx={style.subtitleCard}>
                <CardHeader
                  sx={style.subtitleContent}
                  title="⼿数料率"
                  titleTypographyProps={{ variant: "subtitle1" }}
                />
              </Card>
            </Grid>
            <Grid item xs={4} xl={4}>
              <Card sx={style.subtitleCard}>
                <CardHeader
                  sx={style.subtitleContent}
                  title="手数料合計額"
                  titleTypographyProps={{ variant: "subtitle1" }}
                />
              </Card>
            </Grid>
          </Grid>
          <Grid container item direction="row" xs={12}>
            <Grid item xs={4}>
              <Card sx={style.valueCardCornerLeft}>
                <p style={{ height: "1.25rem" }}>
                  {new Intl.NumberFormat("ja-JP", {
                    style: "currency",
                    currency: "JPY",
                  }).format(productOrdersTotal)}
                </p>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={style.valueCard}>
                <p style={{ height: "1.25rem" }}>
                  {Math.floor(productCommissionRate / numBookingsWithItems)} %
                </p>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={style.valueCardCornerRight}>
                <p style={{ height: "1.25rem" }}>
                  {new Intl.NumberFormat("ja-JP", {
                    style: "currency",
                    currency: "JPY",
                  }).format(productOrderCommissionFees)}
                </p>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* on request of Masa, this section should not be shown for now. Probably after August 22 */}
      {/* <Grid container item direction="column" xs={10} sm={6} lg={3}>
        <Card
          sx={{
            border: 1,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            textAlign: "center",
          }}
        >
          <CardHeader
            sx={{ whiteSpace: "pre-line" }}
            title={`請求額相殺後の金額\n(①-②)`}
          />
        </Card>
        <Grid container item direction="row" xs={12}>
          <Grid item xs={7}>
            <Card sx={style.valueCardCornerLeft}>
              <p style={{ height: "1.25rem" }}>{totalOutputName}</p>
            </Card>
          </Grid>
          <Grid item xs={5}>
            <Card sx={style.valueCardCornerRight}>
              <p style={{ height: "1.25rem" }}>
                {new Intl.NumberFormat("ja-JP", {
                  style: "currency",
                  currency: "JPY",
                }).format(onSiteCommissionFees - inAdvanceCommissionFees)}
              </p>
            </Card>
          </Grid>
        </Grid>
      </Grid> */}
    </Grid>
  );
}
