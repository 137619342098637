import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";

import useUploadImage from "../hooks/useImageUploader";
import "./ImageUploaderCard.css";
import { useRef } from "react";

interface ImageUploaderCardProps {
  mediaUrl?: string;
  setMediaUrl: (mediaUrl: string | null) => void;
  mutable: boolean;
  path: string;
  imageProps?: { style?: { [key: string]: string } };
  isDeleted?: boolean;
  handleDelete?: () => void;
  undoDelete?: () => void;
  mediaType?: "photo" | "video";
  setMediaType?: (mediaType: "photo" | "video" | null) => void;
}

export default function ImageUploaderCard({
  mediaUrl,
  setMediaUrl,
  mutable,
  path,
  imageProps,
  isDeleted,
  handleDelete,
  undoDelete,
  mediaType = "photo",
  setMediaType = () => {},
}: ImageUploaderCardProps) {
  const { uploading, uploadImage } = useUploadImage();

  const inputRef = useRef<HTMLInputElement>(null);

  const Media = mediaType === "video" ? "video" : "img";

  return (
    <div
      className={
        "EditorImageUploader" +
        (mediaUrl ? "" : " NoImage" + (mutable ? " Mutable" : ""))
      }
    >
      <div
        className="ImageUploaderButtons"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
        }}
      >
        {uploading ? (
          <CircularProgress />
        ) : mediaUrl ? (
          // buttons when there is an image
          <>
            <Tooltip title="拡大写真を見る" disableInteractive>
              <IconButton
                size="large"
                className="EditorFaqImageEnlargeButton"
                href={mediaUrl}
                target="_blank"
              >
                <FitScreenIcon />
              </IconButton>
            </Tooltip>
            {mutable ? (
              isDeleted ? (
                <Tooltip title="削除取り消し">
                  <IconButton
                    size="large"
                    className="EditorFaqImageDeleteButton"
                    onClick={undoDelete}
                  >
                    <RestoreFromTrashIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="写真を削除する">
                  <IconButton
                    size="large"
                    className="EditorFaqImageDeleteButton"
                    onClick={() => {
                      (handleDelete ?? setMediaUrl)(null);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )
            ) : (
              <></>
            )}
          </>
        ) : // buttons when there is no image
        mutable ? (
          <>
            <input
              type="file"
              style={{ display: "none" }}
              ref={inputRef}
              onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                const result = await uploadImage(event.target.files![0], path);
                if (result) {
                  setMediaUrl(result.mediaUrl);
                  setMediaType(result.mediaType as "photo" | "video");
                }
              }}
            />

            <Tooltip title="写真をアップロードする">
              <IconButton
                className="EditorFaqImageAddButton"
                size="large"
                onClick={() => inputRef.current?.click()}
              >
                <AddAPhotoIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <Tooltip title="No image">
            <NoPhotographyIcon className="EditorFaqImutableIcon" />
          </Tooltip>
        )}
      </div>

      {mediaUrl && (
        <Media
          className="EditorFaqImage"
          src={mediaUrl}
          style={{ ...(imageProps?.style ?? {}) }}
        />
      )}
    </div>
  );
}

ImageUploaderCard.defaultProps = {
  mutable: true,
};
