import { useMemo, useEffect } from "react";
import Chart from "react-apexcharts";

interface AcceptanceRateSpeedometerProps {
  acceptanceRate: number | undefined;
  minAcceptanceRate: number;
  numerator: number | undefined;
  denominator: number | undefined;
  chartName: string | undefined;
}
export default function AcceptanceRateSpeedometer({
  acceptanceRate,
  minAcceptanceRate,
  numerator,
  denominator,
  chartName
}: AcceptanceRateSpeedometerProps) {
  const state = useMemo(
    () => ({
      options: {
        states: {
          active: {
            filter: {
              type: "none",
            },
          },
        },
        labels: ["予約承認率"],
        stroke: {
          lineCap: "round" as "round",
        },

        chart: {
          // this id is needed, or the label formatter will not update
          id: `basic-radial-bar-${acceptanceRate} basic-radial-bar-${chartName}`,
          offsetY: -20,
        },

        plotOptions: {
          stroke: {
            lineCap: "round",
          },

          radialBar: {
            hollow: {
              size: "55%",
            },
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              show: true,
              name: {
                offsetY: 28,
                fontSize: "1rem",
              },
              value: {
                offsetY: -10,
                fontSize: "4rem",
                formatter: () => {
                  return acceptanceRate === undefined
                    ? ""
                    : `${acceptanceRate}%`;
                },
              },
            },
          },
        },

        colors: [
          () => {
            // no acceptance rate > grey
            if (acceptanceRate === undefined) return "#f2f2f2";
            // bad acceptance rate > red
            if (acceptanceRate < minAcceptanceRate) return "#eb4034";
            // good acceptance rate > green
            return "#1db557";
          },
        ],
      },
      series: [Math.pow((acceptanceRate ?? 0), 1)],
    }),
    [acceptanceRate, minAcceptanceRate, numerator, denominator]
  );

  useEffect(() => {
    const chartId = `basic-radial-bar-${chartName}`;
    const chartElement = document.querySelector(
      `div[id*=${chartId}] .apexcharts-text`
    );

    if (chartElement) {
      while (chartElement.firstChild) {
        chartElement.removeChild(chartElement.firstChild);
      }

      const line1 = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
      line1.textContent = "予約承認率";
      line1.setAttribute("dy", "1.2em");

      const line2 = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
      let num = (denominator ?? 0) - (numerator ?? 0)
      line2.textContent = `(${num}/${denominator})`;
      line2.setAttribute("x", "13.6rem");
      line2.setAttribute("dy", "1.2em");

      chartElement.appendChild(line1);
      chartElement.appendChild(line2);
    }
  }, [acceptanceRate, numerator, denominator]);

  return (
    <Chart options={state.options} series={state.series} type="radialBar" />
  );
}
