export default function useRateBooking(bookingAvailability: any, bookingTotal: any) {
    const numBookingAvailability = calculateTotal(bookingAvailability);
    const numBookingTotal = calculateTotal(bookingTotal);

    if (numBookingAvailability === 0 && numBookingTotal === 0) {
        return 0;
    }
    const percentage = (numBookingAvailability / numBookingTotal) * 100;
    return parseFloat(percentage.toFixed(2));
}

function calculateTotal(data: Record<string, number>): number {
    return Object.values(data).reduce((total, value) => total + value, 0);
}
