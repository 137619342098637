import { Button, Menu, MenuItem, Skeleton } from "@mui/material";
import { useCallback, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { VenueWithPlans } from "../index";

import "./ScopeSelector.css";

export const allVenuesLabel = "全店舗";

interface ScopeSelectorProps {
  loading: boolean;
  label: string;
  scopeId: string | null;
  setScopeId: (id: string | null) => void;
  plansPerVenue: Map<string, VenueWithPlans>;
}

export default function ScopeSelector({
  loading,
  label,
  plansPerVenue,
  scopeId,
  setScopeId,
}: ScopeSelectorProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(
    (id: string | null) => {
      setOpen(false);
      setScopeId(id);
    },
    [setScopeId]
  );

  return (
    <div className="DashboardScopeSelector">
      {(loading && (
        <Skeleton
          className="DashboardScopeSelectorSkeleton"
          variant="rectangular"
        />
      )) || (
        <>
          <Button
            className="DashboardScopeSelectorButton"
            variant="contained"
            onClick={handleClick}
          >
            <div className="DashboardScopeSelectorItem">{label}</div>
            <KeyboardArrowDownIcon />
          </Button>
          <Menu
            PaperProps={{
              style: {
                maxHeight: 72 * 4.5,
                width: anchorEl?.clientWidth ?? 0,
              },
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose(scopeId)}
          >
            <ul>
              {plansPerVenue.size > 1 && (
                <MenuItem onClick={() => handleClose(null)}>
                  {allVenuesLabel}
                </MenuItem>
              )}
              {[...plansPerVenue].map(([venueId, { venueName, plans }]) => {
                return (
                  <div key={venueId}>
                    <MenuItem
                      className="DashboardScopeSelectorVenueItem"
                      onClick={() => handleClose(venueId)}
                    >
                      {venueName}
                    </MenuItem>
                    {plans.map(({ planId, planName }) => {
                      return (
                        <MenuItem
                          className="DashboardScopeSelectorPlanItem"
                          key={planId}
                          onClick={() => handleClose(planId)}
                        >
                          {`‣ ${planName}`}
                        </MenuItem>
                      );
                    })}
                  </div>
                );
              })}
            </ul>
          </Menu>
        </>
      )}
    </div>
  );
}
