import { IconButton } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import FilterListIcon from "@mui/icons-material/FilterList";
import ReviewStars from "../../../components/ReviewStars";
import { ImagesCell, LinkCell, ReviewCell } from "./renderCell";
import { Field, IdAndName } from "./types";
import React from "react";
import "./index.css";

const getPlanUrl = (planId: string) => {
  return `https://oiwai.anny.gift/celebration-plans/${planId}`;
};

const getVenueUrl = (venueId: string) => {
  return `https://oiwai.anny.gift/venues/${venueId}`;
};

const getBookingUrl = (bookingId: string) => {
  return `/bookings/${bookingId}`;
};

interface ColDefParameters {
  field: Field;
  width: number;
  renderCell?: (_: GridRenderCellParams) => JSX.Element;
  headerName?: string;
  filter?: React.Key | null;
}

const getColDef = ({
  field,
  width,
  renderCell,
  headerName,
  filter,
}: ColDefParameters) => {
  return {
    field: field,
    renderCell: renderCell,
    renderHeader: () => {
      return (
        <div className="column-header">
          {headerName ?? field}
          {!!filter && <FilterListIcon />}
        </div>
      );
    },
    width: width,
    sortable: false,
    disableColumnMenu: filter === undefined,
  };
};

const getColumnDefs = (
  planFilter: React.Key | null,
  venueFilter: React.Key | null,
  ratingFilter: React.Key | null
): GridColDef[] => {
  return [
    getColDef({
      field: "plan",
      headerName: "プラン名",
      width: 200,
      filter: planFilter,
      renderCell: (params: GridRenderCellParams<IdAndName>) =>
        LinkCell(params, getPlanUrl),
    }),
    getColDef({
      field: "venue",
      headerName: "店舗名",
      width: 200,
      filter: venueFilter,
      renderCell: (params: GridRenderCellParams<IdAndName>) =>
        LinkCell(params, getVenueUrl),
    }),
    getColDef({
      field: "booking",
      headerName: "予約ID",
      width: 140,
      renderCell: (params: GridRenderCellParams<IdAndName>) =>
        LinkCell(params, getBookingUrl),
    }),
    getColDef({
      field: "rating",
      headerName: "評価",
      width: 140,
      filter: ratingFilter,
      renderCell: (params: GridRenderCellParams) => (
        <ReviewStars rating={params.value} />
      ),
    }),
    getColDef({
      field: "content",
      headerName: "本文",
      width: 330,
      renderCell: (params: GridRenderCellParams) => (
        <ReviewCell content={params.value} />
      ),
    }),
    getColDef({
      field: "reviewAt",
      headerName: "投稿日",
      width: 106,
    }),
    getColDef({
      field: "imageUrls",
      headerName: "画像",
      width: 80,
      renderCell: (params: GridRenderCellParams<string[]>) => {
        const hasImages = params.value.length > 0;

        if (hasImages) {
          return <ImagesCell images={params.value} />;
        }

        return (
          <IconButton color="primary" disabled={true}>
            <PhotoCameraIcon />
          </IconButton>
        );
      },
    }),
  ];
};

export default getColumnDefs;
