import { TableCell } from "@mui/material";
import styles from "../../styles";
import { CrossCheckbox } from "./Plan/components/atoms/CheckboxIcon";
import {
  isCutoffForAllPlans,
  isDateClosedForAllPlans,
  Schedules,
  UseSchedules,
} from "../useSchedules";
import { useCallback, useMemo } from "react";

export default function OpenCloseAll({
  index,
  schedules,
  date,
  toggleDateForAll,
}: {
  schedules: Schedules;
  index: number;
  date: string;
} & Pick<UseSchedules, "toggleDateForAll">) {
  const isCutoff = useMemo(() => isCutoffForAllPlans(schedules, date), [
    schedules,
    date,
  ]);

  const closedDate = useMemo(() => isDateClosedForAllPlans(schedules, date), [
    schedules,
    date,
  ]);

  const style = useMemo(
    () => ({
      ...styles.cell,
      ...(isCutoff ? styles.disabledCell : {}),
    }),
    [isCutoff]
  );

  const toggleDate = useCallback(() => toggleDateForAll(schedules, date), [
    toggleDateForAll,
    schedules,
    date,
  ]);

  return (
    <TableCell key={index} sx={style}>
      <CrossCheckbox
        disabled={isCutoff}
        checked={closedDate}
        onClick={toggleDate}
      />
    </TableCell>
  );
}
