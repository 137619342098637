import { Box, Tabs as MuiTabs, Tab } from "@mui/material";
import { useState } from "react";

interface TabsProps {
  tabs: {
    title: string;
    content: JSX.Element;
  }[];
  selectedTab?: number;
}

export default function Tabs(props: TabsProps) {
  const [selectedTab, setSelectedTab] = useState(props.selectedTab || 0);

  return (
    <>
      <Box sx={{ marginBottom: "10px" }}>
        <MuiTabs
          sx={{ "& .Mui-selected": { color: "#000 !important" } }}
          TabIndicatorProps={{
            style: { backgroundColor: "#c8a063" },
          }}
          value={selectedTab}
          onChange={(_event: any, newValue: number) => setSelectedTab(newValue)}
        >
          {props.tabs.map((tab) => (
            <Tab key={tab.title} label={tab.title} />
          ))}
        </MuiTabs>
      </Box>
      <div>{props.tabs[selectedTab]?.content}</div>
    </>
  );
}
