import { gql, useMutation } from "@apollo/client";
import { useState, useCallback, useMemo, useEffect } from "react";
import { useNotifications } from "../../../../../components/Notification";
import { Holiday, isHolidayType } from "../helpers";

export type UseSaveScheduleProps = {
  venueId: string;
};
export default function useSaveSchedules({ venueId }: UseSaveScheduleProps) {
  const { showNotification } = useNotifications();

  const [save, { loading: saving, error: saveError }] = useMutation<
    SaveAvailabilityResponse,
    SaveAvailabilityInput
  >(SAVE_AVAILABILITY);

  const [changes, setChanges] = useState<{
    [planId: string]: {
      timeSlots: { [date: string]: { [timeSlot: string]: boolean } };
      dates: { [date: string]: boolean };
      holidays: { [holiday: string]: boolean };
    };
  }>({});

  useEffect(() => {
    // reset changes when changing venues
    setChanges({});
  }, [venueId]);

  const canSave = useMemo(
    () =>
      Object.values(changes).some(
        ({ dates, timeSlots, holidays }) =>
          !!Object.keys(dates).length ||
          !!Object.keys(holidays).length ||
          Object.values(timeSlots).some((dates) => !!Object.keys(dates).length)
      ),
    [changes]
  );

  const handleSave = useCallback(async () => {
    const variables = Object.entries(changes).reduce(
      (acc, [planId, change]) => {
        const getDates = (opening: boolean) =>
          Object.entries(change.dates)
            .filter(([_, toOpen]) => toOpen === opening)
            .map(([date, _]) => date);

        const getTimeSlots = (opening: boolean) =>
          Object.entries(change.timeSlots).flatMap(([date, timeSlots]) =>
            Object.entries(timeSlots)
              .filter(([_, toOpen]) => toOpen === opening)
              .map(([timeSlot, _]) => `${date} ${timeSlot}`)
          );

        const getHolidays = (opening: boolean) =>
          Object.entries(change.holidays)
            .filter(([_, toOpen]) => toOpen === opening)
            .map(([date, _]) => date);

        const newDatesToOpen = getDates(true);
        const newDatesToClose = getDates(false);
        const newTimeSlotsToOpen = getTimeSlots(true);
        const newTimeSlotsToClose = getTimeSlots(false);
        const newHolidaysToOpen = getHolidays(true);
        const newHolidaysToClose = getHolidays(false);

        return {
          datesToOpen: newDatesToOpen.length
            ? [
                ...(acc.datesToOpen ?? []),
                {
                  planId,
                  dates: newDatesToOpen,
                },
              ]
            : acc.datesToOpen,
          datesToClose: newDatesToClose.length
            ? [
                ...(acc.datesToClose ?? []),
                {
                  planId,
                  dates: newDatesToClose,
                },
              ]
            : acc.datesToClose,
          timeSlotsToOpen: newTimeSlotsToOpen.length
            ? [
                ...(acc.timeSlotsToOpen ?? []),
                {
                  planId,
                  dates: newTimeSlotsToOpen,
                },
              ]
            : acc.timeSlotsToOpen,
          timeSlotsToClose: newTimeSlotsToClose.length
            ? [
                ...(acc.timeSlotsToClose ?? []),
                {
                  planId,
                  dates: newTimeSlotsToClose,
                },
              ]
            : acc.timeSlotsToClose,
          holidaysToOpen: newHolidaysToOpen.length
            ? [
                ...(acc.holidaysToOpen ?? []),
                {
                  planId,
                  dates: newHolidaysToOpen,
                },
              ]
            : acc.holidaysToOpen,
          holidaysToClose: newHolidaysToClose.length
            ? [
                ...(acc.holidaysToClose ?? []),
                {
                  planId,
                  dates: newHolidaysToClose,
                },
              ]
            : acc.holidaysToClose,
        };
      },
      {} as Omit<SaveAvailabilityInput, "venueId">
    );

    try {
      await save({
        variables: { venueId, ...variables },
      });
      setChanges({});
      showNotification({
        severity: "success",
        message: "saved schedule",
      });
    } catch (error) {
      showNotification({
        severity: "error",
        message: (error as Error).message,
      });
    }
  }, [venueId, save, changes, showNotification]);

  const toggleChange = useCallback(
    (
      planId: string,
      date: string | Holiday,
      toOpen: boolean,
      timeSlot?: string
    ) => {
      setChanges((changes) => {
        if (!changes.hasOwnProperty(planId))
          changes[planId] = {
            dates: {},
            timeSlots: {},
            holidays: {},
          };

        if (timeSlot && !changes[planId].timeSlots.hasOwnProperty(date))
          changes[planId].timeSlots[date] = {};

        const obj = timeSlot
          ? changes[planId].timeSlots[date]
          : isHolidayType(date)
          ? changes[planId].holidays
          : changes[planId].dates;

        const toggleValue = timeSlot ?? date;

        obj.hasOwnProperty(toggleValue)
          ? delete obj[toggleValue]
          : (obj[toggleValue] = toOpen);

        return { ...changes };
      });
    },
    [setChanges]
  );

  return {
    toggleChange,
    canSave,
    save: handleSave,
    saving,
    saveError,
  };
}

type PlanDate = {
  planId: string;
  dates: string[];
};
type SaveAvailabilityInput = {
  venueId: string;
  datesToOpen?: PlanDate[];
  datesToClose?: PlanDate[];
  timeSlotsToOpen?: PlanDate[];
  timeSlotsToClose?: PlanDate[];
  holidaysToOpen?: PlanDate[];
  holidaysToClose?: PlanDate[];
};
type SaveAvailabilityResponse = {
  saveVenueAvailability: {
    success: boolean;
  };
};
const SAVE_AVAILABILITY = gql`
  mutation SaveVenueAvailability(
    $venueId: ID!
    $timeSlotsToOpen: [PlanDates!]
    $timeSlotsToClose: [PlanDates!]
    $datesToOpen: [PlanDates!]
    $datesToClose: [PlanDates!]
    $holidaysToOpen: [PlanDates!]
    $holidaysToClose: [PlanDates!]
  ) {
    saveVenueAvailability(
      input: {
        venueId: $venueId
        datesToOpen: $datesToOpen
        datesToClose: $datesToClose
        timeSlotsToOpen: $timeSlotsToOpen
        timeSlotsToClose: $timeSlotsToClose
        holidaysToOpen: $holidaysToOpen
        holidaysToClose: $holidaysToClose
      }
    ) {
      success
    }
  }
`;
