import { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function UserMenu() {
  const [menuAchor, setMenuAnchor] = useState<Element | null>(null);

  return (
    <>
      <IconButton
        style={{ color: "#ffffff" }}
        onClick={(e: { currentTarget: Element }) =>
          setMenuAnchor(e.currentTarget)
        }
      >
        <AccountCircleIcon />
      </IconButton>
      <Menu
        open={Boolean(menuAchor)}
        anchorEl={menuAchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setMenuAnchor(null)}
      >
        <MenuItem
          onClick={() => {
            localStorage.removeItem("user");
            setMenuAnchor(null);
            window.location.reload();
          }}
        >
          ログアウト
        </MenuItem>
      </Menu>
    </>
  );
}
