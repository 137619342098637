import type { PropsWithChildren } from "react";
import { DragOverlay, defaultDropAnimationSideEffects } from "@dnd-kit/core";
import type { DropAnimation } from "@dnd-kit/core";
import "./SortablePlanList.css";
import { Table, TableBody } from "@mui/material";

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.2",
      },
    },
  }),
};

interface Props {}

export function SortableOverlay({ children }: PropsWithChildren<Props>) {
  return (
    <DragOverlay className={"ActiveRow"} dropAnimation={dropAnimationConfig}>
      <Table className="DndTable" size="small">
        <TableBody>{children}</TableBody>
      </Table>
    </DragOverlay>
  );
}
