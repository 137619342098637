export function groupDatesByWeek(dates: Date[]): Map<string, number> {
  return dates.reduce((acc, date) => {
    const startOfWeek = getFormattedStartOfWeek(date);
    acc.set(startOfWeek, (acc.get(startOfWeek) ?? 0) + 1);
    return acc;
  }, new Map<string, number>());
}

export function getStartOfDay(date: Date) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0
  );
}

export function getStartOfWeek(date: Date) {
  const dayOfWeek = date.getDay();
  const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
  return getStartOfDay(new Date(date.setDate(diff)));
}

export function getFormattedStartOfWeek(date: Date): string {
  const startOfWeek = getStartOfWeek(date);
  return `${startOfWeek.getMonth() + 1}月${startOfWeek.getDate() + 1}日`;
}

export const getPastWeeksDates = (numWeeks: number): Date[] =>
  [...Array(numWeeks)]
    .map((_, i) => new Date(Date.now() - i * 7 * 24 * 60 * 60 * 1000))
    .reverse();

export function formatDate(date: Date): string {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0];
}
