import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import { isProductionEnvironment } from "./helpers/environment";
// force rebuild
if (isProductionEnvironment) {
  Sentry.init({
    dsn:
      "https://0cd037da4e1b45fc803c10ec064ae04f@o1047916.ingest.sentry.io/6388315",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));
