import { useQuery, gql } from "@apollo/client";
import { DataGrid, GridRowsProp } from "@mui/x-data-grid";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useState } from "react";
import getColumnDefs from "./columnDefs";
import getColumnMenu from "./columnMenu";
import { IdAndName } from "./types";
import "./index.css";

const PAGE_SIZE = 10;

export default function ReviewsList() {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [venueFilter, setVenueFilter] = useState<string | null>(null);
  const [planFilter, setPlanFilter] = useState<string | null>(null);
  const [ratingFilter, setRatingFilter] = useState<number | null>(null);

  const { data, loading, error } = useQuery(GET_REVIEWS, {
    variables: {
      offset: paginationModel.page * paginationModel.pageSize,
      limit: PAGE_SIZE,
      venueIdFilter: venueFilter,
      planIdFilter: planFilter,
      ratingFilter: ratingFilter,
    },
  });

  if (error) return <>Error! {error.message}</>;

  const queryResult: QueryResult = data ? data.reviews : null;
  const rows: GridRowsProp =
    queryResult && queryResult.records
      ? queryResult.records.map((record) => {
          return {
            ...record,
            plan: { id: record.planId, name: record.planName },
            venue: { id: record.venueId, name: record.venueName },
            booking: { id: record.reservationId, name: record.bookingId },
          };
        })
      : [];
  const totalRecords: number = queryResult ? queryResult.totalRecords : 0;
  const venues: IdAndName[] = data ? data.venues : [];
  const plans: IdAndName[] = data ? data.plans : [];

  return (
    <div className="review-list">
      <DataGrid
        className="review-list-datagrid"
        components={{
          ColumnMenuIcon: () => <FilterAltIcon fontSize="small" />,
          ColumnMenu: getColumnMenu(
            plans,
            venues,
            planFilter,
            venueFilter,
            ratingFilter,
            setPlanFilter,
            setVenueFilter,
            setRatingFilter
          ),
        }}
        rows={rows}
        columns={getColumnDefs(planFilter, venueFilter, ratingFilter)}
        rowCount={totalRecords}
        autoHeight={true}
        paginationMode="server"
        pageSizeOptions={[paginationModel.pageSize]}
        loading={loading}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        disableRowSelectionOnClick={true}
      />
    </div>
  );
}

const GET_REVIEWS = gql`
  query Reviews(
    $offset: Int!
    $limit: Int!
    $planIdFilter: String
    $venueIdFilter: String
    $ratingFilter: Float
  ) {
    reviews(
      input: {
        offset: $offset
        limit: $limit
        planIdFilter: $planIdFilter
        venueIdFilter: $venueIdFilter
        ratingFilter: $ratingFilter
      }
    ) {
      totalRecords
      records {
        id
        rating
        content
        imageUrls
        reviewAt
        bookingId
        reservationId
        planId
        planName
        venueId
        venueName
      }
    }
    venues {
      id
      name
    }
    plans {
      id
      name
    }
  }
`;

interface QueryResult {
  totalRecords: number;
  records: {
    id: number;
    rating: number;
    content: string;
    image_urls: string[];
    reviewAt: Date;
    bookingId: string;
    reservationId: string;
    planId: string;
    planName: string;
    venueId: string;
    venueName: string;
  }[];
}
