import {
  Grid,
  Card,
  CardHeader,
  SxProps,
  Typography,
  CardHeaderProps,
} from "@mui/material";

type TotalsCardProps = Pick<CardHeaderProps, "title"> & {
  total: number;
  commissionFee: number;
  cancellationFee: number;
};

export default function TotalsCard({
  title,
  total,
  commissionFee,
  cancellationFee,
}: TotalsCardProps) {
  const style: SxProps = {
    subtitleCard: {
      border: 1,
      borderRadius: 0,
      textAlign: "center",
    },
    subtitleContent: {
      padding: "10px",
    },
    subHeading: {
      textAlign: "center",
      borderTop: 1,
      borderLeft: 1,
      borderRight: 1,
      backgroundColor: "#FFFFFF",
      whiteSpace: "pre-line",
    },
    valueCard: {
      border: 1,
      textAlign: "center",
      borderRadius: 0,
    },
    valueCardCornerLeft: {
      border: 1,
      textAlign: "center",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    valueCardCornerRight: {
      border: 1,
      textAlign: "center",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
  };

  return (
    <Grid item container direction="column" xs={12} lg={6}>
      <Card
        sx={{
          textAlign: "center",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <CardHeader title={title} />
      </Card>

      <Grid container direction="row">
        <Grid container item direction="column" sm={9}>
          <Typography
            sx={{
              ...style.subHeading,
              height: "3rem",
              paddingTop: "1rem",
            }}
            variant="h6"
          >
            プラン予約
          </Typography>
          <Grid container item direction="row">
            <Grid container item direction="column" sm={6}>
              <Grid item>
                <Card sx={style.subtitleCard}>
                  <CardHeader
                    sx={style.subtitleContent}
                    title="⾦額"
                    titleTypographyProps={{ variant: "subtitle1" }}
                  />
                </Card>
              </Grid>
              <Grid item>
                <Card sx={style.valueCardCornerLeft}>
                  <p style={{ height: "1.125rem" }}>
                    {new Intl.NumberFormat("ja-JP", {
                      style: "currency",
                      currency: "JPY",
                    }).format(total)}
                  </p>
                </Card>
              </Grid>
            </Grid>
            <Grid container item direction="column" sm={6}>
              <Grid item>
                <Card sx={style.subtitleCard}>
                  <CardHeader
                    sx={style.subtitleContent}
                    title="ご請求額（弊社手数料）"
                    titleTypographyProps={{ variant: "subtitle1" }}
                  />
                </Card>
              </Grid>
              <Grid item>
                <Card sx={style.valueCard}>
                  <p style={{ height: "1.125rem" }}>
                    {new Intl.NumberFormat("ja-JP", {
                      style: "currency",
                      currency: "JPY",
                    }).format(commissionFee)}
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item direction="column" xs={6} sm={3}>
          <Typography sx={style.subHeading} variant="h6">
            {`キャンセル\n(請求対象分)`}
          </Typography>
          <Grid container item direction="row">
            <Grid container item direction="column" sm={12}>
              <Grid item>
                <Card sx={style.subtitleCard}>
                  <CardHeader
                    sx={style.subtitleContent}
                    title="⾦額"
                    titleTypographyProps={{ variant: "subtitle1" }}
                  />
                </Card>
              </Grid>
              <Grid item>
                <Card sx={style.valueCardCornerRight}>
                  <p style={{ height: "1.125rem" }}>
                    {new Intl.NumberFormat("ja-JP", {
                      style: "currency",
                      currency: "JPY",
                    }).format(cancellationFee)}
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
