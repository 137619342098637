import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import InfoIcon from "@mui/icons-material/Info";
import { Stack, IconButton, SxProps, Tooltip } from "@mui/material";
import { useCallback } from "react";
import { OmitFirstTwoArg } from "../../../../../types";
import { UseSchedules } from "../../../../../useSchedules";

type TimeSlotProps = {
  timeSlot: string;
  open: boolean;
  isCutoff: boolean;
  toggleTimeSlot: OmitFirstTwoArg<UseSchedules["toggleTimeSlot"]>;
};
export default function TimeSlot({
  timeSlot,
  open,
  isCutoff,
  toggleTimeSlot,
}: TimeSlotProps) {
  const toggleTimeSlotForTimeSlot = useCallback(
    () => toggleTimeSlot(timeSlot),
    [timeSlot, toggleTimeSlot]
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <span style={{ fontSize: "20px" }}>{timeSlot}</span>
      <IconButton
        disabled={isCutoff}
        sx={open ? sx.timeSlotAvailable : sx.timeSlotUnavailable}
        size="small"
        onClick={toggleTimeSlotForTimeSlot}
      >
        {open ? <CheckIcon /> : <ClearIcon />}
      </IconButton>
      {isCutoff && (
        <Tooltip title="受付締切期日が過ぎているためで売止されています">
          <InfoIcon />
        </Tooltip>
      )}
    </Stack>
  );
}

const sx: { [id: string]: SxProps } = {
  timeSlotAvailable: {
    border: "1px solid green",
    borderRadius: "5px",
    color: "green",
  },
  timeSlotUnavailable: {
    border: "1px solid red",
    borderRadius: "5px",
    color: "red",
  },
};
