import WarningIcon from "@mui/icons-material/Warning";
import { Tooltip } from "@mui/material";
import "./AcceptanceRateField.css";

const minAcceptanceRate = 95;
const minBookings = 8;

export default function AcceptanceRateField({
  planId,
  bookings,
  rejections,
  acceptanceRate,
}: RejectionRateFieldProps) {
  return (
    <a
      className="PlanDashboardLink"
      href={`/reports/dashboard?id=${planId}`}
      target="_blank"
      rel="noreferrer"
    >
      <Tooltip
        placement="top-start"
        arrow
        followCursor
        title={`${bookings - rejections}/${bookings}`}
      >
        <div
          className={
            "AcceptanceRateField" +
            ((acceptanceRate ?? minAcceptanceRate) < minAcceptanceRate
              ? " LowAcceptanceRate"
              : "") +
            (bookings >= minBookings ? " HasMinBookings" : "")
          }
        >
          <>
            {bookings >= minBookings &&
              (acceptanceRate ?? minAcceptanceRate) < minAcceptanceRate && (
                <WarningIcon />
              )}
            <div>
              {(acceptanceRate !== undefined && acceptanceRate + "%") || "なし"}
            </div>
          </>
        </div>
      </Tooltip>
    </a>
  );
}

interface RejectionRateFieldProps {
  planId: string;
  bookings: number;
  rejections: number;
  acceptanceRate: number | undefined;
}
