import { add, isAfter, isBefore, subDays } from "date-fns";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { AvailabilityStatus } from "../../../../../types";
import {
  setDifference,
  getDay,
  parseDate,
  setUnion,
  parseTimeSlot,
  formatDate,
  Holiday,
  isHoliday,
  holidayDayDifference,
  isPreHoliday,
} from "../helpers";
import useFetchSchedules, {
  getBlankDateSchedule,
  isBlankDateSchedule,
  PlanSchedule,
  UseFetchSchedulesProps,
  VenueSchedule,
} from "./useFetchSchedule";
import useSaveSchedules from "./useSaveSchedule";

export const getAvailableTimeSlots = (schedule: PlanSchedule, date: string) =>
  setDifference(
    setUnion(
      getDefaultTimeSlots(schedule, date),
      getOpenTimeSlots(schedule, date)
    ),
    getClosedTimeSlots(schedule, date)
  );

export const getOpenTimeSlots = (schedule: PlanSchedule, date: string) => {
  if (!schedule.dates.hasOwnProperty(date))
    schedule.dates[date] = getBlankDateSchedule();
  return schedule.dates[date].openTimeSlots;
};

export const getClosedTimeSlots = (schedule: PlanSchedule, date: string) => {
  if (!schedule.dates.hasOwnProperty(date))
    schedule.dates[date] = getBlankDateSchedule();
  return schedule.dates[date].closedTimeSlots;
};

const getTimeSlots = (schedule: PlanSchedule, dateKey: string | number) => {
  if (!schedule.defaultTimeSlots[dateKey])
    schedule.defaultTimeSlots[dateKey] = new Set();
  return schedule.defaultTimeSlots[dateKey];
};

export const getDefaultTimeSlots = (schedule: PlanSchedule, date: string) => {
  return (
    [
      isPreHoliday(date) ? getTimeSlots(schedule, "祝前日") : null,
      isHoliday(date) ? getTimeSlots(schedule, "祝日") : null,
      getTimeSlots(schedule, getDay(date)),
    ].find((timeSlots) => !!timeSlots?.size) ?? new Set<string>()
  );
};

export const isCutoff = (
  schedule: PlanSchedule,
  date: string,
  timeSlot?: string
) => {
  const cutoffTime = add(new Date(), {
    hours: schedule.cutoffTimeHours,
  });
  const maxDateTime = add(new Date(), {
    months: 3 * 12, //bookablePeriod 3 year
    hours: 23,
    minutes: 59,
  });

  const parsedDate = add(parseDate(date), parseTimeSlot(timeSlot ?? "23:59"));

  return isBefore(parsedDate, cutoffTime) || isAfter(parsedDate, maxDateTime);
};

export const isCutoffForAllPlans = (schedules: Schedules, date: string) =>
  Object.values(schedules).every((schedule) => isCutoff(schedule, date));

export const isLocked = (
  schedule: PlanSchedule,
  date: string,
  timeSlot?: string
) => (schedule.dates[date]?.state ?? "default") === "locked";

// export const lockedDates = (schedule: PlanSchedule2) =>
//   Object.entries(schedule.closedDates)
//     .filter(([_, locked]) => locked)
//     .map(([date, _]) => date);

export const canToggleValue = (
  schedule: PlanSchedule,
  date: string,
  timeSlot?: string
) =>
  !(isCutoff(schedule, date, timeSlot) || isLocked(schedule, date, timeSlot));

export const isExistingTimeSlot = (
  schedule: PlanSchedule,
  date: string,
  timeSlot: string
) =>
  getClosedTimeSlots(schedule, date).has(timeSlot) ||
  getAvailableTimeSlots(schedule, date).has(timeSlot);

export const allTimeSlotsAreClosed = (schedule: PlanSchedule, date: string) =>
  !!getClosedTimeSlots(schedule, date).size &&
  !getAvailableTimeSlots(schedule, date).size;

export const isClosedTimeSlot = (
  schedule: PlanSchedule,
  date: string,
  timeSlot: string
) => getClosedTimeSlots(schedule, date).has(timeSlot);

export const isDefaultTimeSlot = (
  schedule: PlanSchedule,
  date: string,
  timeSlot: string
) => getDefaultTimeSlots(schedule, date).has(timeSlot);

export const isClosedDueToHoliday = (schedule: PlanSchedule, date: string) =>
  !![...schedule.closedHolidays].find((holiday) =>
    isHoliday(
      formatDate(subDays(parseDate(date), holidayDayDifference[holiday]))!
    )
  );

export const isClosed = (schedule: PlanSchedule, date: string) => {
  return (
    (schedule.dates[date]?.state ?? "default") === "closed" ||
    isClosedDueToHoliday(schedule, date)
  );
};

export const isClosedHoliday = (schedule: PlanSchedule, holiday: Holiday) =>
  schedule.closedHolidays.has(holiday);

export const isDateClosedForAllPlans = (schedules: Schedules, date: string) =>
  Object.values(schedules).every(
    (schedule) => isClosed(schedule, date) || !canToggleValue(schedule, date)
  );

export type Schedules = {
  [planId: string]: PlanSchedule;
};

export const getStatus = (
  schedule: PlanSchedule,
  date: string
): AvailabilityStatus =>
  isClosed(schedule, date)
    ? "NOT_AVAILABLE"
    : !getAvailableTimeSlots(schedule, date).size
      ? "NOT_AVAILABLE"
      : !!getClosedTimeSlots(schedule, date).size
        ? "PARTIALLY_AVAILABLE"
        : "AVAILABLE";

export const hasTimeSlots = (schedule: PlanSchedule, date: string) =>
  !!(
    getDefaultTimeSlots(schedule, date).size +
    getOpenTimeSlots(schedule, date).size +
    getClosedTimeSlots(schedule, date).size
  );

export default function useSchedules(props: UseFetchSchedulesProps) {
  const {
    plans,
    numBookings,
    numApproval,
    numApprovalPrevMonth,
    numTotalBookings,
    numTotalBookingsPrevMonth,
    schedules: fetchedSchedules,
    loading,
    loadError,
  } = useFetchSchedules(props);

  const [schedules, setSchedules] = useState<VenueSchedule>(fetchedSchedules);

  const updateSchedules = useCallback(
    (arg: SetStateAction<VenueSchedule>) => {
      setSchedules((prevState) => {
        const nextState = typeof arg === "function" ? arg(prevState) : arg;
        return { venueId: nextState.venueId, plans: { ...nextState.plans } };
      });
    },
    [setSchedules]
  );

  useEffect(() => updateSchedules(fetchedSchedules), [
    updateSchedules,
    fetchedSchedules,
  ]);

  const { save, saving, canSave, toggleChange, saveError } = useSaveSchedules(
    props
  );

  const _openTimeSlot = useCallback(
    (schedule: PlanSchedule, date: string, timeSlot: string) => {
      if (
        schedule.dates[date].openTimeSlots.has(timeSlot) ||
        !canToggleValue(schedule, date, timeSlot)
      )
        return;

      schedule.dates[date].closedTimeSlots.delete(timeSlot);
      if (!isDefaultTimeSlot(schedule, date, timeSlot))
        schedule.dates[date].openTimeSlots.add(timeSlot);

      if (isBlankDateSchedule(schedule.dates[date]))
        delete schedule.dates[date];

      toggleChange(schedule.planId, date, true, timeSlot);
    },
    [toggleChange]
  );

  const openTimeSlot = useCallback(
    (schedule: PlanSchedule, date: string, timeSlot: string) =>
      updateSchedules((schedules) => {
        _openTimeSlot(schedule, date, timeSlot);
        schedules.plans[schedule.planId] = { ...schedule };
        return schedules;
      }),
    [updateSchedules, _openTimeSlot]
  );

  const _closeTimeSlot = useCallback(
    (schedule: PlanSchedule, date: string, timeSlot: string) => {
      if (
        schedule.dates[date].closedTimeSlots.has(timeSlot) ||
        !canToggleValue(schedule, date, timeSlot)
      )
        return;

      getOpenTimeSlots(schedule, date).delete(timeSlot);
      if (isDefaultTimeSlot(schedule, date, timeSlot))
        getClosedTimeSlots(schedule, date).add(timeSlot);

      if (isBlankDateSchedule(schedule.dates[date]))
        delete schedule.dates[date];

      toggleChange(schedule.planId, date, false, timeSlot);
    },
    [toggleChange]
  );

  const closeTimeSlot = useCallback(
    (schedule: PlanSchedule, date: string, timeSlot: string) =>
      updateSchedules((schedules) => {
        _closeTimeSlot(schedule, date, timeSlot);
        schedules.plans[schedule.planId] = { ...schedule };

        return schedules;
      }),
    [updateSchedules, _closeTimeSlot]
  );

  const closeAllTimeSlots = useCallback(
    (schedule: PlanSchedule, date: string) =>
      getAvailableTimeSlots(schedule, date).forEach((timeSlot) =>
        _closeTimeSlot(schedule, date, timeSlot)
      ),
    [_closeTimeSlot]
  );

  const openAllTimeSlots = useCallback(
    (schedule: PlanSchedule, date: string) =>
      getClosedTimeSlots(schedule, date).forEach((timeSlot) =>
        _openTimeSlot(schedule, date, timeSlot)
      ),
    [_openTimeSlot]
  );

  const toggleAllTimeSlots = useCallback(
    (schedule: PlanSchedule, date: string) =>
      updateSchedules((schedules) => {
        allTimeSlotsAreClosed(schedule, date)
          ? openAllTimeSlots(schedule, date)
          : closeAllTimeSlots(schedule, date);
        schedules.plans[schedule.planId] = { ...schedule };

        return schedules;
      }),
    [openAllTimeSlots, closeAllTimeSlots, updateSchedules]
  );

  const _openDate = useCallback(
    (schedule: PlanSchedule, date: string) => {
      if (
        schedule.dates[date].state === "open" ||
        !canToggleValue(schedule, date)
      )
        return;
      schedule.dates[date].state = "open";

      toggleChange(schedule.planId, date, true);
    },
    [toggleChange]
  );

  const openDateForAllPlans = useCallback(
    (date: string) => {
      updateSchedules((schedules) => {
        Object.values(schedules.plans).forEach((schedule) => {
          _openDate(schedule, date);
          schedules.plans[schedule.planId] = { ...schedule };
        });
        return schedules;
      });
    },
    [_openDate, updateSchedules]
  );

  const _closeDate = useCallback(
    (schedule: PlanSchedule, date: string) => {
      if (
        schedule.dates[date].state === "closed" ||
        !canToggleValue(schedule, date)
      )
        return;
      schedule.dates[date].state = "closed";

      toggleChange(schedule.planId, date, false);
    },
    [toggleChange]
  );

  const closeDateForAllPlans = useCallback(
    (date: string) => {
      updateSchedules((schedules) => {
        Object.values(schedules.plans).forEach((schedule) => {
          _closeDate(schedule, date);
          schedules.plans[schedule.planId] = { ...schedule };
        });

        return schedules;
      });
    },
    [_closeDate, updateSchedules]
  );

  const addTimeSlot = useCallback(
    (schedule: PlanSchedule, date: string, timeSlot: string) => {
      if (!isExistingTimeSlot(schedule, date, timeSlot))
        openTimeSlot(schedule, date, timeSlot);
    },
    [openTimeSlot]
  );

  const toggleTimeSlot = useCallback(
    (schedule: PlanSchedule, date: string, timeSlot: string) => {
      isClosedTimeSlot(schedule, date, timeSlot)
        ? openTimeSlot(schedule, date, timeSlot)
        : closeTimeSlot(schedule, date, timeSlot);
    },
    [openTimeSlot, closeTimeSlot]
  );

  const toggleHoliday = useCallback(
    (schedule: PlanSchedule, holiday: Holiday) =>
      updateSchedules((schedules) => {
        const { closedHolidays } = schedule;
        const toOpen = closedHolidays.has(holiday);
        toOpen ? closedHolidays.delete(holiday) : closedHolidays.add(holiday);
        toggleChange(schedule.planId, holiday, toOpen);
        schedules.plans[schedule.planId] = {
          ...schedules.plans[schedule.planId],
        };
        return schedules;
      }),
    [updateSchedules, toggleChange]
  );

  const toggleDate = useCallback(
    (planId: string, date: string) => {
      updateSchedules((schedules) => {
        isClosed(schedules.plans[planId], date)
          ? _openDate(schedules.plans[planId], date)
          : _closeDate(schedules.plans[planId], date);
        schedules.plans[planId] = { ...schedules.plans[planId] };
        return schedules;
      });
    },
    [_closeDate, _openDate, updateSchedules]
  );

  const toggleDateForAll = useCallback(
    (schedules: Schedules, date: string) =>
      isDateClosedForAllPlans(schedules, date)
        ? openDateForAllPlans(date)
        : closeDateForAllPlans(date),
    [closeDateForAllPlans, openDateForAllPlans]
  );

  return {
    loading:
      loading ||
      !Object.keys(schedules.plans).length ||
      props.venueId !== schedules.venueId,
    loadError,
    canSave,
    save,
    saving,
    saveError,
    plans,
    numBookings,
    numApproval,
    numApprovalPrevMonth,
    numTotalBookings,
    numTotalBookingsPrevMonth,
    schedules: schedules.plans,
    setSchedules: updateSchedules,
    toggleDate,
    toggleDateForAll,
    addTimeSlot,
    toggleTimeSlot,
    toggleAllTimeSlots,
    toggleHoliday,
  };
}

export type UseSchedules = ReturnType<typeof useSchedules>;
