import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import "./index.css";

export default function ReviewStars({
  rating,
  maxRating = 5,
}: {
  rating: number;
  maxRating?: number;
}) {
  const integral = Math.floor(rating);
  const decimal = rating - integral;

  const stars = Array(integral).fill(StarIcon);
  const halfStars = Array(Math.ceil(decimal)).fill(StarHalfIcon);
  const outlinedStars = Array(Math.floor(maxRating - rating)).fill(
    StarOutlineIcon
  );

  return (
    <ul className="review-stars">
      {[...stars, ...halfStars, ...outlinedStars].map((Icon, index) => (
        <Icon key={index} />
      ))}
    </ul>
  );
}
