import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { Navigate, useParams } from "react-router-dom";

import { API_URL } from "../../../app.config";
import { useNotifications } from "../../../components/Notification";

export default function ResetPassword() {
  const { showNotification } = useNotifications();
  const { resetCode } = useParams<{ resetCode: string }>();

  const [redirect, setRedirect] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");

  if (redirect) return <Navigate to="/login" />;

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "30px" }}>パスワードをリセット</div>
      <div style={{ marginBottom: "10px" }}>
        <TextField
          type="password"
          style={{ backgroundColor: "#fff" }}
          label="新しいパスワード"
          value={newPassword}
          onChange={(e: any) => setNewPassword(e.target.value)}
        />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <TextField
          type="password"
          style={{ backgroundColor: "#fff" }}
          label="新しいパスワードを再入力"
          value={newPasswordAgain}
          onChange={(e: any) => setNewPasswordAgain(e.target.value)}
        />
      </div>
      <Button
        style={{ backgroundColor: "#c8a063", color: "#ffffff" }}
        type="submit"
        variant="contained"
        onClick={() => handleResetPassword()}
      >
        リセット
      </Button>
    </div>
  );

  function handleResetPassword() {
    if (newPassword !== newPasswordAgain)
      return showNotification({
        message: "New passwords are not equal",
        severity: "error",
      });

    fetch(`${API_URL}/user/reset-password`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ resetcode: resetCode, newpassword: newPassword }),
    }).then(async (response) => {
      if (response.status === 200) {
        showNotification({
          message: "パスワードの変更が完了しました！",
          severity: "success",
        });
        setRedirect(true);
      } else {
        showNotification({
          message: "パスワードの変更に失敗しました！",
          severity: "error",
        });
      }
    });
  }
}
