import { Box, Button, ButtonGroup, Stack } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { add, format, isBefore, set, sub } from "date-fns";
import styles from "../styles";
import { Dispatch, SetStateAction } from "react";
import { UseSchedules } from "./useSchedules";

type ToolbarProps = Pick<
  UseSchedules,
  "plans" | "save" | "saving" | "canSave"
> & {
  month: Date;
  setMonth: Dispatch<SetStateAction<Date>>;
};

export default function Toolbar(props: ToolbarProps) {
  return (
    <Stack sx={styles.toolbar} direction="row">
      <div style={{ flexGrow: 1 }}>
        <MonthControls month={props.month} setMonth={props.setMonth} />
      </div>
      <Button
        sx={styles.saveButton}
        variant="contained"
        disabled={!props.canSave || props.saving}
        onClick={() => props.save()}
      >
        保存
      </Button>
    </Stack>
  );

  function MonthControls(props: { month: Date; setMonth: (m: Date) => void }) {
    const currentMonth = set(new Date(), { date: 1, hours: 0, minutes: 0 });
    const previousMonth = sub(props.month, { months: 1 });
    const nextMonth = add(props.month, { months: 1 });
    const previousMonthIsPast = isBefore(previousMonth, currentMonth);

    return (
      <Stack direction="row" spacing={3} alignItems="center">
        <Box sx={styles.monthText}>
          {format(props.month, "yyyy")}年 {format(props.month, "M")}月
        </Box>
        <ButtonGroup sx={styles.monthChangeButtons}>
          <Button
            sx={styles.monthChangeButton}
            disabled={previousMonthIsPast}
            onClick={() => props.setMonth(previousMonth)}
          >
            <ArrowBackIosNewIcon />
          </Button>
          <Button
            sx={styles.monthChangeButton}
            onClick={() => props.setMonth(nextMonth)}
          >
            <ArrowForwardIosIcon />
          </Button>
        </ButtonGroup>
      </Stack>
    );
  }
}
