import ImageUploaderCard from "../../components/ImageUploaderCard";

import ControlledTextField from "../../BasicInfo/components/ControlledTextField";
import { HighlightsField } from "../types";
import "./HighlightCard.css";
import { getArrayFieldState } from "../../formValues";
import { FormContext } from "../../components/FormContext";
import { useContext } from "react";
import { RevertableArrayItem } from "../../components/Revertable";

export default function HighlightCard({
  formIdentifier,
  itemIndex,
  setMediaUrl,
  setMediaType,
  planId,
  highlightId,
}: HighlightCardProps) {
  const { form } = useContext(FormContext);
  const { control, watch } = form;
  const arrayName = HighlightsField.highlights;

  return (
    <RevertableArrayItem
      arrayName={HighlightsField.highlights}
      itemIndex={itemIndex}
    >
      <div className="EditorHighlightCard">
        <ImageUploaderCard
          mediaUrl={watch(`${formIdentifier}.${HighlightsField.mediaUrl}`)}
          mutable={true}
          setMediaUrl={setMediaUrl}
          path={`plans/${planId}/highlights/${highlightId}`}
          mediaType={watch(`${formIdentifier}.${HighlightsField.mediaType}`)}
          setMediaType={setMediaType}
        />

        <div className="EditorHighlightCardInput">
          <ControlledTextField
            control={control}
            name={`${formIdentifier}.${HighlightsField.title}`}
            label={"おすすめポイント"}
            fullWidth
            multiline
            rows={1}
            className={getArrayFieldState({
              form,
              arrayName,
              itemIndex,
              fieldName: HighlightsField.title,
            })}
          />
          <ControlledTextField
            control={control}
            name={`${formIdentifier}.${HighlightsField.details}`}
            label={"説明文"}
            fullWidth
            multiline
            rows={4}
            className={getArrayFieldState({
              form,
              arrayName,
              itemIndex,
              fieldName: HighlightsField.details,
            })}
          />
        </div>
      </div>
    </RevertableArrayItem>
  );
}

interface HighlightCardProps {
  planId: string;
  highlightId: string;
  itemIndex: number;
  formIdentifier: string;
  handleDelete: () => void;
  setMediaUrl: (mediaType: string | null) => void;
  setMediaType: (mediaType: "photo" | "video" | null) => void;
}
