import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export type Severity = "error" | "warning" | "info" | "success";

export type NotificationData = {
  message: string;
  severity: Severity;
};

const DurationBySeverity: Record<Severity, number> = {
  success: 4000,
  info: 4000,
  warning: 8000,
  error: 8000,
};

export function NotificationBar() {
  const { notification, clearNotification } = useNotifications();

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    clearNotification();
  };

  return (
    notification && (
      <Snackbar
        open={true}
        autoHideDuration={DurationBySeverity[notification.severity]}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={notification.severity}>
          {notification.message}
        </Alert>
      </Snackbar>
    )
  );
}

export type NotificationsContextValue = {
  notification: NotificationData | null;
  showNotification: (notification: NotificationData) => void;
  clearNotification: () => void;
};

export const NotificationsContext = React.createContext<NotificationsContextValue>(
  {
    notification: null,
    showNotification: () => {},
    clearNotification: () => {},
  }
);

export function NotificationsProvider({ children }: any) {
  const [
    notification,
    setNotification,
  ] = React.useState<NotificationData | null>(null);

  const showNotification = React.useCallback(
    (notification: NotificationData) => {
      setNotification(notification);
    },
    [setNotification]
  );

  const clearNotification = React.useCallback(() => {
    setNotification(null);
  }, [setNotification]);

  return (
    <NotificationsContext.Provider
      value={{
        notification,
        showNotification,
        clearNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}

export function useNotifications() {
  return React.useContext(NotificationsContext);
}
