import { useContext } from "react";
import FaqCard from "./FaqCard";
import Stack from "@mui/material/Stack";
import { FieldValues, UseFieldArrayReturn } from "react-hook-form";
import { FaqsField } from "../types";
import { FormContext } from "../../components/FormContext";
import { getArrayFieldValue, setArrayFieldValue } from "../../formValues";

export default function FaqList({ fieldArray, planId }: FaqListProps) {
  const { form } = useContext(FormContext);

  const { fields, remove } = fieldArray;

  const removeItem = (itemIndex: number) => {
    form.addRemovedItem({
      fieldName: FaqsField.faqs,
      fieldId: getArrayFieldValue({
        form,
        arrayName: FaqsField.faqs,
        itemIndex,
        fieldName: FaqsField.id,
      }),
    });
    remove(itemIndex);
  };

  return (
    <Stack>
      {fields.map((faq, i) => {
        return (
          <FaqCard
            planId={planId}
            remove={() => removeItem(i)}
            key={faq.id}
            faqId={faq.id}
            itemIndex={i}
            setImageUrl={(imageUrl: string | null) => {
              setArrayFieldValue({
                form,
                arrayName: FaqsField.faqs,
                itemIndex: i,
                fieldName: FaqsField.imageUrl,
                value: imageUrl,
              });
            }}
            formIdentifier={`${FaqsField.faqs}.${i}`}
            setDeleted={(isDeleted: boolean) =>
              setArrayFieldValue({
                form,
                arrayName: FaqsField.faqs,
                itemIndex: i,
                fieldName: FaqsField.isDeleted,
                value: isDeleted,
              })
            }
          />
        );
      })}
    </Stack>
  );
}

interface FaqListProps {
  planId: string;
  fieldArray: UseFieldArrayReturn<FieldValues, FaqsField.faqs, "id">;
}
