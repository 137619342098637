export const enum PaidOptionField {
  plan_paid_items = "planPaidItems",
  imageUrl = "imageUrl",
  id = "fieldId",
  isDeleted = "isDeleted",
  name = "name",
  price = "price",
  description = "description",
  status = "status",
}
