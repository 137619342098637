import equal from "deep-equal";

export type ObjectCompareResult = {
  success: boolean;
  name?: string;
  prevValue?: any;
  nextValue?: any;
};

export const compareProps = <T = { [key: string]: any }>(
  prev: T,
  next: T,
  options?: {
    omits?: (keyof T)[];
    debug?: boolean;
  }
): boolean => {
  const failed: ObjectCompareResult[] = Object.keys(prev)
    .map(
      (key: string): ObjectCompareResult => {
        if (options?.omits && options?.omits?.includes(key as keyof T))
          return { success: true };
        const prevValue = prev[key as keyof T];
        const nextValue = next[key as keyof T];
        const result = equal(prevValue, nextValue);
        return { success: result, name: key, prevValue, nextValue };
      }
    )
    .filter((val) => !val.success);
  if (failed.length > 0 && !!options?.debug)
    failed.map((f) =>
      console.log("changed", f.name, ":", f.prevValue, "to", f.nextValue)
    );
  return failed.length === 0;
};
