import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  jaJP,
} from "@mui/x-data-grid";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Typography } from "@mui/material";

import { useMemo, useState } from "react";
import renders from "./cost-column-renders";
import valueExports from "./cost-column-value-exports";
import { ALL_VENUES, Reservation } from "./types";
import ExportMenu from "./Export/CustomExportButton";
import { Venue } from ".";

export default function Overview(props: {
  venue?: Venue;
  getPdfData: () => Promise<string>;
  reservations: Reservation[];
  totalCount: number;
  yearMonth: string;
}) {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  const columns = [
    getColumn("id", "予約ID", true, true, 120),
    getColumn("status", "予約状況", false, true, 100),
    getColumn("createdAt", "発生日時", false, true, 100),
    getColumn("datetime", "予約日時", false, true, 90),
    getColumn("plan", "プラン名", false, true, 250),
    getColumn("customer", "予約者名", false, false, 150),
    getColumn("items", "人数", false, false, 160),
    getColumn("paymentMethod", "決済⽅法", false, true, 180),
    getColumn("payment", "予約取扱⾦額", false, true, 170),
    getColumn("cancellation", "キャンセル料", false, false, 170),
    getColumn("commissionRate", "弊社手数料率", false, false, 170),
    getColumn("commissionFee", "弊社手数料額", false, false, 170),
  ];

  const rows = props.reservations ? props.reservations : [];
  const totalRows = props.totalCount ? props.totalCount : 0;

  const disabledReason = useMemo(() => {
    if (props.venue?.id === ALL_VENUES)
      return "明細書は1店舗ごとでしか出力出来ません";

    const date = new Date(props.yearMonth);
    const now = new Date();

    const sameYearEarlierMonth =
      date.getFullYear() === now.getFullYear() &&
      date.getMonth() < now.getMonth();
    const earlierYear = date.getFullYear() < now.getFullYear();
    const pastMonth = sameYearEarlierMonth || earlierYear;

    if (!pastMonth) {
      return "過去の月を選択して下さい";
    }

    return "";
  }, [props.venue, props.yearMonth]);

  return (
    <>
      <Typography
        sx={{
          margin: "1rem",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        variant="h5"
      >
        ご予約情報
      </Typography>
      <DataGrid
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
        style={{ backgroundColor: "#fff" }}
        loading={props.reservations.length === 0}
        components={{
          Toolbar: () => {
            return (
              <GridToolbarContainer style={{ justifyContent: "space-between" }}>
                <GridToolbarFilterButton />
                <ExportMenu
                  csvOptions={{
                    delimiter: ",",
                    allColumns: true,
                    fileName:
                      props.yearMonth.slice(0, 7) + "-Annyお祝い体験月次売上",
                  }}
                  pdfOptions={{
                    disabledReason,
                    getPdfData: props.getPdfData,
                    fileName: `【Annyお祝い体験】お支払通知書-${
                      props.venue?.name
                    }-${props.yearMonth.slice(0, 4)}年${props.yearMonth.slice(
                      5,
                      7
                    )}月ご利用分`,
                  }}
                />
              </GridToolbarContainer>
            );
          },
          ColumnMenuIcon: () => <FilterAltIcon fontSize="small" />,
        }}
        columns={columns}
        rows={rows}
        autoHeight={true}
        disableRowSelectionOnClick={true}
        rowCount={totalRows}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[10, 25, 50]}
      />
    </>
  );

  function getColumn(
    field: any,
    name: string,
    menu: boolean,
    sort: boolean,
    width?: number,
    type?: string
  ) {
    return {
      field: field,
      headerName: name,
      sortable: sort,
      disableColumnMenu: menu ? false : true,
      flex: width ? undefined : 1,
      type: type ? type : "string",
      width: width ? width : undefined,
      renderCell: renders[field],
      valueGetter: valueExports[field],
    };
  }
}
