import { FaBabyCarriage, FaGlassCheers, FaHiking, FaCar } from "react-icons/fa";
import { GiChickenOven, GiPresent, GiBeerStein } from "react-icons/gi";
import { GoPrimitiveDot } from "react-icons/go";
import { IconType } from "react-icons/lib";
import { MdLocalActivity } from "react-icons/md";
import { RiRestaurantLine, RiCakeLine } from "react-icons/ri";
import {
  barIcon,
  conferenceIcon,
  diningTableIcon,
  dinnerIcon,
  terraceBIcon,
  terraceIcon,
  vipIcon,
} from "./svgIcons";

export const inclusionIcons = new Map<string, JSX.Element | IconType>([
  // react icons
  ["GoPrimitiveDot", <GoPrimitiveDot />],
  ["RiRestaurantLine", <RiRestaurantLine />],
  ["RiCakeLine", <RiCakeLine />],
  ["GiPresent", <GiPresent />],
  ["MdLocalActivity", <MdLocalActivity />],
  ["FaGlassCheers", <FaGlassCheers />],
  ["GiBeerStein", <GiBeerStein />],
  ["FaHiking", <FaHiking />],
  ["FaBabyCarriage", <FaBabyCarriage />],
  ["FaCar", <FaCar />],
  ["GiChickenOven", <GiChickenOven />],
  // svg icons
  ["Bar", barIcon],
  ["Conference", conferenceIcon],
  ["DiningTable", diningTableIcon],
  ["Dinner", dinnerIcon],
  ["Terrace", terraceIcon],
  ["TerraceB", terraceBIcon],
  ["VIP", vipIcon],
]);
