import {
  Stack,
  Button,
  TextField,
  IconButton,
  SxProps,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TimePicker from "@mui/lab/TimePicker";
import { useMemo, useState } from "react";
import {
  isCutoff,
  isExistingTimeSlot,
  UseSchedules,
} from "../../../../../useSchedules";
import { OmitFirstTwoArg } from "../../../../../types";
import { formatTimeSlot } from "../../../../../helpers";

type ActionsProps = {
  date: string;
  addTimeSlot: OmitFirstTwoArg<UseSchedules["addTimeSlot"]>;
  isCutoff: OmitFirstTwoArg<typeof isCutoff>;
  isExistingTimeSlot: OmitFirstTwoArg<typeof isExistingTimeSlot>;
  hasTimeSlots: boolean;
  allTimeSlotsAreClosed: boolean;
  toggleAllTimeSlots: OmitFirstTwoArg<UseSchedules["toggleAllTimeSlots"]>;
};

export default function Actions({
  date,
  addTimeSlot,
  isCutoff,
  hasTimeSlots,
  allTimeSlotsAreClosed,
  toggleAllTimeSlots,
  isExistingTimeSlot,
}: ActionsProps) {
  return (
    <Stack spacing={1}>
      <CloseOpenAll
        hasTimeSlots={hasTimeSlots}
        allTimeSlotsAreClosed={allTimeSlotsAreClosed}
        toggleAllTimeSlots={toggleAllTimeSlots}
      />
      <AddTimeSlot
        date={date}
        isCutoff={isCutoff}
        addTimeSlot={addTimeSlot}
        isExistingTimeSlot={isExistingTimeSlot}
      />
    </Stack>
  );
}

function CloseOpenAll(
  props: Pick<
    ActionsProps,
    "hasTimeSlots" | "allTimeSlotsAreClosed" | "toggleAllTimeSlots"
  >
) {
  if (!props.hasTimeSlots) return <></>;

  return (
    <Button
      variant="outlined"
      startIcon={
        props.allTimeSlotsAreClosed ? (
          <CheckCircleIcon />
        ) : (
          <DoNotDisturbOnIcon />
        )
      }
      sx={props.allTimeSlotsAreClosed ? sx.OpenAllButton : sx.closeAllButton}
      onClick={props.toggleAllTimeSlots}
    >
      {props.allTimeSlotsAreClosed
        ? "全ての予約スロットを販売開始にする"
        : "全ての予約スロットを売止にする"}
    </Button>
  );
}

function AddTimeSlot({
  addTimeSlot,
  isCutoff,
  date,
  isExistingTimeSlot,
}: Pick<
  ActionsProps,
  "addTimeSlot" | "isCutoff" | "date" | "isExistingTimeSlot"
>) {
  const [newTimeSlot, setNewTimeSlot] = useState<null | Date>(null);

  const formattedTimeSlot = formatTimeSlot(newTimeSlot);

  const addTimeSlotError = useMemo(() => {
    if (newTimeSlot === null) return "予約受付時間の設定がありません";
    if (isNaN(newTimeSlot.getTime())) return "無効な値が入力されています";
    if (isExistingTimeSlot(formattedTimeSlot as string))
      return "予約受付時間が既に設定されています";
    if (isCutoff(formattedTimeSlot))
      return "予約受付締切時間を過ぎているため設定出来ません";
  }, [formattedTimeSlot, newTimeSlot, isCutoff, isExistingTimeSlot]);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          label="予約スロットを追加する"
          value={newTimeSlot}
          onChange={(newValue) => {
            if (newValue !== null) {
              const currentDate = new Date(date);
              newValue.setFullYear(currentDate.getFullYear());
              newValue.setMonth(currentDate.getMonth());
              newValue.setDate(currentDate.getDate());
            }
            setNewTimeSlot(newValue);
          }}
          renderInput={(params) => (
            <TextField size="small" sx={{ width: "256px" }} {...params} />
          )}
          ampm={false}
        />
      </LocalizationProvider>
      <Tooltip title={addTimeSlotError ?? ""}>
        <span>
          <IconButton
            disabled={!!addTimeSlotError}
            size="small"
            sx={!newTimeSlot ? sx.addTimeSlotDisabled : sx.addTimeSlot}
            onClick={() => {
              addTimeSlot(formattedTimeSlot!);
              setNewTimeSlot(null);
            }}
          >
            <AddIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Stack>
  );
}

const sx: { [id: string]: SxProps } = {
  OpenAllButton: {
    color: "green",
    border: "1px solid green",
    "&:hover": { color: "green", border: "1px solid green" },
  },
  closeAllButton: {
    color: "#f00",
    border: "1px solid #f00",
    "&:hover": { color: "#f00", border: "1px solid #f00" },
  },
  addTimeSlotDisabled: {
    border: "1px solid gray",
    borderRadius: "5px",
    color: "gray",
  },
  addTimeSlot: {
    border: "1px solid black",
    borderRadius: "5px",
    color: "black",
  },
  saveTimeSlots: {
    color: "#c8a063",
    borderColor: "#c8a063",
    "&:hover": { borderColor: "#c8a063", backgroundColor: "#eee" },
  },
};
