import { Plan } from "../.";
import { useMemo, useState } from "react";
import SortableRow, { SortablePlanRowProps } from "./SortableRow";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import type { Active } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import { DndContext } from "@dnd-kit/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./SortablePlanList.css";
import { SortableOverlay } from "./SortableOverlay";

export default function SortablePlanList({
  plans,
  venueName,
  onChange,
}: SortablePlanListProps) {
  const [active, setActive] = useState<Active | null>(null);
  const activeItem: SortablePlanRowProps | null = useMemo(() => {
    const plan = plans.find((plan) => plan.id === active?.id);
    if (plan === undefined) return null;
    return {
      planId: plan.id,
      planName: plan.name,
      ...plan.bookings,
      priceTypes: plan.activities
        .flatMap((activities) => activities.priceTypes)
        .filter((priceType) => priceType.active),
      firstPublishedAt: plan.firstPublishedAt,
      venueName: venueName,
    };
  }, [active, plans, venueName]);

  const rows: SortablePlanRowProps[] = useMemo(() => {
    return plans.map((plan) => ({
      planId: plan.id,
      planName: plan.name,
      ...plan.bookings,
      priceTypes: plan.activities
        .flatMap((activities) => activities.priceTypes)
        .filter((priceType) => priceType.active),
      firstPublishedAt: plan.firstPublishedAt,
      venueName: venueName,
    }));
  }, [plans, venueName]);

  return (
    <DndContext
      modifiers={[restrictToParentElement]}
      onDragStart={({ active }) => {
        setActive(active);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = plans.findIndex(({ id }) => id === active.id);
          const overIndex = plans.findIndex(({ id }) => id === over.id);

          onChange(arrayMove(plans, activeIndex, overIndex));
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      {/* TODO: move part of this to SortablePlanTable? */}
      <SortableContext items={plans} strategy={verticalListSortingStrategy}>
        <TableContainer>
          <Table className="DndTable" size="small">
            <TableHead>
              <TableRow>
                <TableCell className="EditColumn"></TableCell>
                <TableCell className="DetailsColumn"></TableCell>
                <TableCell className="AcceptanceRateColumn">
                  予約受入率
                </TableCell>
                <TableCell className="VenueNameColumn">店舗名</TableCell>
                <TableCell className="PlanNameColumn">掲載プラン名</TableCell>
                <TableCell className="PriceTypesColumn">
                  販売価格（税・サ込）
                </TableCell>
                <TableCell className="PublishedDateColumn">公開日</TableCell>
                <TableCell className="DragHandleColumn"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <SortableRow key={row.planId} {...row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </SortableContext>
      <SortableOverlay>
        {activeItem ? (
          <SortableRow key={activeItem.planId} {...activeItem} />
        ) : null}
      </SortableOverlay>
    </DndContext>
  );
}

interface SortablePlanListProps {
  plans: Plan[];
  venueName: string;
  onChange(plans: Plan[]): void;
}
