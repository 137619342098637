import { PropsWithChildren, useContext, useState } from "react";
import UndoButton from "./UndoButton";

import "./Revertable.css";
import { FieldState } from "../types";
import {
  createArrayItemName,
  getArrayItemState,
  getFieldState,
  setArrayItemValue,
  setFieldValue,
} from "../formValues";
import { FormContext } from "./FormContext";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export function getFieldCourse(values: any, fieldName: string) {
  if (typeof values === "object") {
    const nameArr = fieldName.split(".");
    return values[nameArr[0]][nameArr[1]][nameArr[2]];
  }
}

export function getValueFromObject(object: any, path: any) {
  const properties = path.split(".");
  let result = object;

  for (let prop of properties) {
    if (prop.match(/^\d+$/)) {
      prop = parseInt(prop);
    }
    result = result[prop];
    if (result === undefined) {
      return undefined;
    }
  }

  return result;
}

export function Revertable({ ...props }: PropsWithChildren<RevertableProps>) {
  const { form } = useContext(FormContext);

  const handleUndo = () => {
    setFieldValue({
      form,
      ...props,
      value: props.isCourse
        ? getFieldCourse(form.originalValues, props.fieldName)
        : form.originalValues[props.fieldName],
    });
  };

  const getState = () => getFieldState({ form, ...props });

  return (
    <div>
      <RevertableContent
        {...props}
        handleUndo={handleUndo}
        getState={getState}
        className="Revertable"
      />
    </div>
  );
}

export function RevertableArrayField({
  arrayName,
  itemIndex,
  fieldName,
  ...rest
}: PropsWithChildren<RevertableProps & RevertableArrayProps>) {
  const arrayItemName = createArrayItemName(arrayName, itemIndex, fieldName);

  return Revertable({
    fieldName: arrayItemName,
    ...rest,
  });
}

export function RevertableArrayItem({
  remove,
  ...props
}: PropsWithChildren<RevertableArrayProps>) {
  const { form } = useContext(FormContext);

  const handleUndo = () => {
    const state = getArrayItemState({ form, ...props });

    switch (state) {
      case FieldState.added:
        remove !== undefined && remove(props.itemIndex);
        break;
      case FieldState.touched:
      case FieldState.removed:
        setArrayItemValue({
          form,
          ...props,
          value:
            props.photoIndex || props.photoIndex === 0
              ? getValueFromObject(form.originalValues, props.arrayName)[
                  props.itemIndex
                ]
              : form.originalValues[props.arrayName][props.itemIndex],
        });
        break;
    }
  };

  const getState = () =>
    getArrayItemState({
      form,
      ...props,
    });

  return (
    <RevertableContent
      {...props}
      handleUndo={handleUndo}
      getState={getState}
      className="Revertable RevertableArray"
    />
  );
}

type RevertableProps = {
  fieldName: string;
  isCourse?: boolean;
};

type RevertableArrayProps = {
  arrayName: string;
  itemIndex: number;
  photoIndex?: number;
  remove?: (index: number) => void;
};

function RevertableContent({
  getState,
  handleUndo,
  children,
  className,
}: PropsWithChildren<RevertableContentProps>) {
  const isTouched = getState() !== FieldState.untouched;
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <div className={`${className} ${isTouched ? "Touched" : ""}`}>
        {isTouched && <UndoButton handleUndo={() => setOpenDialog(true)} />}
        {children}
      </div>
      <RevertDialog
        open={openDialog}
        setOpen={setOpenDialog}
        handleUndo={handleUndo}
      />
    </>
  );
}

function RevertDialog({ open, setOpen, handleUndo }: RevertDialogProps) {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{"変更依頼を解除しますか？"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          変更依頼の取り消しを実行しますか？
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} autoFocus>
          キャンセル
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
            handleUndo();
          }}
        >
          依頼解除
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type RevertDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleUndo: () => void;
};

type RevertableContentProps = {
  handleUndo: () => void;
  getState: () => FieldState;
  className: string;
};
