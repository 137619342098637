import RestoreIcon from "@mui/icons-material/Restore";
import { IconButton } from "@mui/material";
import "./UndoButton.css";

export default function UndoButton({ handleUndo }: UndoButtonProps) {
  return (
    <IconButton className="UndoButton" onClick={handleUndo}>
      <RestoreIcon />
    </IconButton>
  );
}

interface UndoButtonProps {
  handleUndo: () => void;
}
