import {
  getDay as datefnsGetDay,
  parse as datefnsParse,
  format as datefnsFormat,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  addDays,
} from "date-fns";
import Holidays from "date-holidays";
import { daysOfTheWeek } from "../../../../types";

export const dayAbrToDay = new Map(
  daysOfTheWeek.map((day, index) => [day, index])
);

export const parseDate = (date: string) =>
  datefnsParse(date, "yyyy-MM-dd", new Date());

export const parseTimeSlot = (timeSlot: string) => ({
  hours: Number(timeSlot.slice(0, 2)),
  minutes: Number(timeSlot.slice(3)),
});

export const formatDate = (date: Date | null) =>
  !!date ? datefnsFormat(date, "yyyy-MM-dd") : undefined;

export const formatTimeSlot = (date: Date | null) =>
  !!date ? datefnsFormat(date, "HH:mm") : undefined;

export const getDay = (date: string) => datefnsGetDay(parseDate(date));

export const extractYear = (datetime: string) => datetime.slice(0, 4);

export const extractMonth = (datetime: string) => datetime.slice(5, 7);

export const extractDate = (datetime: string) => datetime.slice(0, 10);

export const extractTime = (datetime: string) => datetime.slice(11, 16);

export const extractDateAndTime = (datetime: string) =>
  [extractDate, extractTime].map((fn) => fn(datetime));

export function setDifference<T>(setA: Set<T>, setB: Set<T>): Set<T> {
  const difference = new Set<T>(setA);
  setB.forEach((item: T) => difference.delete(item));
  return difference;
}

export function setUnion<T>(setA: Set<T>, setB: Set<T>): Set<T> {
  return new Set([...setA, ...setB]);
}

const hd = new Holidays("JP");

const holidaysPerYear = new Map<string, Set<string>>();

export const getHolidays = (year: string | number) =>
  hd
    .getHolidays(year)
    .filter(({ name }) => !["大晦日", "ノエル", "七五三"].includes(name));

export const getHolidaysOfMonth = (date: string) => {
  const year = extractYear(date);
  if (!holidaysPerYear.has(year))
    holidaysPerYear.set(
      year,
      new Set(getHolidays(year).map(({ date }) => extractDate(date)))
    );

  const month = extractMonth(date);
  return new Set(
    [...holidaysPerYear.get(year)!].filter(
      (date) => extractMonth(date) === month
    )
  );
};

export const isHoliday = (date: string | Date) => {
  const dateString = typeof date === "string" ? date : formatDate(date)!;
  const year = dateString.slice(0, 4);
  if (!holidaysPerYear.has(year))
    holidaysPerYear.set(
      year,
      new Set(getHolidays(year).map(({ date }) => date.slice(0, 10)))
    );
  return holidaysPerYear.get(year)?.has(dateString);
};

export const isPreHoliday = (date: string | Date) => {
  const nextDay = formatDate(
    addDays(typeof date === "string" ? parseDate(date) : date, 1)
  )!;
  return !isHoliday(date) && isHoliday(nextDay);
};

export const holidayDayDifference = {
  祝日: 0,
  祝前日: -1,
};

export const holidays = ["祝日", "祝前日"] as const;
export type Holiday = typeof holidays[number];
export const holidayDateDifference = {
  祝日: 0,
  祝前日: -1,
} as const;

export const isHolidayType = (maybeHoliday: string): maybeHoliday is Holiday =>
  holidays.includes(maybeHoliday as Holiday);

export const getAllDatesInMonth = (date: Date) => {
  const startOfMonthDate = startOfMonth(date);
  const endOfMonthDate = endOfMonth(date);

  const allDatesInMonth = eachDayOfInterval({
    start: startOfMonthDate,
    end: endOfMonthDate,
  });

  return allDatesInMonth.map(formatDate) as string[];
};
