import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import _ from "lodash";

import { IColumn } from "..";
import SortableItem from "./Item";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  UniqueIdentifier,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  List,
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

export default function SortableColumns(props: SortableColumnsProps) {
  const user = JSON.parse(localStorage.getItem("user") ?? '')
  const columns = props.columns.map(column => column.field)

  const [items, setItems] = useState<UniqueIdentifier[]>(columns)
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [saveCustomSettings, { loading }] = useMutation(UPDATE_CUSTOM_SETTINGS)

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    const overId = over ? over.id : ''

    if (active.id !== overId) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id)
        const newIndex = items.indexOf(overId)

        return arrayMove(items, oldIndex, newIndex)
      })
    }
  };

  const closeDialog = () => props.toggleVisible(false)

  const onSaveBtnClicked = () => {
    const sortedColumns = items.map(item => item.toString())
    const newSettings = _.merge(_.get(user, 'customSettings'), {
      ui: { bookings: { columns: sortedColumns } }
    })

    saveCustomSettings({
      variables: { partnerId: user.id, settings: JSON.stringify(newSettings) }
    }).then(() => {
      localStorage.setItem("user", JSON.stringify({
        ...user,
        customSettings: newSettings
      }))

      props.setSortedColumns(sortedColumns)
      closeDialog()
    })
  }

  return (
    <Dialog open={props.visible}>
      <DialogTitle sx={{ m: 0, p: 2 }}>下記項目の並び順を変更する</DialogTitle>
      <DialogContent>
        <Box sx={{ width: "360px" }}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
              >
                {items.map((item) => (
                  <SortableItem
                    key={item}
                    index={item}
                    label={props.columns.find(col => col.field === item)?.headerName ?? ''}
                  />
                ))}
              </SortableContext>
            </List>
          </DndContext>
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          variant="outlined"
          sx={{
            backgroundColor: "white",
            color: "#c8a063",
            borderColor: "#c8a063",
            "&:hover": {
              borderColor: "#c8a063",
            },
          }}
          onClick={closeDialog}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          loading={loading}
          sx={{
            color: "#ffffff",
            backgroundColor: "#c8a063",
            margin: "0.5em",
            "&:hover": {
              backgroundColor: "#a37939",
            },
          }}
          variant="contained"
          onClick={onSaveBtnClicked}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

interface SortableColumnsProps {
  visible: boolean
  toggleVisible: (value: boolean) => void
  columns: IColumn[]
  setSortedColumns: (items: string[]) => void
}

const UPDATE_CUSTOM_SETTINGS = gql`
  mutation updateCustomSettings($partnerId: ID!, $settings: String!) {
    saveCustomSettings(
      partnerId: $partnerId,
      settings: $settings
    ) {
      success
    }
  }
`
