export const enum ActivitiesField {
  Course = "course",
  CourseMenu = "courseMenu",
  CoursePhotos = "coursePhotos",
  CoursePhotoId = "fieldId",
  CoursePhotoSrc = "src",
  CoursePhotoIndex = "photoIndex",
  CoursePhotoIsDeleted = "isDeleted",
  CoursePhotoIsAdded = "isAdded",
}
