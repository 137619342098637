import { default as MuiTextField } from "@mui/material/TextField";
import type { OutlinedTextFieldProps } from "@mui/material/TextField";

export default function TextField(props: TextFieldProps) {
  return (
    <MuiTextField
      {...props}
      sx={{
        width: props.width ? props.width : "100%",
        input: { textAlign: props.textalign ? props.textalign : "left" },
      }}
      InputProps={{
        readOnly: props.readOnly,
        style: { color: props.textcolor },
        startAdornment: props.startadornment ? props.startadornment : undefined,
        endAdornment: props.endadornment ? props.endadornment : undefined,
      }}
    />
  );
}

interface TextFieldProps extends Omit<OutlinedTextFieldProps, "variant"> {
  textcolor?: string;
  readOnly?: boolean;
  startadornment?: JSX.Element;
  endadornment?: JSX.Element;
  width?: string;
  textalign?: "center" | "left" | "right";
}
