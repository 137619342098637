import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import DateRangePicker from "../../../components/DateRangePicker";

export default function ColumnMenu(props: ColumnMenuProps) {
  if (props.field === "status") return renderStatusColumnMenu();
  if (props.field === "createdAt") return renderCreatedAtColumnMenu();
  if (props.field === "venue") return renderVenueColumnMenu();
  if (props.field === "plan") return renderPlanColumnMenu();
  if (props.field === "datetime") return renderDatetimeColumnMenu();
  return <></>;

  function renderStatusColumnMenu() {
    return (
      <MenuList>
        <MenuItem
          selected={props.reservationStatus.value === null}
          onClick={(e) => handleClick(e, null)}
        >
          全て
        </MenuItem>
        <Divider />
        <MenuItem
          style={{ color: "red" }}
          selected={props.reservationStatus.value === "NOT_YET_RESERVED"}
          onClick={(e) => handleClick(e, "NOT_YET_RESERVED")}
        >
          要回答
        </MenuItem>
        <MenuItem
          style={{ color: "green" }}
          selected={props.reservationStatus.value === "RESERVED"}
          onClick={(e) => handleClick(e, "RESERVED")}
        >
          確定
        </MenuItem>
        <MenuItem
          selected={props.reservationStatus.value === "REJECTED"}
          onClick={(e) => handleClick(e, "REJECTED")}
        >
          受入不可
        </MenuItem>
        <MenuItem
          selected={props.reservationStatus.value === "CANCELLED"}
          onClick={(e) => handleClick(e, "CANCELLED")}
        >
          キャンセル
        </MenuItem>
      </MenuList>
    );

    function handleClick(event: any, value: string | null) {
      props.reservationStatus.set(value);
      props.closeMenu(event);
    }
  }

  function renderCreatedAtColumnMenu() {
    return (
      <DateRangePicker
        from={props.created.from}
        until={props.created.until}
        onClose={props.closeMenu}
        maxDate={new Date()}
      />
    );
  }

  function renderVenueColumnMenu() {
    return (
      <MenuList>
        <MenuItem
          selected={props.venueId.value === null}
          onClick={(e) => handleClick(e, null)}
        >
          全ての店舗
        </MenuItem>
        <Divider />
        {props.venues.map((v) => (
          <MenuItem
            key={v.id}
            selected={props.venueId.value === v.id}
            onClick={(e) => handleClick(e, v.id)}
          >
            {v.name}
          </MenuItem>
        ))}
      </MenuList>
    );

    function handleClick(event: any, value: string | null) {
      props.venueId.set(value);
      props.closeMenu(event);
    }
  }

  function renderPlanColumnMenu() {
    return (
      <MenuList>
        <MenuItem
          selected={props.planId.value === null}
          onClick={(e) => handleClick(e, null)}
        >
          全てのプラン
        </MenuItem>
        <Divider />
        {props.plans.map((p) => (
          <MenuItem
            key={p.id}
            selected={props.planId.value === p.id}
            onClick={(e) => handleClick(e, p.id)}
          >
            {p.name}
          </MenuItem>
        ))}
      </MenuList>
    );

    function handleClick(event: any, value: string | null) {
      props.planId.set(value);
      props.closeMenu(event);
    }
  }

  function renderDatetimeColumnMenu() {
    return (
      <DateRangePicker
        from={props.datetime.from}
        until={props.datetime.until}
        onClose={props.closeMenu}
      />
    );
  }
}

interface ColumnMenuProps {
  closeMenu: (event: any) => void;
  field: string;
  reservationStatus: {
    value: string | null;
    set: (value: string | null) => void;
  };
  created: {
    from: { value: string | null; set: (value: string | null) => void };
    until: { value: string | null; set: (value: string | null) => void };
  };
  datetime: {
    from: { value: string | null; set: (value: string | null) => void };
    until: { value: string | null; set: (value: string | null) => void };
  };
  venueId: {
    value: string | null;
    set: (value: string | null) => void;
  };
  planId: {
    value: string | null;
    set: (value: string | null) => void;
  };
  venues: any[];
  plans: any[];
}
