// @ts-ignore
import {
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
} from "@mui/x-data-grid";
import { ButtonProps } from "@mui/material/Button";

// import PdfExportMenuItem from "./PdfExportMenuItem";

type CustomExportButtonProps = {
  csvOptions: any;
  pdfOptions: {
    getPdfData: () => Promise<string>;
    disabledReason: string;
    fileName: string;
  };
  buttonProps?: ButtonProps;
};

export default function ExportMenu({
  csvOptions,
  pdfOptions,
  buttonProps,
}: CustomExportButtonProps) {
  return (
    <GridToolbarExportContainer {...buttonProps}>
      <GridCsvExportMenuItem options={csvOptions} />
      {/* TODO: implement updated requirements before enabling as found in this note:
      https://gitlab.com/omedetaro/development/system/-/issues/325#note_1312890047 */}
      {/* <PdfExportMenuItem {...pdfOptions} /> */}
    </GridToolbarExportContainer>
  );
}
