import { gql, useMutation, useQuery } from "@apollo/client";
import { max } from "date-fns";
import { useMemo } from "react";
import { EditNotificationStack } from "./EditNotificationStack/EditNotificationStack";

export default function EditNotifications() {
  const { data: notificationData } = useQuery<
    GetEditNotificationsResponse,
    GetEditNotificationsInput
  >(GET_EDIT_NOTIFICATIONS, {
    variables: {
      input: {
        onlyUnseen: false,
      },
    },
  });

  const rowData = useMemo(() => {
    if (notificationData === undefined) return [];

    const groupedNotifications = notificationData.partnerEditNotifications.reduce(
      (acc, { planId, planName, createdAt, seenAt }) => {
        const createdAtDate = new Date(createdAt);
        if (!acc[planId])
          acc[planId] = { planName, createdAt: createdAtDate, count: 0 };
        acc[planId] = {
          ...acc[planId],
          createdAt: max([createdAtDate, acc[planId].createdAt]),
          count: acc[planId].count + (seenAt === null ? 1 : 0),
        };
        return acc;
      },
      {} as {
        [planId: string]: {
          planName: string;
          createdAt: Date;
          count: number;
        };
      }
    );

    return Object.entries(groupedNotifications)
      .map(([planId, { planName, createdAt, count }]) => ({
        planName,
        planId,
        createdAt,
        count,
      }))
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
      .slice(0, 5);
  }, [notificationData]);

  const input = useMemo(
    () => ({
      ids:
        notificationData?.partnerEditNotifications
          .filter(({ seenAt }) => seenAt === null)
          .map(({ id }) => id) ?? [],
    }),
    [notificationData]
  );

  const [setToSeen] = useMutation<
    SetEditNotificationsToSeenResponse,
    SetEditNotificationsToSeenInput
  >(SET_EDIT_NOTIFICATIONS_TO_SEEN, {
    variables: { input },
    update: (cache) => {
      const notifications = cache.readQuery<GetEditNotificationsResponse>({
        query: GET_EDIT_NOTIFICATIONS,
        variables: {
          input: {
            onlyUnseen: false,
          },
        },
      });

      const data = {
        partnerEditNotifications:
          notifications?.partnerEditNotifications.map((notification) => ({
            ...notification,
            seenAt: new Date().toISOString(),
          })) ?? [],
      };

      cache.writeQuery<GetEditNotificationsResponse>({
        query: GET_EDIT_NOTIFICATIONS,
        variables: {
          input: {
            onlyUnseen: false,
          },
        },
        data,
      });
    },
  });

  return (
    <EditNotificationStack
      rowData={rowData}
      onClick={setToSeen}
      disabled={rowData.length === 0}
    />
  );
}

const GET_EDIT_NOTIFICATIONS = gql`
  query GetNotifications($input: PartnerEditNotificationsInput!) {
    partnerEditNotifications(input: $input) {
      id
      planId
      planName
      createdAt
      seenAt
    }
  }
`;

type GetEditNotificationsInput = {
  input: {
    onlyUnseen?: boolean;
  };
};

type GetEditNotificationsResponse = {
  partnerEditNotifications: {
    id: string;
    planId: string;
    planName: string;
    createdAt: string;
    seenAt: string;
  }[];
};

const SET_EDIT_NOTIFICATIONS_TO_SEEN = gql`
  mutation SetEditNotificationsToSeen(
    $input: SetPartnerEditNotificationsToSeenInput!
  ) {
    setPartnerEditNotificationsToSeen(input: $input) {
      success
      error
    }
  }
`;

type SetEditNotificationsToSeenInput = {
  input: {
    ids: string[];
  };
};

type SetEditNotificationsToSeenResponse = {
  success: boolean;
  error?: string;
};
