import { default as MuiModal } from "@mui/material/Modal";
import Box from "@mui/system/Box";

export default function Modal(props: ModalProps) {
  return (
    <MuiModal
      open={props.value !== null}
      onClose={() => props.setValue(null)}
      disableAutoFocus={true}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          backgroundColor: "rgb(255, 255, 255, 0.9)",
          borderRadius: "5px",
          border: "1px solid black",
          p: 4,
          textAlign: "center",
        }}
      >
        {props.value}
      </Box>
    </MuiModal>
  );
}

interface ModalProps {
  value: null | string;
  setValue: (value: null | string) => void;
}
