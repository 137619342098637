import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import ReceiptIcon from "@mui/icons-material/Receipt";
import EmailIcon from "@mui/icons-material/Email";
import HelpIcon from "@mui/icons-material/Help";
import GradeIcon from "@mui/icons-material/Grade";

import styles from "./styles";
import Logo from "../Logo";
import { useGlobalContext } from "../../GlobalContext";
import UserMenu from "./UserMenu";
import PlansMenu from "./PlansMenu";
import ReportsMenu from "./ReportsMenu";

export default function Menu() {
  const { isLoggedIn } = useGlobalContext();
  if (!isLoggedIn) return <></>;

  return (
    <AppBar sx={styles.menuAppBar} position="static">
      <Toolbar sx={styles.menuToolBar} variant="dense">
        <Logo iconColor="#80642a" textColor="#fff" />
        <div style={{ marginLeft: "50px" }}>
          <Button
            sx={styles.menuButton}
            startIcon={<ReceiptIcon />}
            href="/bookings"
          >
            予約管理
          </Button>
        </div>
        <div style={{ marginLeft: "15px" }}>
          <PlansMenu />
        </div>
        <div style={{ marginLeft: "15px" }}>
          <ReportsMenu />
        </div>
        <div style={{ marginLeft: "15px" }}>
          <Button
            sx={styles.menuButton}
            startIcon={<GradeIcon />}
            href="/reviews"
          >
            レビュー
          </Button>
        </div>
        <Box sx={styles.rightMenu}>
          <Button
            sx={styles.externalButton}
            href="https://docs.google.com/forms/d/e/1FAIpQLSd4vsjRvz4IDoYyw6aZBZ3nLI8KMQ84arhOPhiIslEUfLlv8A/viewform"
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<EmailIcon />}
          >
            お問い合わせ
          </Button>
          <Button
            sx={styles.externalButton}
            href="https://sites.google.com/luchegroup.com/annyoiwai/%E3%83%9B%E3%83%BC%E3%83%A0"
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<HelpIcon />}
          >
            ご利用方法
          </Button>
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
