import { Dialog, DialogContent, DialogProps, DialogTitle } from "@mui/material";
import { useMemo } from "react";
import { OmitFirstTwoArg, OpenableTimeSlot } from "../../../../../types";
import {
  UseSchedules,
  getAvailableTimeSlots,
  getClosedTimeSlots,
  isDefaultTimeSlot,
  isExistingTimeSlot,
} from "../../../../../useSchedules";
import Form, { FormProps } from "./Form";

export type DayProps = {
  hasTimeSlots: boolean;
  availableTimeSlots: ReturnType<typeof getAvailableTimeSlots>;
  closedTimeSlots: ReturnType<typeof getClosedTimeSlots>;
  allTimeSlotsAreClosed: boolean;
  addTimeSlot: OmitFirstTwoArg<UseSchedules["addTimeSlot"]>;
  toggleTimeSlot: OmitFirstTwoArg<UseSchedules["toggleTimeSlot"]>;
  isExistingTimeSlot: OmitFirstTwoArg<typeof isExistingTimeSlot>;
  toggleAllTimeSlots: OmitFirstTwoArg<UseSchedules["toggleAllTimeSlots"]>;
  isDefaultTimeSlot: OmitFirstTwoArg<typeof isDefaultTimeSlot>;
} & Pick<FormProps, "planId" | "date" | "isCutoff"> &
  DialogProps;

export default function Day({
  date,
  planId,
  availableTimeSlots,
  closedTimeSlots,
  addTimeSlot,
  toggleAllTimeSlots,
  toggleTimeSlot,
  isDefaultTimeSlot,
  allTimeSlotsAreClosed,
  hasTimeSlots,
  isExistingTimeSlot,
  isCutoff,
  ...dialogProps
}: DayProps) {
  const [extraTimeSlots, defaultTimeSlots] = useMemo(
    () =>
      [
        ...[...availableTimeSlots].map((timeSlot) => ({
          timeSlot,
          open: true,
        })),
        ...[...closedTimeSlots].map((timeSlot) => ({ timeSlot, open: false })),
      ]
        .reduce(
          (acc, { timeSlot, open }) => {
            const isDefault = isDefaultTimeSlot(timeSlot);
            acc[Number(isDefault)].push({
              timeSlot,
              open,
            });
            return acc;
          },
          [[], []] as [OpenableTimeSlot[], OpenableTimeSlot[]]
        )
        .map((timeSlots) =>
          timeSlots.sort((a, b) => a.timeSlot.localeCompare(b.timeSlot))
        ),
    [availableTimeSlots, closedTimeSlots, isDefaultTimeSlot]
  );

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>{date}</DialogTitle>
      <DialogContent>
        <Form
          planId={planId}
          date={date}
          defaultTimeSlots={defaultTimeSlots}
          extraTimeSlots={extraTimeSlots}
          isCutoff={isCutoff}
          toggleTimeSlot={toggleTimeSlot}
          allTimeSlotsAreClosed={allTimeSlotsAreClosed}
          hasTimeSlots={hasTimeSlots}
          toggleAllTimeSlots={toggleAllTimeSlots}
          addTimeSlot={addTimeSlot}
          isExistingTimeSlot={isExistingTimeSlot}
        />
      </DialogContent>
    </Dialog>
  );
}
