import {
  Checkbox,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  DialogContent,
  DialogProps,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { UseSchedules, isClosedHoliday } from "../../../../useSchedules";
import { OmitFirstArg } from "../../../../types";
import { holidays } from "../../../../helpers";

export function HolidaysModalButton(props: HolidaysModalButtonProps) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <IconButton onClick={() => setOpen(true)}>
        <SettingsIcon />
      </IconButton>
      <HolidaysModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        {...props}
      />
    </div>
  );
}

function HolidaysModal({
  isClosedHoliday,
  toggleHoliday,
  open,
  onClose,
  ...dialogProps
}: HolidaysModalProps) {
  return (
    <Dialog open={open} onClose={onClose} {...dialogProps}>
      <DialogTitle>休日のカレンダー設定</DialogTitle>
      <DialogContent sx={{ padding: "1rem" }} dividers>
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            {holidays.map((holiday) => (
              <FormControlLabel
                key={holiday}
                control={
                  <Checkbox
                    checked={isClosedHoliday(holiday)}
                    onChange={() => {
                      toggleHoliday(holiday);
                    }}
                    name={holiday}
                  />
                }
                label={`${holiday}を全て売り止めする`}
              />
            ))}
          </FormGroup>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
}

type HolidaysModalButtonProps = {
  isClosedHoliday: OmitFirstArg<typeof isClosedHoliday>;
  toggleHoliday: OmitFirstArg<UseSchedules["toggleHoliday"]>;
};

type HolidaysModalProps = HolidaysModalButtonProps & DialogProps;
