import { default as MuiCard } from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import type { CardProps as MuiCardProps } from "@mui/material/Card";

export default function Card(props: CardProps) {
  return (
    <MuiCard variant="outlined" {...props}>
      {props.title && (
        <>
          <CardHeader
            titleTypographyProps={{ fontSize: "16px", fontWeight: 700 }}
            title={props.title}
            action={props.headerelements ? props.headerelements : undefined}
          />
          <Divider />
        </>
      )}
      <CardContent
        sx={{ padding: "16px", ":last-child": { paddingBottom: "16px" } }}
      >
        {props.children}
      </CardContent>
    </MuiCard>
  );
}

interface CardProps extends MuiCardProps {
  title?: string;
  headerelements?: JSX.Element;
  children?: any;
}
