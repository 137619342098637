import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { VenueIdAndName } from "./SortablePlanTable";
import "./VenueMenu.css";

export default function VenueMenu({ venues, venue, setVenue }: VenueMenuProps) {
  return (
    <div>
      <FormControl>
        <InputLabel>店舗で絞る</InputLabel>
        <Select
          size="small"
          label="店舗で絞る"
          value={venue.name}
          onChange={(e) => {
            const venueName = e.target.value;
            setVenue(venues.find((venue) => venue.name === venueName));
          }}
        >
          {venues.map((venue, i) => (
            <MenuItem key={i} value={venue.name}>
              {venue.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

interface VenueMenuProps {
  venues: VenueIdAndName[];
  venue: VenueIdAndName;
  setVenue: any;
}
