import { Checkbox, CheckboxProps, SvgIconProps } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";

export type CheckboxIconProps = React.PropsWithChildren<{}> & SvgIconProps;

const CheckboxIcon = ({ children, ...iconProps }: CheckboxIconProps) => (
  <>
    <CheckBoxOutlineBlankIcon />
    {children && (
      <div style={{ position: "absolute", top: "9px" }}>{children}</div>
    )}
  </>
);

type CrossCheckboxProps = CheckboxProps;

export const CrossCheckbox = (props: CrossCheckboxProps) => (
  <Checkbox
    sx={{
      "&.Mui-checked": {
        color: "rgba(0, 0, 0, 0.6)",
      },
      "&.Mui-disabled": {
        color: "gray",
      },
    }}
    {...props}
    icon={<CheckboxIcon />}
    checkedIcon={
      <CheckboxIcon>
        <CloseIcon sx={{ color: props.disabled ? "gray" : "red" }} />
      </CheckboxIcon>
    }
  />
);

export default CheckboxIcon;
