import { useContext } from "react";
import {
  FieldValues,
  UseFieldArrayReturn,
  useFieldArray,
} from "react-hook-form";
import { ActivitiesField } from "../types";
import { FormContext } from "../../components/FormContext";
import { getArrayFieldValue, setArrayFieldValue } from "../../formValues";
import CoursePhotoCard from "./CoursePhotoCard";
import AddCoursePhotoButton from "./AddCoursePhotoButton";

export default function CoursePhotoList({
  index,
  planId,
}: CoursePhotoListProps) {
  const { form } = useContext(FormContext);
  const fieldArray = useFieldArray({
    name: `${ActivitiesField.Course}.${index}.${ActivitiesField.CoursePhotos}`,
    control: form.control,
  });

  const { append, fields, remove } = fieldArray;

  const removeItem = (itemIndex: number) => {
    form.addRemovedItem({
      fieldName: `${ActivitiesField.Course}.${index}.${ActivitiesField.CoursePhotos}`,
      fieldId: getArrayFieldValue({
        form,
        arrayName: `${ActivitiesField.Course}.${index}.${ActivitiesField.CoursePhotos}`,
        itemIndex,
        fieldName: `${ActivitiesField.Course}.${index}.${ActivitiesField.CoursePhotoId}`,
      }),
    });
    remove(itemIndex);
  };

  return (
    <>
      <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
        {fields.map((photo, i) => {
          return (
            <CoursePhotoCard
              index={index}
              planId={planId}
              remove={() => removeItem(i)}
              key={photo.id}
              photoId={photo.id}
              itemIndex={i}
              setImageUrl={(imageUrl: string | null) => {
                setArrayFieldValue({
                  form,
                  arrayName: `${ActivitiesField.Course}.${index}.${ActivitiesField.CoursePhotos}`,
                  itemIndex: i,
                  fieldName: ActivitiesField.CoursePhotoSrc,
                  value: imageUrl,
                });
              }}
              formIdentifier={`${ActivitiesField.Course}.${index}.${ActivitiesField.CoursePhotos}.${i}`}
              setDeleted={(isDeleted: boolean) =>
                setArrayFieldValue({
                  form,
                  arrayName: `${ActivitiesField.Course}.${index}.${ActivitiesField.CoursePhotos}`,
                  itemIndex: i,
                  fieldName: ActivitiesField.CoursePhotoIsDeleted,
                  value: isDeleted,
                })
              }
            />
          );
        })}
      </div>
      <AddCoursePhotoButton
        append={append}
        fields={fields}
        form={form}
        index={index}
      />
    </>
  );
}

interface CoursePhotoListProps {
  planId: string;
  index: number;
}

export const generatePhotoUrl = (planId: string, photoId: string) =>
  `plans/${planId}/course_photos/${photoId}`;
