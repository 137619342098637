import { TextField } from "@mui/material";
import { Control, Controller, FieldValues } from "react-hook-form";

interface ControlledTextFieldProps {
  control: Control<FieldValues, any>;
  name: string;
  label: string;
  minLength?: number;
  maxLength?: number;
  [key: string]: unknown;
}

export default function ControlledTextField({
  control,
  name,
  label,
  maxLength,
  ...rest
}: ControlledTextFieldProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref } }) => (
        <TextField
          inputProps={{ maxLength: maxLength ?? 2500 }}
          onChange={onChange}
          value={value}
          label={label}
          {...rest}
        />
      )}
    />
  );
}
