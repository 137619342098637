import { gql, useQuery } from "@apollo/client";
import { startOfMonth, endOfMonth } from "date-fns";
import { useMemo, useRef } from "react";
import { PlanAvailability } from "../../../../../types";
import { dayAbrToDay, formatDate, Holiday, holidays } from "../helpers";
import { extractDateAndTime } from "../helpers";

export type DateSchedule = {
  state: "default" | "open" | "closed" | "locked";
  openTimeSlots: Set<string>;
  closedTimeSlots: Set<string>;
};

export const getBlankDateSchedule = () =>
({
  state: "default",
  openTimeSlots: new Set(),
  closedTimeSlots: new Set(),
} as DateSchedule);

export const isBlankDateSchedule = (schedule: DateSchedule) =>
  schedule.state === "default" &&
  schedule.openTimeSlots.size === 0 &&
  schedule.closedTimeSlots.size === 0;

export type SchedulePerDate = {
  [date: string]: DateSchedule;
};
export type PlanSchedule = {
  planId: string;
  cutoffTimeHours: number;
  bookablePeriodMonths: number;
  closedHolidays: Set<Holiday>;
  defaultTimeSlots: { [day: string]: Set<string> };
  dates: SchedulePerDate;
};

export type SchedulePerPlan = {
  [planId: string]: PlanSchedule;
};

export type VenueSchedule = {
  venueId: string;
  plans: SchedulePerPlan;
};

export type UseFetchSchedulesProps = {
  venueId: string;
  month: Date;
};
export default function useFetchSchedules({
  venueId,
  month,
}: UseFetchSchedulesProps) {
  const { data, loading, error: loadError } = useQuery<GetScheduleResponse>(
    GET_SCHEDULE,
    {
      variables: {
        venueId,
        from: formatDate(startOfMonth(month)),
        until: formatDate(endOfMonth(month)),
      },
      fetchPolicy: "no-cache",
    }
  );

  const schedulesRef = useRef<VenueSchedule>({ venueId: "", plans: {} });

  holidays.filter((holiday) => holiday);
  const schedules = useMemo(() => {
    if (
      data !== undefined &&
      (schedulesRef.current.venueId !== data.venueAvailability.venueId ||
        !Object.keys(schedulesRef.current).length)
    ) {
      schedulesRef.current = {
        venueId: data.venueAvailability.venueId,
        plans: data.venueAvailability.plans.reduce((acc, plan) => {
          acc[plan.planId] = {
            planId: plan.planId,
            bookablePeriodMonths: plan.schedule.bookablePeriodMonths,
            cutoffTimeHours: plan.schedule.cutoffTimeHours,
            defaultTimeSlots: plan.schedule.days.reduce(
              (acc, { dayOfTheWeek, holiday, timeSlots }) => {
                if (dayOfTheWeek)
                  acc[dayAbrToDay.get(dayOfTheWeek)!] = new Set(timeSlots);
                else if (holiday) acc[holiday] = new Set(timeSlots);
                return acc;
              },
              {} as PlanSchedule["defaultTimeSlots"]
            ),
            closedHolidays: new Set(plan.schedule.closedHolidays as Holiday[]),
            dates: [
              "openDates",
              "closedDates",
              "openTimeSlots",
              "closedTimeSlots",
            ].reduce((acc, key) => {
              if (key === "openDates") {
                plan.schedule.openDates.forEach((date) => {
                  if (!acc.hasOwnProperty(date))
                    acc[date] = getBlankDateSchedule();
                  acc[date].state = "open";
                });
              }
              if (key === "closedDates") {
                plan.schedule.closedDates.forEach(({ locked, moment }) => {
                  if (!acc.hasOwnProperty(moment))
                    acc[moment] = getBlankDateSchedule();
                  acc[moment].state = locked ? "locked" : "closed";
                });
              }
              if (key === "openTimeSlots") {
                plan.schedule.openTimeSlots.forEach((datetime) => {
                  const [date, time] = extractDateAndTime(datetime);
                  if (!acc.hasOwnProperty(date))
                    acc[date] = getBlankDateSchedule();
                  acc[date].openTimeSlots.add(time);
                });
              }
              if (key === "closedTimeSlots") {
                plan.schedule.closedTimeSlots.forEach((datetime) => {
                  const [date, time] = extractDateAndTime(datetime);
                  if (!acc.hasOwnProperty(date))
                    acc[date] = getBlankDateSchedule();
                  acc[date].closedTimeSlots.add(time);
                });
              }
              return acc;
            }, {} as SchedulePerDate),
          };
          return acc;
        }, {} as SchedulePerPlan),
      };
    }

    return schedulesRef.current;
  }, [data]);

  const plans = useMemo(() => data?.venueAvailability.plans ?? [], [data]);

  const numBookings = useMemo(
    () =>
      plans.reduce((planAcc, plan) => {
        planAcc[plan.planId] = plan.availability.reduce(
          (availabilityAcc, availability) => {
            availabilityAcc[availability.date] = availability.numBookings;
            return availabilityAcc;
          },
          {} as { [date: string]: number }
        );
        return planAcc;
      }, {} as { [planId: string]: { [date: string]: number } }),
    [plans]
  );

  const numApproval = useMemo(
    () =>
      plans.reduce((planAcc, plan) => {
        planAcc[plan.planId] = plan.availability.reduce(
          (availabilityAcc, availability) => {
            availabilityAcc[availability.date] = availability.numApproval;
            return availabilityAcc;
          },
          {} as { [date: string]: number }
        );
        return planAcc;
      }, {} as { [planId: string]: { [date: string]: number } }),
    [plans]
  );

  const numTotalBookings = useMemo(
    () =>
      plans.reduce((planAcc, plan) => {
        planAcc[plan.planId] = plan.availability.reduce(
          (availabilityAcc, availability) => {
            availabilityAcc[availability.date] = availability.numTotalBookings;
            return availabilityAcc;
          },
          {} as { [date: string]: number }
        );
        return planAcc;
      }, {} as { [planId: string]: { [date: string]: number } }),
    [plans]
  );


  const numApprovalPrevMonth = useMemo(
    () =>
      plans.reduce((planAcc, plan) => {
        planAcc[plan.planId] = plan.availabilityPrevMonth.reduce(
          (availabilityAcc, availability) => {
            availabilityAcc[availability.date] = availability.numBookings;
            return availabilityAcc;
          },
          {} as { [date: string]: number }
        );
        return planAcc;
      }, {} as { [planId: string]: { [date: string]: number } }),
    [plans]
  );

  const numTotalBookingsPrevMonth = useMemo(
    () =>
      plans.reduce((planAcc, plan) => {
        planAcc[plan.planId] = plan.availabilityPrevMonth.reduce(
          (availabilityAcc, availability) => {
            availabilityAcc[availability.date] = availability.numTotalBookings;
            return availabilityAcc;
          },
          {} as { [date: string]: number }
        );
        return planAcc;
      }, {} as { [planId: string]: { [date: string]: number } }),
    [plans]
  );

  return {
    plans,
    numBookings,
    numApproval,
    numApprovalPrevMonth,
    numTotalBookings,
    numTotalBookingsPrevMonth,
    schedules,
    loadError,
    loading,
  };
}

type GetScheduleResponse = {
  venueAvailability: {
    venueId: string;
    venueName: string;
    plans: PlanAvailability[];
  };
};
const GET_SCHEDULE = gql`
  query GetAvailability($venueId: ID!, $from: Date!, $until: Date!) {
    venueAvailability(venueId: $venueId, from: $from, until: $until) {
      venueId
      venueName
      plans {
        planId
        planName
        planOrder
        schedule {
          cutoffTimeHours
          bookablePeriodMonths
          closedDates {
            moment
            locked
          }
          closedTimeSlots
          closedHolidays
          openDates
          openTimeSlots
          days {
            holiday
            dayOfTheWeek
            timeSlots
          }
        }
        priceTypes {
          id
          name
          amount
          currencyCode
        }
        seatOptions {
          id
          title
        }
        availability {
          date
          numBookings
          numApproval
          numTotalBookings
        }
        availabilityPrevMonth {
          date
          numBookings
          numTotalBookings
        }
      }
    }
  }
`;
