import { gql, useQuery } from "@apollo/client";
import {
  Box,
  Modal,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import reservationStatusColors from "../../../../../../../../helpers/booking-status-colors";
import reservationStatusTranslations from "../../../../../../../../helpers/booking-status-translations";

interface BookingsModalProps {
  planId: string;
  day: string;
  open: boolean;
  onClose: () => void;
}

export default function BookingsModal(props: BookingsModalProps) {
  const { loading, error, data } = useQuery<QueryResponse>(GET_BOOKINGS, {
    variables: {
      planId: props.planId,
      reservationDate: props.day,
      status: ["NOT_YET_RESERVED", "RESERVED"],
      offset: 0,
    },
  });

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box sx={style}>
        {loading && <>Loading...</>}
        {error && <>Error</>}
        {!loading && !error && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>予約ID</TableCell>
                <TableCell>予約受入状況</TableCell>
                <TableCell>時間</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.reservations.records.map((r, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <a
                      style={linkStyle}
                      href={`/bookings/${r.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {r.booking.id}
                    </a>
                  </TableCell>
                  <TableCell
                    style={{ color: reservationStatusColors[r.status] }}
                  >
                    {reservationStatusTranslations[r.status]}
                  </TableCell>
                  <TableCell>{r.datetime.slice(11, 16)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
    </Modal>
  );
}

const GET_BOOKINGS = gql`
  query Reservations(
    $planId: ID
    $reservationDate: String
    $status: [ReservationStatus]
    $offset: Int!
  ) {
    reservations(
      input: {
        planId: $planId
        datetime: { from: $reservationDate, until: $reservationDate }
        status: $status
        offset: $offset
      }
    ) {
      totalRecords
      records {
        id
        booking {
          id
        }
        status
        datetime
      }
    }
  }
`;

interface QueryResponse {
  reservations: {
    totalRecords: number;
    records: {
      id: string;
      booking: {
        id: string;
      };
      status: "NOT_YET_RESERVED" | "RESERVED";
      datetime: string;
    }[];
  };
}

const style: SxProps = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: "5px",
  border: "1px solid black",
  p: 4,
  textAlign: "center",
  outline: "none",
};

const linkStyle: React.CSSProperties = {
  color: "black",
  textDecoration: "none",
};
