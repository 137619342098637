import { useParams } from "react-router-dom";
import { LinearProgress, Paper } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import EditorSidebar from "./Editor/EditorSidebar";
import EditorHeader from "./Editor/EditorHeader";
import { gql, useMutation, useQuery } from "@apollo/client";
import basicInfoEditor from "./BasicInfo";
import highlightsEditor from "./Highlights";
import activitiesEditor from "./Activities";
import faqsEditor from "./FAQs";
import paidOptionEditor from "./PaidOption";
import { FieldValues, useForm, UseFormReturn } from "react-hook-form";
import { FormProvider } from "./components/FormContext";
import {
  getArrayFieldValue,
  getArrayItemState,
  getArrayItemValue,
  getFieldState,
  getFieldValue,
} from "./formValues";
import { useNotifications } from "../../../components/Notification";
import { FieldState, Form, FieldItem, ObjectWithFieldId } from "./types";
import { Plan } from "../List";
import "./Editor.css";
import { ActivitiesField } from "./Activities/types";

function useFormWithOriginalValues(planId: string) {
  const form = useForm();
  const [originalValues, setOriginalValues] = useState<any>({});
  const [removedItems, setRemovedItems] = useState<any[]>([]);
  const addRemovedItem = (item: FieldItem) => {
    if (
      // only keep track of the removed item if it belonged to the default items
      form.formState.defaultValues![item.fieldName]?.some(
        (obj: ObjectWithFieldId) => obj.fieldId === item.fieldId
      )
    )
      setRemovedItems((removedItems) => [...removedItems, item]);
  };
  const resetRemovedItems = () => setRemovedItems([]);

  return {
    ...form,
    planId,
    originalValues,
    setOriginalValues,
    removedItems,
    addRemovedItem,
    resetRemovedItems,
  } as Form;
}

export default function PlanEditor() {
  const { showNotification } = useNotifications();
  const { id: paramId } = useParams<{ id: string }>();
  const planId = paramId as string;
  const [currentTab, setCurrentTab] = useState(0);
  const { data: planData, loading: planLoading, error: planError } = useQuery<
    getPlanResponse,
    getPlanInput
  >(GET_PLAN, {
    variables: {
      planId,
    },
  });
  const { data: editData, loading: editLoading, error: editError } = useQuery<
    getPartnerEditsResponse,
    getPartnerEditsInput
  >(GET_PARTNER_EDITS, {
    variables: {
      planId,
    },
  });

  const loading = planLoading || editLoading;
  const errors = [planError, editError];

  const form = useFormWithOriginalValues(planId ?? "");

  const [saveEdits, { loading: saveLoading }] = useMutation<
    saveEditsResponse,
    saveEditsInput
  >(SAVE_EDITS);

  const handleSave = async () => {
    const dirtyFields = form.formState.dirtyFields;

    const edits = Object.entries(dirtyFields)
      .flatMap(([fieldName, dirty]) => {
        if (Array.isArray(dirty)) {
          return Object.entries(dirty)
            .filter(([_, obj]) => Object.keys(obj).length > 0 || obj === true)
            .map(([i, _]) => {
              const itemIndex = Number(i);
              return {
                fieldName,
                fieldId: getArrayFieldValue({
                  form,
                  arrayName: fieldName,
                  itemIndex,
                  fieldName: "fieldId",
                }),
                newValue: JSON.stringify(
                  getArrayItemValue({
                    form,
                    arrayName: fieldName,
                    itemIndex,
                  })
                ),
                status: getArrayItemState({
                  form,
                  arrayName: fieldName,
                  itemIndex,
                }),
              };
            });
        }

        return {
          fieldName,
          newValue: JSON.stringify({
            text: getFieldValue({ form, fieldName }),
          }),
          status: getFieldState({ form, fieldName }),
        };
      })
      .filter((x) => x.newValue !== undefined);

    const removedItems = form.removedItems.map(({ fieldId, fieldName }) => {
      return {
        fieldId,
        fieldName,
        newValue: JSON.stringify(""),
        status: FieldState.removed,
      };
    });

    let isCheckEmptyPhoto = false;
    for (const course of form.getValues().course) {
      const numCoursePhotosRemaining = course[
        ActivitiesField.CoursePhotos
      ].filter((state: any) => !state.isDeleted).length;
      if (numCoursePhotosRemaining == 0) {
        isCheckEmptyPhoto = true;
        break;
      }
    }

    if (isCheckEmptyPhoto) {
      showNotification({
        message: "料理写真を1枚以上保存してください。",
        severity: "error",
      });
      return;
    }

    let checkPaidPrice = false;
    let checkPaidRequire = false;
    const planPaidItems = form.getValues().planPaidItems;
    for (const item of planPaidItems) {
      if (!item.name || !item.price || !item.description || !item.imageUrl) {
        checkPaidRequire = true;
        break;
      }
      if (!/^(0|[1-9]\d*)$/.test(item.price)) {
        checkPaidPrice = true;
        break;
      }
    }
    if (checkPaidRequire) {
      showNotification({
        message: "有料オプションの情報をすべてご入力ください。",
        severity: "error",
      });
      return;
    }

    if (checkPaidPrice) {
      showNotification({
        message: "有料アイテムのオプション価格が無効です。",
        severity: "error",
      });
      return;
    }

    saveEdits({
      variables: {
        input: {
          planId,
          edits: [...edits, ...removedItems],
        },
      },
    })
      .then((res) => {
        if (res.data?.savePartnerEdits.success === false) {
          throw new Error(res.data.savePartnerEdits.error);
        }
        form.reset(form.getValues());
        form.resetRemovedItems();

        showNotification({
          message: "編集内容が保存されました",
          severity: "success",
        });
      })
      .catch((err) => {
        const message =
          (err as Error).message === "empty edits"
            ? "未入力箇所があるため保存できません"
            : "編集内容が正常に保存されませんでした。再度お試しください。";

        showNotification({
          message,
          severity: "error",
        });
      });
  };

  const plan = useMemo(() => {
    if (planData === undefined) return undefined;
    return planData.plan;
  }, [planData]);
  const venueName = useMemo(() => {
    if (planData === undefined) return "";
    return planData.plan.activities[0].venue.name;
  }, [planData]);

  const editors = [
    basicInfoEditor,
    highlightsEditor,
    activitiesEditor,
    faqsEditor,
    paidOptionEditor,
  ];

  useEffect(() => {
    if (planData !== undefined && editData !== undefined) {
      // get the original plan data
      const originalValues = editors.reduce((acc, editor) => {
        return {
          ...acc,
          ...editor.getDefaultValues(plan!),
        };
      }, {});

      form.setOriginalValues(JSON.parse(JSON.stringify(originalValues)));

      // overlay partner edit data over existing data
      const defaultValues = editData.partnerEdits.reduce(
        (acc: { [key: string]: any[] }, edit: ObjectWithFieldId) => {
          if (edit.fieldId) {
            const array = acc[edit.fieldName];
            if (!array) return { ...acc };
            const index = array?.findIndex(
              (obj: FieldItem) => obj.fieldId === edit.fieldId
            );
            const newValue = JSON.parse(edit.newValue);

            if (index === -1) {
              // if field id does not yet exist, it is new
              if (edit.editStatus === FieldState.removed) return acc;
              array.push(newValue);
            } else array[index] = newValue;

            // else if the field id does exist, override the previous value
            return {
              ...acc,
              ...{ [edit.fieldName]: array },
            };
          }

          return {
            ...acc,
            ...{
              [edit.fieldName]: JSON.parse(edit.newValue)["text"],
            },
          };
        },
        { ...originalValues }
      );
      form.reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planData, editData]);

  const EditorForm = editors.map((editor) => editor.form)[currentTab];

  errors.forEach((error) => {
    if (error) return <div>不具合が発生しました</div>;
  });

  return (
    <FormProvider form={form} planData={plan}>
      <Paper className="EditorPaper" sx={{ width: "100%", mb: 2 }}>
        <div className="Editor">
          <EditorSidebar
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          <div className="EditorContent">
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                <EditorHeader
                  planName={plan?.name ?? ""}
                  venueName={venueName}
                  handleSave={handleSave}
                  saveLoading={saveLoading}
                />

                <form>
                  <EditorForm />
                </form>
              </>
            )}
          </div>
        </div>
      </Paper>
    </FormProvider>
  );
}

const GET_PLAN = gql`
  query GetPlanQuery($planId: ID!) {
    plan(id: $planId) {
      name
      inclusions {
        category
        items {
          id
          title
          description
          iconName
        }
      }
      faqs {
        id
        priority
        imageUrl
        question
        answer
      }
      planPaidItems {
        id
        status
        imageUrl
        name
        price
        description
      }
      activities {
        id
        name
        venue {
          name
        }
        coursePhotos {
          id
          photoIndex
          src
        }
        json {
          json {
            name
            courseMenu
          }
        }
      }
      json {
        courseMenu
        highlights {
          index
          title
          details
          exclusive
          media {
            src
            type
          }
        }
        subtitle
        description
        remarks
      }
    }
  }
`;

type getPlanInput = {
  planId: string;
};

type getPlanResponse = {
  plan: Plan;
};

const GET_PARTNER_EDITS = gql`
  query PartnerEdits($planId: ID!) {
    partnerEdits(planId: $planId) {
      fieldId
      fieldName
      newValue
      editStatus
    }
  }
`;

type getPartnerEditsInput = {
  planId: string;
};

type getPartnerEditsResponse = {
  partnerEdits: {
    fieldId: string;
    fieldName: string;
    newValue: unknown;
    editStatus: string;
  }[];
};

const SAVE_EDITS = gql`
  mutation SavePartnerEdits($input: SavePartnerEditsInput!) {
    savePartnerEdits(input: $input) {
      success
      error
    }
  }
`;

type saveEditsInput = {
  input: {
    planId: string;
    edits: {
      fieldId?: string;
      fieldName: string;
      newValue: string;
      status: string;
    }[];
  };
};

type saveEditsResponse = {
  savePartnerEdits: {
    success: boolean;
    error?: string;
  };
};

export type FormContext = UseFormReturn<FieldValues, any>;
