import { Box, Divider, Stack } from "@mui/material";
import { OmitFirstTwoArg, OpenableTimeSlot } from "../../../../../types";
import {
  isCutoff,
  isExistingTimeSlot,
  UseSchedules,
} from "../../../../../useSchedules";
import Actions from "./Actions";
import TimeSlot from "./TimeSlot";

export type FormProps = {
  planId: string;
  date: string;
  addTimeSlot: OmitFirstTwoArg<UseSchedules["addTimeSlot"]>;
  hasTimeSlots: boolean;
  allTimeSlotsAreClosed: boolean;
  isExistingTimeSlot: OmitFirstTwoArg<typeof isExistingTimeSlot>;
  toggleAllTimeSlots: OmitFirstTwoArg<UseSchedules["toggleAllTimeSlots"]>;
  toggleTimeSlot: OmitFirstTwoArg<UseSchedules["toggleTimeSlot"]>;
  isCutoff: OmitFirstTwoArg<typeof isCutoff>;
  defaultTimeSlots: OpenableTimeSlot[];
  extraTimeSlots: OpenableTimeSlot[];
};

export default function Form({
  date,
  addTimeSlot,
  toggleAllTimeSlots,
  toggleTimeSlot,
  defaultTimeSlots,
  extraTimeSlots,
  allTimeSlotsAreClosed,
  isCutoff,
  hasTimeSlots,
  isExistingTimeSlot,
}: FormProps) {
  if (!date) return <></>;

  return (
    <Stack spacing={2}>
      {!(defaultTimeSlots.length + extraTimeSlots.length) && (
        <Box sx={{ textAlign: "center" }}>
          この日にはプラン販売設定されていません
        </Box>
      )}
      <TimeSlots
        timeSlots={defaultTimeSlots}
        isCutoffTimeSlot={isCutoff}
        toggleTimeSlot={toggleTimeSlot}
      />
      <Divider />
      <ExtraTimeSlots
        timeSlots={extraTimeSlots}
        isCutoffTimeSlot={isCutoff}
        toggleTimeSlot={toggleTimeSlot}
      />
      <Actions
        toggleAllTimeSlots={toggleAllTimeSlots}
        allTimeSlotsAreClosed={allTimeSlotsAreClosed}
        hasTimeSlots={hasTimeSlots}
        isExistingTimeSlot={isExistingTimeSlot}
        isCutoff={isCutoff}
        addTimeSlot={addTimeSlot}
        date={date}
      />
    </Stack>
  );
}

const ExtraTimeSlots = (props: {
  timeSlots: OpenableTimeSlot[];
  toggleTimeSlot: OmitFirstTwoArg<UseSchedules["toggleTimeSlot"]>;
  isCutoffTimeSlot: OmitFirstTwoArg<typeof isCutoff>;
}) => {
  if (props.timeSlots.length === 0) return <></>;

  return (
    <Stack spacing={1}>
      <div style={{ textAlign: "center", fontWeight: "bold" }}>
        新規追加予約スロット
      </div>
      <TimeSlots {...props} />
    </Stack>
  );
};

const TimeSlots = ({
  timeSlots,
  toggleTimeSlot,
  isCutoffTimeSlot,
}: {
  timeSlots: OpenableTimeSlot[];
  toggleTimeSlot: OmitFirstTwoArg<UseSchedules["toggleTimeSlot"]>;
  isCutoffTimeSlot: OmitFirstTwoArg<typeof isCutoff>;
}) => (
  <Stack spacing={1}>
    {timeSlots.map(({ timeSlot, open }) => (
      <TimeSlot
        key={timeSlot}
        open={open}
        timeSlot={timeSlot}
        isCutoff={isCutoffTimeSlot(timeSlot)}
        toggleTimeSlot={toggleTimeSlot}
      />
    ))}
  </Stack>
);
