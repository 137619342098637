import React from "react";

export type GlobalContextValue = {
  isLoggedIn: boolean;
  setIsLoggedIn: (value: boolean) => void;
};

export const GlobalContext = React.createContext<GlobalContextValue>({
  isLoggedIn: false,
  setIsLoggedIn: () => {},
});

export function GlobalContextProvider({ children }: any) {
  const [isLoggedIn, setIsLoggedIn] = React.useState(
    localStorage.getItem("user") ? true : false
  );

  return (
    <GlobalContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export function useGlobalContext() {
  return React.useContext(GlobalContext);
}
