import { FieldState, Form, ObjectWithFieldId } from "./types";

interface GetFieldProps {
  form: Form;
  fieldName: string;
}

type GetArrayItemProps = {
  form: Form;
  arrayName: string;
  itemIndex: number;
  photoIndex?: number;
};

type GetArrayFieldProps = { fieldName?: string } & GetArrayItemProps;

interface SetValueProps {
  form: Form;
  value: unknown;
  options?:
    | Partial<{
        shouldValidate: boolean;
        shouldDirty: boolean;
        shouldTouch: boolean;
      }>
    | undefined;
}

export function createArrayItemName(
  arrayName: string,
  itemIndex: number,
  fieldName?: string
) {
  return `${arrayName}.${itemIndex}` + (fieldName ? `.${fieldName}` : "");
}

function getState(
  currentValue: ObjectWithFieldId | undefined,
  defaultValue: ObjectWithFieldId | undefined
) {
  if (currentValue === undefined || currentValue.isDeleted)
    return FieldState.removed;
  if (defaultValue === undefined) return FieldState.added;
  if (JSON.stringify(currentValue) === JSON.stringify(defaultValue))
    return FieldState.untouched;
  return FieldState.touched;
}

export function getFieldValue({ form, fieldName }: GetFieldProps) {
  return form.watch(fieldName);
}

export function getFieldState({ form, fieldName }: GetFieldProps) {
  const currentValue = getFieldValue({ form, fieldName });
  const defaultValue = getNestedProperty(fieldName, form.originalValues);

  return getState(currentValue, defaultValue);
}

export function getArrayFieldValue({
  form,
  arrayName,
  itemIndex,
  fieldName,
}: GetArrayFieldProps) {
  return getFieldValue({
    form,
    fieldName: createArrayItemName(arrayName, itemIndex, fieldName),
  });
}

export function getArrayFieldState({
  form,
  arrayName,
  itemIndex,
  fieldName,
  photoIndex,
}: GetArrayFieldProps) {
  let defaultValue;
  const currentValue: ObjectWithFieldId = getFieldValue({
    form,
    fieldName: createArrayItemName(arrayName, itemIndex),
  });

  if (photoIndex || photoIndex === 0) {
    defaultValue = form.originalValues.course[photoIndex].coursePhotos?.find(
      (obj: ObjectWithFieldId) => obj.fieldId === currentValue?.fieldId
    );
  } else {
    defaultValue = form.originalValues[arrayName]?.find(
      (obj: ObjectWithFieldId) => obj.fieldId === currentValue?.fieldId
    );
  }

  if (fieldName === undefined) return getState(currentValue, defaultValue);
  return getState(currentValue?.[fieldName], defaultValue?.[fieldName]);
}

export function getArrayItemValue({
  form,
  arrayName,
  itemIndex,
}: GetArrayItemProps) {
  return getArrayFieldValue({ form, arrayName, itemIndex });
}

export function getArrayItemState({
  form,
  arrayName,
  itemIndex,
  photoIndex,
}: GetArrayItemProps) {
  return getArrayFieldState({ form, arrayName, itemIndex, photoIndex });
}

export function setFieldValue({
  form,
  fieldName,
  value,
  options,
}: GetFieldProps & SetValueProps) {
  return form.setValue(fieldName, value, options ?? { shouldDirty: true });
}

export function setArrayFieldValue({
  form,
  arrayName,
  itemIndex,
  fieldName,
  value,
  options,
}: GetArrayFieldProps & SetValueProps) {
  return setFieldValue({
    form,
    fieldName: createArrayItemName(arrayName, itemIndex, fieldName),
    value,
    options,
  });
}

export function setArrayItemValue({
  form,
  arrayName,
  itemIndex,
  value,
  options,
}: GetArrayFieldProps & SetValueProps) {
  return setArrayFieldValue({ form, arrayName, itemIndex, value, options });
}

interface RecursiveObject {
  [key: string | number]: RecursiveObject;
}

function getNestedProperty(path: string, obj?: RecursiveObject) {
  if (obj === undefined) return undefined;
  return path.split(".").reduce<typeof obj>((acc, propertyName) => {
    if (/\d+/.test(propertyName)) {
      const arrayIndex = parseInt(propertyName);
      return acc[arrayIndex];
    } else {
      return acc[propertyName];
    }
  }, obj);
}
