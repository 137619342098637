import { gql, useMutation } from "@apollo/client";
import { Button, LinearProgress, Stack, SxProps } from "@mui/material";

import { VenuePlanSchedulesType, PlanScheduleType } from ".";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import PlanSchedule from "./PlanSchedule";
import { useNotifications } from "../../../../components/Notification";

interface FormData {
  venueId: string;
  data: VenuePlanSchedulesType;
  hasUnsavedChange: boolean;
  setHasUnsavedChange: Dispatch<SetStateAction<boolean>>;
}

export default function PlanSchedules(props: FormData) {
  const { showNotification } = useNotifications();
  const { hasUnsavedChange, setHasUnsavedChange } = props;

  const [saving, setSaving] = useState(false);
  const [plans, setPlans] = useState(props.data.plans);

  const updatePlan = useCallback(
    (newPlan: PlanScheduleType) => {
      setHasUnsavedChange(true);
      setPlans((prevPlans) =>
        prevPlans.map((p) => (p.planId !== newPlan.planId ? p : newPlan))
      );
    },
    [setHasUnsavedChange]
  );
  const [savePlanSchedules] = useMutation(SAVE_PLAN_SCHEDULES);

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          sx={sx.saveButton}
          variant="contained"
          disabled={saving || !hasUnsavedChange}
          onClick={handleSave}
        >
          保存
        </Button>
      </Stack>
      {saving && <LinearProgress />}
      {!saving &&
        plans.map((p) => (
          <PlanSchedule key={p.planId} plan={p} updatePlan={updatePlan} />
        ))}
    </Stack>
  );

  function handleSave() {
    setSaving(true);

    savePlanSchedules({
      variables: {
        venueId: props.venueId,
        planSchedules: plans.map((p) => ({
          planId: p.planId,
          cutoffTimeHours: p.schedule.cutoffTimeHours,
          bookablePeriodMonths: p.schedule.bookablePeriodMonths,
          days: p.schedule.days,
        })),
      },
    })
      .then(() => {
        setSaving(false);
        setHasUnsavedChange(false);
        showNotification({
          message: "Successfully saved",
          severity: "success",
        });
      })
      .catch(() => {
        setSaving(false);
        setHasUnsavedChange(false);
        showNotification({
          message: "Saving failed!",
          severity: "error",
        });
      });
  }
}

const SAVE_PLAN_SCHEDULES = gql`
  mutation SaveVenuePlanSchedules(
    $venueId: ID!
    $planSchedules: [SavePlanScheduleInput!]
  ) {
    saveVenuePlanSchedules(input: { venueId: $venueId, plans: $planSchedules })
  }
`;

const sx: { [id: string]: SxProps } = {
  scheduleDotw: {
    fontSize: "20px",
    fontWeight: "bold",
    width: "100px",
  },
  saveButton: {
    "&:hover": { backgroundColor: "green" },
    backgroundColor: "green",
  },
  addTimeSlotButton: {
    borderColor: "black",
    color: "black",
    "&:hover": {
      borderColor: "black",
      backgroundColor: "#eee",
    },
  },
};
