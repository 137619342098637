import { Divider, MenuItem, MenuList } from "@mui/material";
import React from "react";
import { Field, IdAndName } from "./types";
import "./index.css";

const renderColumnMenu = <T extends React.Key>(
  entities: IdAndName[],
  filter: T | null,
  setFilter: React.Dispatch<React.SetStateAction<T | null>>,
  closeMenu: (event: any) => void
) => (
  <MenuList>
    <MenuItem
      selected={filter == null}
      onClick={(e) => {
        setFilter(null);
        closeMenu(e);
      }}
    >
      全て
    </MenuItem>
    <Divider />
    {entities.map((entity) => {
      return (
        <MenuItem
          key={entity.id}
          selected={entity.id === filter}
          onClick={(e) => {
            setFilter(entity.id as T);
            closeMenu(e);
          }}
        >
          {entity.name}
        </MenuItem>
      );
    })}
  </MenuList>
);

const getColumnMenu = (
  plans: IdAndName[],
  venues: IdAndName[],
  planFilter: string | null,
  venueFilter: string | null,
  ratingFilter: number | null,
  setPlanFilter: React.Dispatch<React.SetStateAction<string | null>>,
  setVenueFilter: React.Dispatch<React.SetStateAction<string | null>>,
  setRatingFilter: React.Dispatch<React.SetStateAction<number | null>>
): React.JSXElementConstructor<any> => {
  return (args: {
    hideMenu: (event: any) => void;
    currentColumn: { field: Field };
  }) => {
    switch (args.currentColumn.field) {
      case "plan":
        return renderColumnMenu(
          plans,
          planFilter,
          setPlanFilter,
          args.hideMenu
        );
      case "venue":
        return renderColumnMenu(
          venues,
          venueFilter,
          setVenueFilter,
          args.hideMenu
        );
      case "rating":
        return renderColumnMenu(
          Array.from({ length: 6 }, (_, i) => {
            return { id: i, name: i.toString() };
          }),
          ratingFilter,
          setRatingFilter,
          args.hideMenu
        );
    }
    return <div>Column menu not found</div>;
  };
};

export default getColumnMenu;
