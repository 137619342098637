import { gql, useMutation } from "@apollo/client";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DateTimePicker from "@mui/lab/DateTimePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import { useState } from "react";
import Button from "@mui/material/Button";
import { useNotifications } from "../Notification";
import { TextField } from "@mui/material";

export default function ChangeDateTimeButton(props: {
  bookingId: string;
  reservationId: string;
  currentReservationDateTime: string;
}) {
  const { showNotification } = useNotifications();
  const [changeReservationDateTime] = useMutation(CHANGE_RESERVATION_DATETIME);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [tempPickerValue, setTempPickerValue] = useState(
    new Date(props.currentReservationDateTime)
  );

  function handleChangeReservationDateTime() {
    changeReservationDateTime({
      variables: {
        bookingId: props.bookingId,
        reservationId: props.reservationId,
        newReservationDateTime: format(tempPickerValue, "yyyy-MM-dd HH:mm"),
      },
      refetchQueries: ["GetBookingQuery"],
    })
      .then(() => {
        showNotification({
          message: `予約日時が変更されました → ${props.currentReservationDateTime}。 確認メールも再度送信されました。`,
          severity: "success",
        });
        setDialogOpen(false);
      })
      .catch(() => {
        showNotification({
          message: `予約日時の変更に失敗しました。`,
          severity: "error",
        });
      });
  }

  return (
    <>
      <Button
        sx={{
          color: "#ffffff",
          backgroundColor: "#c8a063",
          margin: "0.3em",
          "&:hover": {
            backgroundColor: "#a37939",
          },
        }}
        variant="contained"
        onClick={() => {
          setDialogOpen(true);
        }}
      >
        日時変更
      </Button>
      <ChangeDateTimeDialog
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        value={tempPickerValue}
        setValue={(newDateTime: Date) => {
          setTempPickerValue(newDateTime);
        }}
        changeReservationDateTime={handleChangeReservationDateTime}
      />
    </>
  );
}

function ChangeDateTimeDialog(pickerProps: {
  open: boolean;
  closeDialog: () => void;
  value: Date;
  setValue: (newDateTime: Date) => void;
  changeReservationDateTime: () => void;
}) {
  return (
    <Dialog open={pickerProps.open} onClose={pickerProps.closeDialog}>
      <DialogTitle>予約日時を変更する</DialogTitle>
      <DialogContent>
        予約日時を変更すると、予約ステータスが[確定]に変更されます。
        <br />
        また、ゲストにも予約確定メールが自動送信されます。
        <br />
        <br />
        <LocalizationProvider locale={ja} dateAdapter={AdapterDateFns}>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="新しい予約日時"
            minDate={new Date()}
            leftArrowButtonText="前月"
            rightArrowButtonText="来月"
            inputFormat="yyyy-MM-dd HH:mm"
            onError={console.error}
            mask="____-__-__ __:__"
            ampm={false}
            value={pickerProps.value}
            onChange={(newValue) => {
              pickerProps.setValue(new Date(newValue!));
            }}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            pickerProps.closeDialog();
          }}
        >
          キャンセル
        </Button>
        <Button
          color="warning"
          onClick={() => pickerProps.changeReservationDateTime()}
        >
          変更
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const CHANGE_RESERVATION_DATETIME = gql`
  mutation(
    $bookingId: ID!
    $reservationId: ID!
    $newReservationDateTime: String!
  ) {
    changeBookingReservationDateTime(
      input: {
        bookingId: $bookingId
        reservationId: $reservationId
        newReservationDateTime: $newReservationDateTime
      }
    ) {
      success
    }
  }
`;
