import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import "./DashboardItem.css";

export default function DashboardItem({
  header,
  HeaderIcon,
  loading,
  children,
}: PropsWithChildren<DashBoardItemProps>) {
  return (
    <Grid item xs={12} md={6}>
      <Paper className="DashboardItemPaper" elevation={3}>
        <div className="DashboardItemHeader">
          {HeaderIcon}
          {header && <Typography>{header}</Typography>}
        </div>
        {(loading && (
          <Skeleton className="DashboardItemSkeleton" variant="rectangular" />
        )) ||
          children}
      </Paper>
    </Grid>
  );
}

interface DashBoardItemProps {
  header?: string;
  HeaderIcon?: JSX.Element;
  loading: boolean;
}
