import { gql, useQuery } from "@apollo/client";
import { LinearProgress } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import PlanSchedules from "./PlanSchedules";

interface VenuePlanSchedulesArgs {
  venueId: string;
  hasUnsavedChange: boolean;
  setHasUnsavedChange: Dispatch<SetStateAction<boolean>>;
}

export default function VenuePlanSchedules(props: VenuePlanSchedulesArgs) {
  const { loading, error, data } = useQuery<queryResponse>(GET_SCHEDULES, {
    variables: { venueId: props.venueId },
    fetchPolicy: "no-cache",
  });
  if (error) return <>Error! {error.message}</>;
  if (loading || !data) return <LinearProgress />;

  return (
    <PlanSchedules
      venueId={props.venueId}
      data={data.venuePlanSchedules}
      hasUnsavedChange={props.hasUnsavedChange}
      setHasUnsavedChange={props.setHasUnsavedChange}
    />
  );
}

const GET_SCHEDULES = gql`
  query GetVenuePlanSchedules($venueId: ID!) {
    venuePlanSchedules(venueId: $venueId) {
      plans {
        planId
        planName
        schedule {
          cutoffTimeHours
          bookablePeriodMonths
          days {
            dayOfTheWeek
            holiday
            timeSlots
          }
        }
      }
    }
  }
`;

interface queryResponse {
  venuePlanSchedules: VenuePlanSchedulesType;
}

export interface VenuePlanSchedulesType {
  plans: PlanScheduleType[];
}

export interface PlanScheduleType {
  planId: string;
  planName: string;
  schedule: {
    cutoffTimeHours: number;
    bookablePeriodMonths: number;
    days: { dayOfTheWeek?: string; holiday?: string; timeSlots: string[] }[];
  };
}
