import React, { useState, useMemo } from "react";
import { compareProps } from "../../../../../../../../helpers/compare-props";
import { TableCell, Box } from "@mui/material";
import styles from "../../../../../styles";
import BookingsModal from "./BookingsModal";

interface Props {
  planId: string;
  date: string;
  numBookings?: number;
  disabled: boolean;
}

const NumBooking: React.FC<Props> = ({
  planId,
  date,
  numBookings,
  disabled,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const style = useMemo(
    () => ({
      ...styles.cell,
      ...styles.planDivider,
      ...(disabled ? styles.disabledCell : {}),
    }),
    [disabled]
  );

  if (numBookings === undefined) return <></>;
  return (
    <TableCell sx={style}>
      <Box
        sx={numBookings > 0 ? styles.dayWithBookings : {}}
        onClick={() => {
          if (numBookings > 0) setModalOpen(true);
        }}
      >
        {numBookings}
      </Box>
      {modalOpen && (
        <BookingsModal
          planId={planId}
          day={date}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </TableCell>
  );
};

export default React.memo(NumBooking, compareProps);
