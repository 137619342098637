import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";

import { API_URL } from "../app.config";

export default function createApolloClient() {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        console.error(
          `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
        );
        if (err.extensions?.code === "UNAUTHENTICATED") {
          localStorage.removeItem("user");
          if (window !== undefined)
            window.location.href =
              window.location.pathname === "/"
                ? "/login"
                : `/login?callbackUrl=${window.location.pathname}`;
        }
      }
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const getToken = () => {
    const localStorageUser = localStorage.getItem("user");
    const user = localStorageUser ? JSON.parse(localStorageUser) : null;
    return user ? `Bearer ${user.token}` : "";
  };

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({ headers: { authorization: getToken() } });
    return forward(operation);
  });

  const httpLink = createUploadLink({ uri: `${API_URL}/graphql` });

  return new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false,
    }),
    //@ts-ignore
    link: ApolloLink.from([errorLink, authMiddleware, httpLink]),
  });
}
