import {
  Autocomplete,
  CircularProgress,
  Stack,
  TextField,
} from "@mui/material";
import { useMemo } from "react";
import { Venue } from ".";
import { ALL_VENUES } from "./types";

interface VenueSelectorProps {
  venue?: Venue;
  setVenue: (venue: Venue) => void;
  venues: { id: string; name: string }[];
}

export default function VenueSelector({
  venue,
  setVenue,
  venues,
}: VenueSelectorProps) {
  const venueOptions = useMemo(
    () => [
      ...(venues.length > 1 ? [ALL_VENUES] : []),
      ...venues.map(({ id }) => id),
    ],
    [venues]
  );

  const venueIdToName = useMemo(
    () =>
      venues.reduce(
        (acc, { id, name }) => {
          acc[id] = name;
          return acc;
        },
        { [ALL_VENUES]: "全て" } as Record<string, string>
      ),
    [venues]
  );

  if (!venue) return <CircularProgress />;
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      style={{ marginLeft: "1em" }}
    >
      <Autocomplete
        size="medium"
        sx={{ minWidth: 200 }}
        options={venueOptions}
        value={venue.id}
        onChange={(_, value) => {
          if (value !== null) {
            setVenue({
              id: value,
              name:
                venues.find((venue) => venue.id === value)?.name ?? ALL_VENUES,
            });
          }
        }}
        renderInput={(params) => <TextField {...params} label="店舗" />}
        getOptionLabel={(id) => venueIdToName[id] ?? id}
      />
    </Stack>
  );
}
