import { Button, Divider, Modal, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import WarningIcon from "@mui/icons-material/Warning";
import { useState } from "react";
import "./styles.css";
import ReactMarkdown from "react-markdown";

export interface InfoBarProps {
  synopsis: string;
  content: string;
  variant: "announcement" | "warning";
}

export default function InfoBar(props: InfoBarProps) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="info-bar">
      <Button
        className={`synopsis-button ${props.variant}`}
        onClick={handleOpen}
      >
        <Box className="synopsis-button-content">
          {props.variant === "announcement" ? (
            <AnnouncementIcon />
          ) : (
            <WarningIcon />
          )}
          {props.synopsis}
        </Box>
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Paper className="info-bar-modal-paper" elevation={16}>
          <Typography variant="h5">{props.synopsis}</Typography>
          <Divider />
          <ReactMarkdown>{props.content}</ReactMarkdown>
        </Paper>
      </Modal>
    </div>
  );
}
