import { RenderParams } from "./types";
import reservationStatusTranslations from "../../../helpers/booking-status-translations";
import paymentMethodTranslation from "../../../helpers/payment-method-translation";
import itemOrderStatusTranslations from "../../../helpers/item-order-status-translations";

export function valueExportViewBooking(params: RenderParams) {
  return `${params.row.booking.id}`;
}

export function valueExportStatus(params: RenderParams) {
  return `${reservationStatusTranslations[params.row.status]}`;
}

export function valueExportPlan(params: RenderParams) {
  return `${params.row.activity.plan.name}`;
}

export function valueExportCustomer(params: RenderParams) {
  return `${params.row.booking.familyName} ${params.row.booking.givenName} 様 ${params.row.booking.familyNameFurigana} ${params.row.booking.givenNameFurigana}`;
}

export function valueExportDatetime(params: RenderParams) {
  return `${params.row.datetime.substr(0, 10)} ${params.row.datetime.substr(
    10,
    6
  )}`;
}

export function valueExportItems(params: RenderParams) {
  let customerType = "";
  params.row.items.forEach(
    (i) => (customerType += `${i.priceType.name}: ${i.quantity} `)
  );
  return customerType;
}

export function valueExportPaymentMethod(params: RenderParams) {
  return `${paymentMethodTranslation[params.row.booking.payment.method]}`;
}

export function valueExportPayment(params: RenderParams) {
  return params.row.booking.paymentAmount;
}

export function valueExportCancellation(params: RenderParams) {
  return params.row.costs.cancellationFee;
}

export function valueExportCommissionRate(params: RenderParams) {
  return `${params.row.costs.commissionRate} %`;
}

export function valueExportCommissionFee(params: RenderParams) {
  return params.row.costs.commission;
}

export function valueExportProductOrderStatus(params: RenderParams) {
  let productOrderStatus = "";
  params.row.booking.productOrders.forEach((po) => {
    productOrderStatus += `${itemOrderStatusTranslations[po.status]} `;
  });
  return productOrderStatus;
}

export function valueExportProductOrderQuantity(params: RenderParams) {
  let productOrderQuantity = "";
  params.row.booking.productOrders.forEach((po) => {
    productOrderQuantity += `【${po.quantity}】`;
  });
  return productOrderQuantity;
}

export function valueExportProductOrderName(params: RenderParams) {
  let productOrderName = "";
  params.row.booking.productOrders.forEach((po) => {
    productOrderName += `【${
      po.product.template?.overrideDisplayName ??
      po.product?.template?.displayName
    } ${po.product.overrideName ?? po.product.name}】`;
  });

  return productOrderName;
}

export function valueExportProductOrderSellingPrice(params: RenderParams) {
  let productOrderPrice = "";
  params.row.booking.productOrders.forEach((po) => {
    productOrderPrice += `【${po.price ?? po.product.sellingPrice}】`;
  });
  return productOrderPrice;
}

const exportValues: {
  [key: string]: (params: RenderParams) => string | number;
} = {
  id: valueExportViewBooking,
  status: valueExportStatus,
  plan: valueExportPlan,
  customer: valueExportCustomer,
  datetime: valueExportDatetime,
  items: valueExportItems,
  paymentMethod: valueExportPaymentMethod,
  payment: valueExportPayment,
  cancellation: valueExportCancellation,
  commissionRate: valueExportCommissionRate,
  commissionFee: valueExportCommissionFee,
  productStatus: valueExportProductOrderStatus,
  productQuantity: valueExportProductOrderQuantity,
  productName: valueExportProductOrderName,
  productPrice: valueExportProductOrderSellingPrice,
};

export default exportValues;
