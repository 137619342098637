import { Paper, Typography } from "@mui/material";
import { Row, RowProps } from "./Row";
import s from "./Stack.module.css";

export function Stack({ header, rows }: StackProps) {
  return (
    <Paper className={s.Stack}>
      <Typography className={`${s.StackHeader} ${s.StackEntry}`}>
        {header}
      </Typography>
      {rows.map((row) => {
        return (
          <div key={row.link} className={s.StackEntry}>
            <Row {...row} />
          </div>
        );
      })}
    </Paper>
  );
}

type StackProps = {
  header: string;
  rows: RowProps[];
};
