import { useState } from "react";
import { NotificationButton } from "../NotificationButton";
import { Stack } from "./Stack";
import { RowProps } from "./Row";
import { ClickAwayListener, Popper } from "@mui/material";

export function NotificationStack(props: NotificationStackProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <NotificationButton
          numNotifications={props.numNotifications ?? props.rows.length}
          active={open}
          disabled={props.disabled}
          handleClick={(event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(anchorEl ? null : event.currentTarget);
            if (props.onClick) props.onClick();
          }}
        />
        <Popper open={open} anchorEl={anchorEl} placement="bottom-end">
          <Stack {...props} />
        </Popper>
      </div>
    </ClickAwayListener>
  );
}

type NotificationStackProps = {
  header: string;
  rows: RowProps[];
  numNotifications?: number;
  onClick?: () => {};
  disabled?: boolean;
};
