import { inclusionIcons } from "./inclusionIcons";

import "./IconPicker.css";
import { IconButton } from "@mui/material";

export default function IconPicker({ handleClick }: IconPickerProps) {
  return (
    <div className="EditorIconPicker">
      {[...inclusionIcons.entries()].map((entry, i) => {
        return (
          <IconButton key={i} onClick={() => handleClick(entry[0])}>
            {entry[1]}
          </IconButton>
        );
      })}
    </div>
  );
}

interface IconPickerProps {
  handleClick: (iconName: string) => void;
}
