import HighlightCard from "./HighlightCard";
import Stack from "@mui/material/Stack";
import { FieldValues, UseFieldArrayReturn } from "react-hook-form";
import { HighlightsField } from "../types";
import { useContext } from "react";
import { FormContext } from "../../components/FormContext";
import { setArrayFieldValue } from "../../formValues";

export default function HighlightList({
  planId,
  fieldArray: { fields, remove },
}: HighlightListProps) {
  const { form } = useContext(FormContext);

  return (
    <Stack>
      {fields.map((highlight, itemIndex) => (
        <HighlightCard
          key={highlight.id}
          highlightId={highlight.uuid}
          formIdentifier={`${HighlightsField.highlights}.${itemIndex}`}
          itemIndex={itemIndex}
          handleDelete={() => remove(itemIndex)}
          planId={planId}
          setMediaUrl={(mediaUrl: string | null) => {
            setArrayFieldValue({
              form,
              arrayName: HighlightsField.highlights,
              itemIndex: itemIndex,
              fieldName: HighlightsField.mediaUrl,
              value: mediaUrl,
            });
          }}
          setMediaType={(mediaType: "photo" | "video" | null) => {
            setArrayFieldValue({
              form,
              arrayName: HighlightsField.highlights,
              itemIndex: itemIndex,
              fieldName: HighlightsField.mediaType,
              value: mediaType,
            });
          }}
        />
      ))}
    </Stack>
  );
}

interface HighlightListProps {
  planId: string;
  fieldArray: FieldArray;
}

type FieldArray = { fields: Record<string, string>[] } & UseFieldArrayReturn<
  FieldValues,
  HighlightsField.highlights,
  "id"
>;
