import { useState } from "react";
import Tabs from "../../../components/Tabs";
import VenueAvailability from "./VenueAvailability";
import VenuePlanSchedules from "./VenuePlanSchedules";
import VenueSelector from "./VenueSelector";

export default function Availability() {
  const [venueId, setVenueId] = useState<string | null>(null);
  const [hasUnsavedChange, setHasUnsavedChange] = useState(false);

  // This will show 'Leave site? Changes you made may not be saved.' confirmation window. according to browser language
  // Due to security reasons, the message text cannot be customized
  window.onbeforeunload = function (e: any) {
    e.preventDefault();
    if (hasUnsavedChange) {
      // only prevent first time user tries to leave with unsaved changes
      setHasUnsavedChange(false);
      return "";
    }
  };

  return (
    <>
      <VenueSelector venue={venueId} setVenue={setVenueId} />
      {venueId && (
        <Tabs
          tabs={[
            {
              title: "プラン販売カレンダー",
              content: (
                <VenueAvailability
                  venue={venueId}
                  setHasUnsavedChange={setHasUnsavedChange}
                />
              ),
            },
            {
              title: "プラン販売設定",
              content: (
                <VenuePlanSchedules
                  venueId={venueId}
                  hasUnsavedChange={hasUnsavedChange}
                  setHasUnsavedChange={setHasUnsavedChange}
                />
              ),
            },
          ]}
        />
      )}
    </>
  );
}
