import { useMemo } from "react";
import InfoBar from "..";

const synopsis = "予約承認率 95%以下";

export default function AcceptanceRateWarningBar({
  numBookings,
  numRejections,
  acceptanceRate,
}: AcceptanceRateWarningBarProps) {
  const content = useMemo(() => {
    return `過去12週間で貴店は${numBookings}件の予約リクエストがあり、${numRejections}件の予約拒否がありました。
貴店の予約承認率は${acceptanceRate}%
Annyお祝い体験で掲げている目標予約承認率は95%です。
予約承認率はプランの露出度に影響が出ます。
定期的に[承認率レポート](/reports/dashboard)で詳細のご確認をお願い致します。`;
  }, [acceptanceRate, numBookings, numRejections]);

  return <InfoBar content={content} synopsis={synopsis} variant="warning" />;
}

interface AcceptanceRateWarningBarProps {
  numBookings: number;
  numRejections: number;
  acceptanceRate: number | undefined;
}
