import { createContext, Dispatch, SetStateAction, useState } from "react";
import { Form } from "../types";
import { Plan } from "../../../../types";

interface FormContextType {
  form: Form;
  numUploading: number;
  setNumUploading: Dispatch<SetStateAction<number>>;
  planData: any;
}

export const FormContext = createContext<FormContextType>({
  // @ts-ignore
  form: undefined,
  planData: undefined,
  numUploading: 0,
  setNumUploading: () => {},
});

interface FormProviderProps {
  form: Form;
  planData: any;
  children: React.ReactNode;
}

export function FormProvider({ form, children, planData }: FormProviderProps) {
  const [numUploading, setNumUploading] = useState(0);

  return (
    <FormContext.Provider
      value={{ form, numUploading, setNumUploading, planData }}
    >
      {children}
    </FormContext.Provider>
  );
}
