import { ApolloProvider } from "@apollo/client";
import { Box } from "@mui/material";
import { BrowserRouter } from "react-router-dom";

import {
  NotificationsProvider,
  NotificationBar,
} from "./components/Notification";
import createApolloClient from "./helpers/create-apollo-client";
import { GlobalContextProvider } from "./GlobalContext";
import Menu from "./components/Menu";
import Announcements from "./components/Announcements";
import WebappRoutes from "./Routes";

export default function App() {
  const client = createApolloClient();

  return (
    <ApolloProvider client={client}>
      <GlobalContextProvider>
        <BrowserRouter>
          <NotificationsProvider>
            <Menu />
            <Announcements />
            <Box
              sx={{
                marginTop: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "20px",
                maxWidth: "1200px",
                overflow: "hidden",
                "@media (max-width:1200px)": {
                  // eslint-disable-line no-useless-computed-key
                  paddingLeft: "24px",
                  paddingRight: "24px",
                },
              }}
            >
              <WebappRoutes />
              <NotificationBar />
            </Box>
          </NotificationsProvider>
        </BrowserRouter>
      </GlobalContextProvider>
    </ApolloProvider>
  );
}
