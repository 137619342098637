import { useFieldArray } from "react-hook-form";
import { Plan } from "../../List";
import HighlightList from "./components/HighlightList";
import { HighlightsField } from "./types";
import { FormContext } from "../components/FormContext";
import { useContext } from "react";

function HighlightsEditorForm() {
  const { form } = useContext(FormContext);

  const fieldArray = useFieldArray({
    name: HighlightsField.highlights,
    control: form.control,
  });

  return <HighlightList fieldArray={fieldArray} planId={form.planId} />;
}

function getDefaultValuesHighlights(plan: Plan) {
  const highlights = plan.json.highlights.map((highlight) => ({
    [HighlightsField.title]: highlight.title,
    [HighlightsField.details]: highlight.details,
    [HighlightsField.mediaUrl]: highlight.media.src,
    [HighlightsField.mediaType]: highlight.media.type,
    [HighlightsField.id]: String(highlight.index),
  }));

  return {
    [HighlightsField.highlights]: highlights,
  };
}

const highlightExport = {
  form: HighlightsEditorForm,
  getDefaultValues: getDefaultValuesHighlights,
};
export default highlightExport;
